import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from 'assets/images/back.arrow.svg'

interface SettingHeaderProps {
    title: string;
}

const SettingHeader = ({ title }: SettingHeaderProps) => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate("/setting");
    }

    return (
        <div className="flex border-b min-h-[76px] border-achromatic-100 px-6 items-center gap-3">
            <BackArrow className='w-4 h-4 cursor-pointer' onClick={handleBackClick} />
            <span className='text-lg font-semibold'>{title}</span>
        </div>
    )
}

export default SettingHeader;