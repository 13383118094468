import { create } from "zustand";

type ViewState = "login" | "terms" | "memberInfo";

interface LoginStore {
  view: ViewState;
  setView: (view: ViewState) => void;
}

export const useLoginStore = create<LoginStore>((set) => ({
  view: "login",
  setView: (view) => set({ view }),
}));
