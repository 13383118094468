import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as RightArrow } from "assets/images/arrow_forward.svg";
import { ReactComponent as LeftArrow } from "assets/images/arrow_back.svg";

interface PostImageComponentProps {
    images?: string[];
}

const PostImageComponent = ({ images = [] }: PostImageComponentProps) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [isDrag, setIsDrag] = useState<boolean>(false);
    const [hasDragged, setHasDragged] = useState<boolean>(false);
    const [startX, setStartX] = useState<number>(0);
    const [mainImage, setMainImage] = useState<string>(images[0] || "");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageIndex, setModalImageIndex] = useState(0);

    const handleDragStart = (event: React.MouseEvent | React.TouchEvent) => {
        event.preventDefault();
        setIsDrag(true);

        setHasDragged(false);

        const pageX = "touches" in event ? event.touches[0].pageX : event.pageX;

        if (scrollRef.current) {
            setStartX(pageX + scrollRef.current.scrollLeft);
        }
    }

    const handleDragMove = (event: MouseEvent | TouchEvent) => {
        if (!isDrag || !scrollRef.current) return;

        setIsDrag(true);
        const pageX = "touches" in event ? event.touches[0].pageX : event.pageX;
        scrollRef.current.scrollLeft = startX - pageX;

        if (!hasDragged) {
            setHasDragged(true);
        }
    }

    const handleDragEnd = () => {
        setIsDrag(false);
    }

    useEffect(() => {
        if (isDrag) {
            window.addEventListener('mousemove', handleDragMove as EventListener);
            window.addEventListener('mouseup', handleDragEnd);
            window.addEventListener('touchmove', handleDragMove as EventListener);
            window.addEventListener('touchend', handleDragEnd);
        } else {
            window.removeEventListener('mousemove', handleDragMove as EventListener);
            window.removeEventListener('mouseup', handleDragEnd);
            window.removeEventListener('touchmove', handleDragMove as EventListener);
            window.removeEventListener('touchend', handleDragEnd);
        }

        return () => {
            window.removeEventListener('mousemove', handleDragMove as EventListener);
            window.removeEventListener('mouseup', handleDragEnd);
            window.removeEventListener('touchmove', handleDragMove as EventListener);
            window.removeEventListener('touchend', handleDragEnd);
        };
    }, [isDrag]);

    const handleMainImageClick = () => {
        const mainImageIndex = images.indexOf(mainImage);
        if (mainImageIndex !== -1) {
            setModalImageIndex(mainImageIndex);
        }
        setIsModalOpen(true);
    }

    const handleImageClick = (image: string) => {
        setMainImage(image);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const showNextImage = () => {
        setModalImageIndex((prev) => (prev + 1) % images.length);
    };

    const showPrevImage = () => {
        setModalImageIndex((prev) => (prev - 1 + images.length) % images.length);
    };

    useEffect(() => {
        setMainImage(images[0] || "");
    }, [images]);

    return (
        <div className="flex flex-col">
            <div>
                {/* 큰이미지 */}
                <div
                    className="border-2 border-achromatic-100 mb-4 rounded-2xl overflow-hidden relative h-[404px] max-w-[632px]"
                    onClick={handleMainImageClick}
                >
                    {mainImage ? (
                        <img
                            src={`${process.env.REACT_APP_API_URL}/${mainImage}`}
                            alt="Main Display"
                            className="w-full h-full object-cover object-center"
                            onContextMenu={(e) => e.preventDefault()}
                            draggable={false}
                        />) : (
                        <div className="w-full h-full flex items-center justify-center">
                            <p className="text-achromatic-400">이미지가 없습니다.</p>
                        </div>
                    )}
                </div>
            </div>
            {/* 작은이미지 */}
            <div
                ref={scrollRef}
                onMouseDown={handleDragStart}
                onTouchStart={handleDragStart}
                className={`flex gap-2 overflow-x-hidden overflow-y-hidden`}
            >
                {images.map((image, index) => (
                    <div
                        key={index}
                        className={`border-2 border-achromatic-100 rounded-2xl overflow-hidden relative max-h-[128px] max-w-[128px] min-h-[128px] min-w-[128px]`}
                        onClick={() => handleImageClick(image)}
                    >
                        <img
                            src={`${process.env.REACT_APP_API_URL}/${image}`}
                            alt={`Uploaded ${index}`}
                            className="w-full h-full object-cover object-center"
                            onContextMenu={(e) => e.preventDefault()}
                        // draggable={false}
                        />
                    </div>
                ))}
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <div className="relative">
                        {/* 닫기 버튼 */}
                        <button
                            onClick={closeModal}
                            className="absolute top-[-50px] right-0 text-white text-xl p-2 rounded-full z-10"
                        >
                            ✕
                        </button>

                        {/* 이전 이미지 버튼 */}
                        <button
                            onClick={showPrevImage}
                            className="absolute left-[-64px] top-1/2 transform -translate-y-1/2 text-white text-3xl p-2 rounded-full"
                        >
                            <LeftArrow />
                        </button>

                        {/* 이미지 */}
                        <img
                            src={`${process.env.REACT_APP_API_URL}/${images[modalImageIndex]}`}
                            alt="Modal Display"
                            className="max-w-[90vw] max-h-[90vh] object-contain"
                            onContextMenu={(e) => e.preventDefault()}
                            draggable={false}
                        />

                        {/* 다음 이미지 버튼 */}
                        <button
                            onClick={showNextImage}
                            className="absolute right-[-64px] top-1/2 transform -translate-y-1/2 text-white text-3xl p-2 rounded-full"
                        >
                            <RightArrow />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PostImageComponent;