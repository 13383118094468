import { AdminSideBar } from "components";
import { useLocation } from "react-router-dom";

type Props = {
    children: React.ReactNode;
}
export default function AdminLayout({ children }: Props) {
    const location = useLocation();
    const isAuthPage = location.pathname.startsWith("/admin/login");

    return (
        <div className="max-w-[1920px] mx-auto w-full flex h-screen bg-achromatic-50">
            {!isAuthPage && (
                <div className="max-w-[300px] w-full">
                    <AdminSideBar />
                </div>
            )}
            <div className="w-full">
                {children}
            </div>
        </div>
    );
}