import { Outlet } from "react-router-dom"

import { RightSideBar } from "components"

export default function MyPage() {
    return (
        <div className="mypage flex w-full mobile:pb-[56px]">
            <div className="flex max-w-[680px] w-full flex-col border-achromatic-100 border-x">
                <Outlet />
            </div>
            <div className="max-w-[360px] w-full mobile:hidden">
                <RightSideBar />
            </div>
        </div>
    )
}