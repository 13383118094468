const faqList = [
  {
    question: "클래빗에서 경매에 입찰하려면 어떻게 해야 하나요?",
    answer:
      "클래빗에서 경매에 입찰하기 위해서는 먼저 SNS 계정을 통해 사용자 등록을 해야 합니다. 사용자 등록은 19세 이상만 가능합니다. 로그인하여, 원하는 경매 물품을 선택하고 입찰 금액을 입력한 뒤, 입찰 버튼을 눌러 참여할 수 있습니다. 입찰 전에 반드시 입찰 유의사항을 꼼꼼히 읽고 경매에 참여해야 합니다. 또한, 입찰 할 때는 해당 경매에 책정된 보증금이 지갑에서 사용되므로 미리 클랩을 충전해두는 것을 추천합니다. 입찰은 경매 종료 시간까지 계속할 수 있으며, 최종적으로 가장 높은 금액을 입찰한 사용자가 낙찰자가 됩니다.",
  },
  {
    question: "클래빗에서 구매한 물품의 결제 방법은 어떻게 되나요?",
    answer:
      "클래빗에서는 안전한 결제 시스템인 Stripe를 통해 신용카드로 결제를 진행할 수 있습니다. 결제는 낙찰 후 24시간 이내에 이루어져야 하며, 결제가 완료되면 경매품은 클래빗의 검수를 거쳐 낙찰자에게 안전하게 배송됩니다.",
  },
  {
    question: "클래빗에서 경매로 물품을 판매하려면 어떻게 해야 하나요?",
    answer:
      "클래빗에서 경매로 물품을 판매하려면 먼저 SNS 계정을 통해 사용자 등록을 한 후, 계정을 크리에이터로 전환해야 합니다. 크리에이터 계정 승인이 이루어지면 판매할 물품의 정보를 상세하게 입력하고, 경매 가격 및 경매 기간을 설정하여 경매를 시작할 수 있습니다. 단, 경매로 판매 가능한 물품은 단순 중고품이 아닌 크리에이터가 가까이 지니던 애장품으로 한정됩니다. 신속한 경매 진행을 위하여 경매 물품은 안내해드린 클래빗 검수센터로 보내주셔야하며, 검수완료 및 낙찰자 결제 완료 시 지갑으로 클랩이 지급됩니다.",
  },
  {
    question: "경매에 참여했는데 입찰을 취소할 수 있나요?",
    answer:
      "입찰 후 1시간 안에는 자유롭게 취소가 가능합니다. 단, 경매종료까지 1시간 미만인 경우에는 취소가 불가합니다. 낙찰이 되면 취소가 불가하며 낙찰 대금을 24시간 내에 결제하지 않은 경우 사용된 보증금에서 일정 비율을 제외한 클랩이 지갑으로 환급됩니다. 따라서 신중하게 입찰해야 합니다.",
  },
  {
    question: "낙찰된 물품에 문제가 있으면 어떻게 해야 하나요?",
    answer:
      "크리에이터의 경매품이 클래빗의 검수를 통과하지 못했을 경우에는 낙찰자에게 환불 조치가 이루어집니다. 단, 배송이 정상적으로 이루어지지 않은 경우를 제외하고, 배송 완료된 경매품은 교환 및 환불이 불가합니다. 문제가 발생하면 고객문의를 통해 접수를 해주시기 바랍니다.",
  },
];

export default faqList;
