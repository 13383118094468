import { AxiosResponse } from "axios";

import { ResponseDTO, SupportDTO } from "types";
import { post } from "utils";

const baseUrl = `${process.env.REACT_APP_API_URL}/support`;

export const createSupport = async (
  title: string,
  content: string,
  titleImage?: File | null,
  contentImage?: File | null
): Promise<AxiosResponse<ResponseDTO<SupportDTO>>> => {
  let url = `${baseUrl}/create`;

  const formData = new FormData();
  formData.append("title", title);
  formData.append("content", content);
  if (titleImage) {
    formData.append("titleImage", titleImage);
  }
  if (contentImage) {
    formData.append("contentImage", contentImage);
  }

  return post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
