import { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';

import { ImageGallery } from 'components';
import { usePostStore, useAuctionStore, useUserStore } from 'store';
import { AuctionDTO } from 'types';

import { ReactComponent as BadgePicture } from 'assets/images/badge.picture.svg';
import { ReactComponent as BadgeAuction } from 'assets/images/badge.auction.svg';

interface SmallButtonProps {
    text: string;
    isSelected?: boolean;
    onClick?: () => void;
    disabled?: boolean;
}

const SmallButton = ({ text, isSelected, onClick, disabled }: SmallButtonProps) => {
    return (
        <button
            onClick={!disabled ? onClick : undefined}
            className="flex h-[22px] max-w-[71px] items-center justify-center"
        >
            {text === '사진' &&
                <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={onClick}
                    disabled={disabled}
                />
            }
            <div className={`flex w-full h-full px-[6px] py-1 rounded-[4px] items-center justify-center ${isSelected ? 'bg-[#0066FF] bg-opacity-[8%]' : 'bg-achromatic-50'}`}>
                <div className='flex items-center gap-1'>
                    {text === '사진' && <BadgePicture className={`w-[11px] h-[11px] ${isSelected ? 'fill-[#0066FF]' : 'fill-achromatic-400'}`} />}
                    {text === '물품경매' && <BadgeAuction className={`w-[11px] h-[11px] ${isSelected ? 'fill-[#0066FF]' : 'fill-achromatic-400'}`} />}
                    <span className={`font-semibold text-xs ${isSelected ? 'text-[#0066FF]' : 'text-achromatic-400'}`}>
                        {text}
                    </span>
                </div>
            </div>
        </button>
    );
}

const GuestPostEditor = () => {
    const [hashtags, setHashtags] = useState<string[]>([]);
    const { uploadedImages, addImage, setUploadedImages } = usePostStore();
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    return (
        <div className="w-full max-h-full min-[142px] border-b border-achromatic-100">
            <div className='flex flex-col w-full h-full justify-between py-6'>
                <div className="w-full text-achromatic-800 text-base font-medium">
                    <textarea
                        ref={textareaRef}
                        className="w-full focus:outline-none resize-none overflow-hidden px-6 bg-white"
                        placeholder={"회원가입을 하고 글을 작성해 보세요."}
                        disabled={true}
                    />
                    <div className="whitespace-pre-wrap mt-2 font-medium text-sm text-primary-500">
                        {hashtags.map((tag) => `${tag}`).join(' ')}
                    </div>
                    {<ImageGallery images={uploadedImages} isPosting={true} />}
                </div>
                <div className="flex justify-between px-6">
                    <div className="flex gap-2 items-end">
                        <input
                            className='hidden'
                            ref={fileInputRef}
                            type="file"
                            disabled={true}
                        />
                        <SmallButton text="사진" disabled={true} />
                        <SmallButton text="물품경매" disabled={true} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GuestPostEditor;