import { getFollowingList, unfollowUser } from 'apis';
import React, { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAlertStore } from 'store';

const FollowingComponent = () => {
    const navigate = useNavigate();

    const fetchDatas = ({ pageParam = 1 }: { pageParam?: number }) => {
        return getFollowingList(pageParam, 6);
    };

    const {
        data,
        isLoading,
        isError,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        refetch,
    } = useInfiniteQuery(
        ["datas", "all"],
        fetchDatas,
        {
            getNextPageParam: (lastPage, allPages) => {
                const totalItems = lastPage.data.data.total;
                const itemsPerPage = 10;
                const currentPage = allPages.length;
                const totalPages = Math.ceil(totalItems / itemsPerPage);
                return currentPage < totalPages ? currentPage + 1 : undefined;
            },
        }
    );

    const handleRemove = (id: string) => {
        unfollowUser(id)
            .then((response) => {
                if (response.data.isSuccess === 'T') {
                    refetch();
                }
            })
            .catch((error) => {
                console.error("팔로우 취소 중 오류", error);
            });
    };

    const handleScroll = () => {
        if (
            window.innerHeight + window.scrollY >= document.body.offsetHeight &&
            hasNextPage &&
            !isFetchingNextPage
        ) {
            fetchNextPage();
        }
    };

    const handleProfileClick = (profileId: string) => {
        navigate(`/@${profileId}`);
    }

    const handleNewFeedClick = (postId: string) => {
        navigate(`/post/${postId}`);
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [hasNextPage, isFetchingNextPage]);

    // 전체 items 개수 계산
    const totalItems = data?.pages?.reduce((acc, page) => acc + page.data.data.items.length, 0) || 0;

    return (
        <div className="p-6">
            {/* 팔로우한 유저가 없는 경우 */}
            {totalItems === 0 && !isLoading && (
                <div className="text-center text-gray-500 text-base py-10">
                    크리에이터를 팔로우하고 소식을 받아보세요!
                </div>
            )}

            <div className="grid grid-cols-3 gap-6 mobile:grid-cols-2">
                {data?.pages.map((page) =>
                    page.data.data.items.map((user) => (
                        <div key={user.id} className="relative flex flex-col items-center gap-2 p-4">
                            <button
                                onClick={() => handleRemove(user.profileId)}
                                className="absolute top-1 right-1 text-gray-400 hover:text-red-500 text-base font-bold"
                            >
                                ×
                            </button>

                            <div
                                className={`relative transition-all duration-500 ease-in-out transform cursor-pointer ${user.recentPostId
                                    ? 'ring-2 ring-green-500 ring-offset-2 ring-offset-white rounded-full hover:rotate-6 hover:scale-105'
                                    : ''
                                    }`}
                                onClick={() => handleProfileClick(user.profileId)}
                            >
                                {user?.profileImage ? (
                                    <img
                                        src={`${process.env.REACT_APP_API_URL}/${user.profileImage}`}
                                        alt="profile"
                                        className="w-24 h-24 object-cover rounded-full transition-all duration-500 ease-in-out"
                                    />
                                ) : (
                                    <div className="w-24 h-24 rounded-full bg-gray-300 mb-2" />
                                )}
                            </div>

                            <div onClick={() => handleProfileClick(user.profileId)} className="flex flex-col items-center gap-1 cursor-pointer text-center">
                                <div className="text-sm font-medium">{user.name}</div>
                                <div className="text-xs text-gray-500">@{user.profileId}</div>
                            </div>

                        </div>
                    ))
                )}
            </div>

            <div>{isFetchingNextPage && "Loading..."}</div>
        </div>
    );
};

export default FollowingComponent;