import { SideBar } from "components";

type Props = {
    children: React.ReactNode;
};
export default function BaseLayout({ children }: Props) {
    return (
        <div className="max-w-[1280px] flex mx-auto h-full mobile:w-full mobile:flex-col">
            <div className="max-w-[240px] w-full mobile:hidden">
                <SideBar />
            </div>
            <div className="max-w-[1040px] w-full mobile:max-w-[360px]">
                {children}
                <div className="hidden mobile:flex mobile:fixed mobile:bottom-0 mobile:max-w-[360px] mobile:w-full">
                    <SideBar />
                </div>
            </div>
        </div>
    );
}