import React, { useEffect, useState } from "react";

import { ReactComponent as SearchIcon } from "assets/images/search.svg";
import { ReactComponent as CancelIcon } from "assets/images/cancel.svg";

interface SearchBarProps {
  onSearch: (searchTerm: string) => void;
  value?: string;
}

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const SearchBar = ({ onSearch, value }: SearchBarProps) => {
  const [inputText, setInputText] = useState<string>("");
  const [isInputText, setIsInputText] = useState<boolean>(false);

  const debouncedInputText = useDebounce(inputText, 200);

  useEffect(() => {
    if (debouncedInputText.length > 0) {
      setIsInputText(true);
    } else {
      setIsInputText(false);
    }
  }, [debouncedInputText]);

  useEffect(() => {
    if (debouncedInputText) {
      // console.log(`debouncedInputText: ${debouncedInputText} inputText: ${inputText} value: ${value}`);
      onSearch(inputText);
    } else {
      // console.log(`debouncedInputText: ${debouncedInputText} inputText: ${inputText} value: ${value}`);
      onSearch("");
    }
  }, [debouncedInputText, onSearch]);

  useEffect(() => {
    if (value) {
      setInputText(value);
      onSearch(value);
    }
  }, [value])

  const handleInputText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const handleCancelClick = () => {
    setInputText("");
    onSearch(""); // 검색 초기화
  };

  return (
    <div className="flex min-h-[76px] justify-center border-b border-achromatic-100">
      <div className="flex w-full bg-white items-center">
        <div className="py-5 pl-6">
          <SearchIcon className="w-6 h-6" />
        </div>
        <div className="flex w-full h-full items-center px-4">
          <input
            className="font-semibold w-full h-12 focus:outline-none caret-primary-500 placeholder-achromatic-300"
            type="text"
            placeholder="크리에이터나 경매 물품을 검색해 보세요!"
            value={inputText}
            onChange={handleInputText}
          />
        </div>
        {isInputText && (
          <div className="py-5 pr-6 cursor-pointer" onClick={handleCancelClick}>
            <CancelIcon className="w-6 h-6" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
