import { SettingHeader } from "components"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { logout } from "apis";


const LogOutComponent = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleLogout = async () => {
            await logout();
            navigate('/login'); // 로그아웃 후 로그인 페이지로 리디렉션
        };

        handleLogout();
    }, [navigate]);

    return (
        <div className="flex flex-col w-full">
            <div>
                <SettingHeader title="로그아웃" />
            </div>
            <div className="flex flex-col py-6">

            </div>
        </div>
    );
}

export default LogOutComponent;