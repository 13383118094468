import { NavLink } from 'react-router-dom';

import { PageHeader } from 'components';
import { settingSideBarLinks } from 'data';

const SettingSideBar = () => {

    return (
        <div className="h-screen bg-white">
            <PageHeader title='설정' />
            <div className="flex flex-col">
                {settingSideBarLinks.map((link, index) => (
                    <NavLink
                        key={index}
                        to={link.href}
                        className={({ isActive }) =>
                            `py-4 px-6 border-b border-achromatic-100 cursor-pointer ${isActive ? 'text-primary-500' : 'text-achromatic-500'}`
                        }
                    >
                        <span className="text-base font-semibold">{link.title}</span>
                    </NavLink>
                ))}
            </div>
        </div>
    );
};

export default SettingSideBar;