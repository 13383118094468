import { Loading } from "components";
import { useEffect, useState } from "react";
import { useAlertStore } from "store";

export default function CompletePage() {
    const showAlert = useAlertStore((state) => state.showAlert);
    const [paymentStatus, setPaymentStatus] = useState<string>('');

    useEffect(() => {
        // URL 쿼리에서 success 파라미터 확인
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('success') === 'true') {
            setPaymentStatus('success');
            // 부모 창에 결제 성공 메시지를 보냄
            if (window.opener) {
                window.opener.postMessage('paymentSuccess', window.location.origin);
                showAlert('클랩 충전이 완료되었습니다.', 'success');
            }
            window.close(); // 새 창을 닫음
        } else if (urlParams.get('canceled') === 'true') {
            // 부모 창에 결제 취소 메시지를 보냄
            setPaymentStatus('canceled');
            if (window.opener) {
                window.opener.postMessage('paymentCanceled', window.location.origin);
                showAlert('결제가 취소되었습니다.', 'error');
            }
            window.close(); // 새 창을 닫음
        }
    }, []);


    return (
        <div className="payment-complete flex flex-col items-center justify-center h-screen gap-5">
            <Loading></Loading>
            {paymentStatus === 'success' && <div>결제가 완료되었습니다.</div>}
            {paymentStatus === 'canceled' && <div>결제가 취소되었습니다.</div>}
            {paymentStatus === 'success' && <div>결제 완료 후 이 창은 자동으로 닫힙니다.</div>}
            {paymentStatus === 'canceled' && <div>결제 취소 후 이 창은 자동으로 닫힙니다.</div>}
        </div>
    );
}