import React, { useEffect, useState } from 'react';
import { BaseModal, Button, AdjustInput } from 'components';
import { withdraw } from 'apis';
import { useAlertStore, useUserStore } from 'store';

interface WithdrawModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    account?: string;
    balance: number
}

const WithdrawModal = ({ isOpen, onClose, title, account, balance }: WithdrawModalProps) => {
    const { user } = useUserStore();
    const showAlert = useAlertStore((state) => state.showAlert);
    const [inputValue, setInputValue] = useState<string>('');  // 입력된 값을 상태로 관리
    const [errorMessage, setErrorMessage] = useState<string>(''); // 오류 메시지 상태
    const [isError, setIsError] = useState<boolean>(false); // 오류 발생 여부 상태

    const formatAmount = (amount: string) => {
        if (!amount) return ''; // 빈 값이면 빈 문자열 반환
        return Number(amount).toLocaleString(); // 숫자 형식으로 변환 (1000 단위 콤마)
    };

    const klavValue = inputValue ? Number(inputValue) * 1000 : 0; // 클랩 단위 변환 (숫자)
    const formattedklavValue = formatAmount(klavValue.toString()); // UI에 표시할 포맷된 값
    const fee = klavValue ? klavValue * 0.2 : 0; // 수수료 계산
    const finalAmount = klavValue ? klavValue - fee : 0; // 정산 예상 금액 계산

    const handleDivClick = () => {
        // 클릭 시 AdjustInput의 input에 포커스
        const input = document.querySelector('input'); // 특정 input을 선택
        if (input) {
            input.focus();
        }
    };

    const handleWithdrawClick = async () => {
        if (user.role !== 'creator') {
            showAlert('크리에이터만 출금이 가능합니다.', 'error'); // 에러 메시지 표시
            onClose();
            return; // 함수 종료
        }

        try {
            const response = await withdraw(Number(inputValue)); // 출금 API 호출
            if (response.data.isSuccess === "T") {
                // 성공 시
                showAlert('출금신청이 완료되었습니다.', 'success'); // 성공 메시지 표시
                onClose(); // 모달 닫기
            }
        } catch (error) {
            console.error(error); // 에러 발생 시 콘솔에 표시
        }
    }

    useEffect(() => {
        if (Number(inputValue) > 10000000) {
            setIsError(true); // 오류 발생
            setErrorMessage('10000000 넘을 수 없습니다.'); // 오류 메시지 설정
        } else {
            setIsError(false); // 오류 발생하지 않음
            setErrorMessage(''); // 오류 메시지 초기화
        }
    }, [inputValue]);

    return (
        <BaseModal title={title} isOpen={isOpen} onClose={onClose}>
            <div className='flex flex-col gap-[60px] w-[560px] mobile:w-[292px]'>
                <div className='flex flex-col pt-8 gap-8'>
                    <div className="flex flex-col text-achromatic-400 font-medium text-sm gap-4">
                        <div className='flex flex-col gap-2'>
                            <span>출금정보</span>
                            <span className='text-black text-base font-semibold'>{account}</span>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <span>출금 가능 수익금</span>
                            <span className='text-black text-base font-semibold'> {balance}클랩</span>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <span>출금할 클랩</span>
                            <div
                                className='flex justify-between items-center border border-achromatic-100 rounded-lg h-12 text-black'
                                onClick={handleDivClick}
                            >
                                <div className='w-full flex h-full items-center'>
                                    <AdjustInput inputValue={inputValue} setInputValue={setInputValue} isError={isError} />
                                </div>
                                <div className='flex-shrink-0 items-center p-3'>
                                    클랩
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <span>출금 금액</span>
                            <span className='text-black text-base font-semibold'>{formattedklavValue}원</span>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <span>수수료</span>
                            <span className='text-black text-base font-semibold'>
                                {inputValue ? `${formatAmount(fee.toFixed(0))}원` : '20%'}
                            </span>
                        </div>
                        {inputValue && (
                            <div className='flex flex-col gap-2'>
                                <span>정산 예상 금액</span>
                                <span className='text-primary-500 text-base font-semibold'>
                                    {inputValue ? `${formatAmount(finalAmount.toFixed(0))}원` : ''}
                                </span>
                            </div>
                        )}

                        {/* 오류 메시지 표시 */}
                        {errorMessage && <span className='text-red-500 mt-2'>{errorMessage}</span>}
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <Button text='출금하기' onClick={handleWithdrawClick} disabled={!inputValue} />
                    <div className='flex flex-col justify-center text-center text-achromatic-400 text-sm leading-[17px] font-medium'>
                        <span>신청하신 금액은 영업일 기준 1~3일 내로 처리됩니다.</span>
                        <span>신청이 완료된 후에는 취소가 불가능합니다.</span>
                    </div>
                </div>
            </div>
        </BaseModal >
    );
};

export default WithdrawModal;
