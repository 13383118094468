const depositList = [
  { klav: 1 },
  { klav: 5 },
  { klav: 10 },
  { klav: 25 },
  { klav: 50 },
  { klav: 100 },
];

export default depositList;
