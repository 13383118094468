import { AxiosResponse } from "axios";

import { ResponseDTO } from "types";
import { WalletDTO } from "types/DTOs/wallet/wallet.dto";
import { get, patch, post } from "utils";

const baseUrl = `${process.env.REACT_APP_API_URL}/wallet`;

export const getMyWallet = async (): Promise<
  AxiosResponse<ResponseDTO<WalletDTO>>
> => {
  let url = `${baseUrl}/myWallet`;

  return get(url);
};

export const updateWallet = async (
  klav: number,
  type: "deposit" | "withdraw" | "payment" | "cancle"
): Promise<AxiosResponse<ResponseDTO<WalletDTO>>> => {
  let url = `${baseUrl}/update`;
  const body = {
    klav,
    type,
  };

  return patch(url, body);
};

export const withdraw = async (
  amount: number
): Promise<AxiosResponse<ResponseDTO<WalletDTO>>> => {
  let url = `${baseUrl}/withdraw`;
  const body = {
    amount,
  };

  return post(url, body);
};
