import { getGuestPopularPostList, getGuestRecommendCreatorList, getPopularPostList, getRecommendCreatorList } from 'apis';
import { Loading } from 'components';

import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { PostDTO } from 'types';

import { ReactComponent as GrayHeart } from 'assets/images/gray.heart.svg'

const privactPolicyUrl = "https://docs.google.com/document/d/e/2PACX-1vRVkLz72D_-QaezBHadnMa9uwXme4sX7qWVaKAFjV3gCP1clYy6oOIYZ4IpjF8odPYGMotLjZP8m6R9/pub";
const termsOfServiceUrl = 'https://docs.google.com/document/d/e/2PACX-1vRDH7v6Lkz8gO6S-mYEoN8zSCQA-4IM4ChKhr3BftQ1FsJvF5Op_iPbominJwBxlOuaVAr0VopxZpdU/pub';
const cookiePolicyUrl = 'https://docs.google.com/document/d/e/2PACX-1vTXE1UxhQzejgD8h2-jWWGtSJVaqzEOvzatllBqO7ZGV5VJqTY5pJyKmUOkXrtHG01Bl2Sc1RFiLD6s/pub'

interface RankItemProps {
    rank: number;
    title: string;
    price: string;
    img: string;
    post?: PostDTO
}

const RankItem = ({ rank, title, price, img, post }: RankItemProps) => {
    const navigate = useNavigate();

    const handleItemClick = async () => {
        navigate(`/post/${post?.id}`);
    }

    return (
        <div className='flex'>
            <div
                className='flex gap-4 py-2 cursor-pointer'
                onClick={handleItemClick}>
                {post?.auction.isWarning ? (
                    <div className='w-20 h-20 rounded-lg bg-achromatic-100 text-achromatic-400 flex items-center justify-center'>
                        <GrayHeart className='w-8 h-8' />
                    </div>
                ) : (
                    <div>
                        {post?.images[0] ? (
                            <img
                                src={`${process.env.REACT_APP_API_URL}/${post?.images[0]}`}
                                alt="post"
                                className="w-20 h-20 rounded-lg object-cover object-center"
                            />
                        ) : (
                            <div className="w-20 h-20 bg-achromatic-100 rounded-lg"></div>
                        )}
                    </div>
                )}

                <div className='text-primary-500 font-semibold text-lg'>{rank}</div>

                <div className='flex flex-col flex-1 gap-1'>
                    <div className='flex items-center gap-2'>
                        {post?.postWriter.profileImage ? (
                            <img
                                src={`${process.env.REACT_APP_API_URL}/${post?.postWriter.profileImage}`}
                                alt="profile"
                                className="w-6 h-6 rounded-full object-cover object-center"
                            />
                        ) : (
                            <div className="w-6 h-6 bg-achromatic-100 rounded-full"></div>
                        )}
                        <div className='text-xs text-achromatic-500'>
                            {post?.postWriter.name}
                        </div>
                    </div>
                    <div className='text-black'>{title}</div>
                    <div className='text-[#F04D1A]'>
                        <span className='text-achromatic-500 pr-1 text-xs font-medium'>현재가</span>
                        {(parseInt(price) * 1000).toLocaleString()}원
                    </div>
                </div>

            </div>
        </div>
    );
}

const GuestRightSideBar = () => {
    const navigate = useNavigate();
    const [popularPosts, setPopularPosts] = useState<PostDTO[]>([]);
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태 추가
    const pageSize = 5; // 한 페이지에 보여줄 아이템 개수

    useEffect(() => {
        getPopularPosts();
    }, []);

    const getPopularPosts = async () => {
        try {
            const response = await getGuestPopularPostList();
            setPopularPosts(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }

    const { data: recommendCreators, isLoading, isError } = useQuery('recommendCreators', getGuestRecommendCreatorList);

    const paginatedData = recommendCreators?.data?.data.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    if (isLoading) return <div></div>
    if (isError) return <div>Error...</div>

    return (
        <div className='h-full bg-white font-semibold text-sm'>
            <div className="flex flex-col h-full p-6 gap-6">
                <div className='flex flex-col gap-6'>
                    <div className='text-achromatic-600'>
                        🔥 인기 경매
                    </div>
                    <div className='flex flex-col gap-2'>
                        {
                            popularPosts.map((post, index) => (
                                <RankItem
                                    key={index}
                                    rank={index + 1}
                                    title={post.auction.title}
                                    price={post.auction.currentPrice.toString()}
                                    img={post.images[0]}
                                    post={post}
                                />
                            ))
                        }
                    </div>
                </div>

                <div className='border-b border-achromatic-100' />

                <div className='flex flex-col gap-6'>
                    <div className='text-achromatic-600'>
                        ✨ 추천 크리에이터
                    </div>
                    <div className='flex flex-col gap-2'>
                        {paginatedData?.map((creator, index) => (
                            <div className='flex gap-4 items-center cursor-pointer py-2' key={index} onClick={() => (navigate(`/@${creator.user.profileId}`))}>
                                {creator.user.profileImage ? (
                                    <img
                                        src={`${process.env.REACT_APP_API_URL}/${creator.user.profileImage}`}
                                        alt="profile"
                                        className="w-8 h-8 rounded-full object-cover object-center"
                                    />
                                ) : (
                                    <div className="w-8 h-8 bg-achromatic-100 rounded-full"></div>
                                )}
                                <span>{creator.user.name}</span>
                            </div>
                        ))}
                    </div>
                    <div className='relative flex justify-center items-center'>

                        {/* 작은 원 세개의 페이징 버튼 */}
                        <div className='flex gap-1 border-achromatic-50 border rounded-full items-center py-1 px-2'>
                            {[1, 2, 3].map(page => (
                                <div
                                    key={page}
                                    className={`w-[6px] h-[6px] rounded-full transition-all duration-150 ${currentPage === page ? 'bg-primary-500' : 'bg-achromatic-100'} cursor-pointer`}
                                    onClick={() => setCurrentPage(page)}
                                ></div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='flex border-t border-achromatic-100 py-6 px-[10px]'>
                    <div className='flex w-full justify-between text-xs text-achromatic-400'>
                        <div>
                            <Link to={privactPolicyUrl} target="_blank">개인정보처리방침</Link>
                        </div>
                        <div>
                            <Link to={termsOfServiceUrl} target="_blank">이용약관</Link>
                        </div>
                        <div>
                            <Link to={cookiePolicyUrl} target="_blank">쿠키정책</Link>
                        </div>
                        <div>
                            ⓒ Klavit
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GuestRightSideBar;