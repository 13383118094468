import { getDeliveringAuctionList, putArriveDelivery, putCompleteDelivery, putCompleteInspection } from "apis";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import { AuctionDeliveryStatus } from "types";

const DeliveryManageComponent = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const itemsPerPage = 10;

    const isDisabled = (status: string, targetStatus: AuctionDeliveryStatus) => {
        const statusOrder = [
            AuctionDeliveryStatus.PAYMENT_PENDING,
            AuctionDeliveryStatus.PAYMENT,
            AuctionDeliveryStatus.CREATOR_DELIVERY,
            AuctionDeliveryStatus.INSPECTION,
            AuctionDeliveryStatus.DELIVERY,
        ];
        const currentIndex = statusOrder.indexOf(status as AuctionDeliveryStatus);
        const targetIndex = statusOrder.indexOf(targetStatus);
        return currentIndex >= targetIndex;
    };

    const { data, isLoading, isError } = useQuery(
        ["deliveringAuctionList", currentPage, itemsPerPage],
        () => getDeliveringAuctionList(currentPage, itemsPerPage),
        {
            keepPreviousData: true,
        }
    );

    if (isLoading) return <div>로딩 중...</div>;
    if (isError) return <div>오류가 발생했습니다.</div>;

    const deliveringAuctionList = data?.data.data.items || [];
    const totalItems = data?.data.data.total || 0;
    const pageCount = Math.ceil(totalItems / itemsPerPage);

    const handlePageClick = ({ selected }: { selected: number }) => {
        setCurrentPage(selected + 1);
    };

    const handleArriveDelivery = async (auctionId: string) => {
        try {
            const response = await putArriveDelivery(auctionId);
            if (response.status === 200) {
                alert("도착 확인이 완료되었습니다.");
                setCurrentPage(1);
            }
        } catch {
            alert("도착 확인에 실패했습니다.");
        }
    };

    const handleCompleteInspection = async (auctionId: string) => {
        try {
            const response = await putCompleteInspection(auctionId);
            if (response.status === 200) {
                alert("검수 완료가 완료되었습니다.");
                setCurrentPage(1);
            }
        } catch {
            alert("검수 완료에 실패했습니다.");
        }
    };

    const handleCompleteDelivery = async (auctionId: string) => {
        try {
            const response = await putCompleteDelivery(auctionId);
            if (response.status === 200) {
                alert("배송 완료가 완료되었습니다.");
                setCurrentPage(1);
            }
        } catch {
            alert("배송 완료에 실패했습니다.");
        }
    };

    const toggleRow = (id: string) => {
        setExpandedRow(expandedRow === id ? null : id);
    };

    return (
        <div className="h-full flex flex-col gap-6 py-6 px-6">
            <div className="text-2xl font-bold">배송 관리</div>

            <div className="flex items-center bg-white rounded-md h-[100px] shadow">
                <div className="flex px-6 text-lg font-medium">총 {totalItems}건</div>
            </div>

            <div className="bg-white rounded-md shadow flex flex-col px-6 py-8">
                <div className="overflow-x-auto">
                    <table className="w-full text-center border-collapse">
                        <thead>
                            <tr className="border-b">
                                <th className="py-2 px-4">번호</th>
                                <th className="py-2 px-4">제목</th>
                                <th className="py-2 px-4">내용</th>
                                <th className="py-2 px-4">상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deliveringAuctionList.map((item, index) => (
                                <>
                                    <tr
                                        key={item.auctionId}
                                        className={`border-b cursor-pointer hover:bg-gray-100 ${expandedRow === item.auctionId ? "bg-gray-50" : ""}`}
                                        onClick={() => toggleRow(item.auctionId)}
                                    >
                                        <td className="py-2 px-4">{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                        <td className="py-2 px-4 truncate" title={item.auction.title}>{item.auction.title}</td>
                                        <td className="py-2 px-4 truncate" title={item.auction.auctionStatus}>{item.auction.auctionStatus}</td>
                                        <td className="py-2 px-4">{item.deliveryStatus}</td>
                                    </tr>
                                    {expandedRow === item.auctionId && (
                                        <tr className="bg-gray-50">
                                            <td colSpan={5} className="py-6 px-6 text-left">
                                                <div className="flex flex-col gap-4">
                                                    <div className="flex flex-col gap-2">
                                                        <span className="font-bold">경매 정보</span>
                                                        <span>경매 제목: {item.auction.title}</span>
                                                        <span>입찰자 수 :{item.auction.numberOfBidders}명</span>
                                                        {/* <span>낙찰자 : {item.auction.auctionUser.userId}</span> */}
                                                        <span>낙찰가 : {item.auction.currentPrice}클랩</span>
                                                    </div>
                                                    <div className="flex flex-col gap-2">
                                                        <span className="font-bold">고객 정보</span>
                                                        {/* <span>{item.auction.}</span> */}
                                                        <span>닉네임: {item.post.postWriter.name} </span>
                                                        <span>이메일: {item.post.postWriter.email} </span>
                                                        <span>생년월일: {item.post.postWriter.birth} </span>
                                                        <span>주소: {item.post.postWriter.address}</span>
                                                        <span>전화번호: {item.post.postWriter.phone}</span>
                                                    </div>
                                                    <div className="flex flex-col gap-2">
                                                        <span className="font-bold">낙찰자 정보</span>
                                                        <span>닉네임: {item.winner.name} </span>
                                                        <span>이메일: {item.winner.email} </span>
                                                        <span>생년월일: {item.winner.birth} </span>
                                                        <span>주소: {item.winner.address}</span>
                                                        <span>전화번호: {item.winner.phone}</span>
                                                    </div>
                                                    <div className="flex flex-col gap-2">
                                                        <span className="font-bold">현재 배송 상태</span>
                                                        <span>{item.deliveryStatus}</span>
                                                    </div>
                                                    <div className="flex justify-end gap-2">
                                                        {/* <button
                                                        className="bg-primary-500 hover:bg-primary-600 text-white font-bold rounded-lg px-4 py-2 transition-all duration-150 ease-in-out"
                                                        onClick={() => handleRequestDelivery(item.auctionId)}
                                                    >
                                                        물품 배송 요청
                                                    </button> */}
                                                        <button
                                                            className="bg-primary-500 hover:bg-primary-600 text-white font-bold rounded-lg px-4 py-2 transition-all duration-150 ease-in-out disabled:bg-achromatic-500"
                                                            onClick={() => handleArriveDelivery(item.auctionId)}
                                                            disabled={isDisabled(item.deliveryStatus, AuctionDeliveryStatus.CREATOR_DELIVERY)}
                                                        >
                                                            도착 확인
                                                        </button>
                                                        <button
                                                            className="bg-primary-500 hover:bg-primary-600 text-white font-bold rounded-lg px-4 py-2 transition-all duration-150 ease-in-ou disabled:bg-achromatic-500"
                                                            onClick={() => handleCompleteInspection(item.auctionId)}
                                                            disabled={isDisabled(item.deliveryStatus, AuctionDeliveryStatus.INSPECTION)}
                                                        >
                                                            검수 완료
                                                        </button>
                                                        <button
                                                            className="bg-primary-500 hover:bg-primary-600 text-white font-bold rounded-lg px-4 py-2 transition-all duration-150 ease-in-out disabled:bg-achromatic-500"
                                                            onClick={() => handleCompleteDelivery(item.auctionId)}
                                                            disabled={isDisabled(item.deliveryStatus, AuctionDeliveryStatus.DELIVERY)}
                                                        >
                                                            배송 완료
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="mt-6 flex justify-center">
                    <ReactPaginate
                        previousLabel={"이전"}
                        nextLabel={"다음"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"flex space-x-2"}
                        activeClassName={"active bg-primary-500 transition-all duration-150 ease-in-out"}
                        previousClassName={"px-3 py-1 border rounded"}
                        nextClassName={"px-3 py-1 border rounded"}
                        pageClassName={"px-3 py-1 border rounded"}
                        activeLinkClassName={"text-white"}
                    />
                </div>
            </div>
        </div>
    );
}

export default DeliveryManageComponent;