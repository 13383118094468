import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { createPaymentIntent } from "apis";
import { PageHeader, AuctionComponent, WalletComponent, MobileTabHeader } from "components";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

export default function Page() {
    const [activeTab, setActiveTab] = useState<"auction" | "wallet">("auction");

    return (
        <div className="auction flex w-full  mobile:pb-[56px]">
            <div className="flex max-w-[680px] w-full h-full min-h-screen flex-col border-achromatic-100 border-x">
                <PageHeader title="경매 & 지갑" />
                <MobileTabHeader activeTab={activeTab} setActiveTab={setActiveTab} />

                {activeTab === "auction" && <AuctionComponent />}
                {activeTab === "wallet" && <WalletComponent />}
            </div>
            <div className="max-w-[360px] w-full mobile:hidden">
                <WalletComponent />
            </div>
        </div>
    );
}