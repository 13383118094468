import { create } from "zustand";

import { CreatorDTO, UserDTO } from "types";

interface UserStore {
  user: UserDTO;
  setUser: (user: UserDTO) => void;
  creator: CreatorDTO;
  setCreator: (creator: CreatorDTO) => void;
}

export const useUserStore = create<UserStore>((set) => ({
  user: {} as UserDTO,
  setUser: (user) => set({ user }),
  creator: {} as CreatorDTO,
  setCreator: (creator) => set({ creator }),
}));
