import { Outlet, useLocation } from "react-router-dom";

export default function Page() {
    const location = useLocation();
    const isAuthPage = location.pathname.startsWith("/admin/login");

    return (
        <div className="admin flex flex-col">
            {!isAuthPage && (
                <div className="bg-primary-500 w-full h-[50px]"></div>
            )}
            <div className="w-full h-full px-8">
                <Outlet />
            </div>
        </div>
    );
}