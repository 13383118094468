import { useEffect } from "react";

import { getPostListByUser } from "apis";
import { Loading, Post } from "components";
import { PostDTO, UserDTO } from "types";
import { useInfiniteQuery } from "react-query";

interface PostWrapperProps {
    user: UserDTO;
}

const UserPostWrapper = ({ user }: PostWrapperProps) => {
    const {
        data,
        isLoading,
        isError,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery(
        ["posts", user.id], // Query Key
        ({ pageParam = 1 }) => getPostListByUser(pageParam, 10, user.id), // API 호출 함수
        {
            getNextPageParam: (lastPage, allPages) => {
                const totalItems = lastPage.data.data.total;
                const itemsPerPage = 10;
                const currentPage = allPages.length;
                const totalPages = Math.ceil(totalItems / itemsPerPage);

                return currentPage < totalPages ? currentPage + 1 : undefined;
            },
            enabled: !!user.id, // user.id가 있을 때만 실행
        }
    );

    const handleScroll = () => {
        if (
            window.innerHeight + window.scrollY >= document.body.offsetHeight &&
            hasNextPage &&
            !isFetchingNextPage
        ) {
            fetchNextPage();
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [hasNextPage, isFetchingNextPage]);

    if (isLoading) return <Loading />;
    if (isError) return <div>Error loading posts.</div>;

    const posts = data?.pages.flatMap((page) => page.data.data.items) ?? [];

    return (
        <div className="flex flex-col">
            {posts.map((post: PostDTO) => (
                <Post key={post.id} post={post} />
            ))}
            {isFetchingNextPage && <Loading />}
        </div>
    );
};

export default UserPostWrapper;