import React, { useEffect, useRef, useState } from "react";

import { AdjustInput, BaseModal, Button } from "components";

import { ReactComponent as CoinIcon } from "assets/images/coin.svg";
import { AuctionDTO, AuctionStatus, BidStatus, BidStatusDTO } from "types";
import { useAlertStore, useWalletStore } from "store";
import { useNavigate } from "react-router-dom";

interface AuctionBidModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  auction: AuctionDTO;
  onBid: (startingPrice: string) => void;
  onImmediatePurchase: (immediatePrice: string) => void;
  bidExistence?: BidStatusDTO;
}

const AuctionBidModal = ({
  isOpen,
  onClose,
  title,
  auction,
  onBid,
  onImmediatePurchase,
  bidExistence
}: AuctionBidModalProps) => {
  const showAlert = useAlertStore((state) => state.showAlert);
  const nagivate = useNavigate();
  const startingPriceInputRef = useRef<HTMLInputElement>(null); // 시작가 input의 ref
  const [startingPrice, setStartingPrice] = useState<string>("");
  const [isStartingPriceError, setIsStartingPriceError] =
    useState<boolean>(false); // 오류 발생 여부 상태
  const [startingPriceErrorMessage, setStartingPriceErrorMessage] =
    useState<string>(""); // 오류 메시지 상태
  const [remainingTime, setRemainingTime] = useState<{ days: number; hours: number; minutes: number }>({ days: 0, hours: 0, minutes: 0 });
  const { wallet } = useWalletStore();

  useEffect(() => {
    const calculateTimeLeft = () => {
      const createdAt = new Date(auction.createdAt!);
      const periodDays = auction.period;
      const endDate = new Date(createdAt);
      endDate.setDate(createdAt.getDate() + Math.floor(periodDays));
      endDate.setHours(createdAt.getHours() + Math.floor((periodDays % 1) * 24));

      const now = new Date();
      const timeDifference = endDate.getTime() - now.getTime();

      let remainingDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      let remainingHours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let remainingMinutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

      if (timeDifference <= 0) {
        if (auction.auctionStatus === AuctionStatus.ONGOING) {
          remainingDays = 0;
          remainingHours = 0;
          remainingMinutes = 1;
        } else {
          remainingDays = 0;
          remainingHours = 0;
          remainingMinutes = 0;
        }
      }

      setRemainingTime({
        days: remainingDays,
        hours: remainingHours,
        minutes: remainingMinutes,
      });
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 60000); // update every 60 seconds

    return () => clearInterval(timer);
  }, [auction.createdAt, auction.period, auction.auctionStatus]);


  const handleBidReview = () => {
    let showError = () => { };
    const price = Number(startingPrice);

    if (isStartingPriceError) {
      showError = () => showAlert("입찰 금액을 확인해주세요.", "error");
      showError();
      return;
    }

    const isFirstBid = auction.startPrice === auction.currentPrice && auction.numberOfBidders === 0;
    const isImmediateBid = auction.immediatePrice === price;
    const hasSufficientFunds = auction.startPrice <= wallet.klav;

    if (isImmediateBid) {
      if (hasSufficientFunds) {
        onImmediatePurchase(startingPrice);
      } else {
        showAlert("보유한 클랩이 부족합니다.", "error");
      }
      return;
    }

    if (bidExistence) {
      if (
        (isFirstBid && price >= auction.currentPrice) ||
        (!isFirstBid && price > auction.currentPrice)
      ) {
        onBid(startingPrice);
      } else {
        showError();
      }
    } else {
      if (
        (isFirstBid && price >= auction.currentPrice && hasSufficientFunds) ||
        (!isFirstBid && price > auction.currentPrice && hasSufficientFunds)
      ) {
        onBid(startingPrice);
      } else {
        showError();
      }
    }
  };

  const handleDivClick = () => {
    if (startingPriceInputRef.current) {
      startingPriceInputRef.current.focus();
    }
  };

  const handeChargeClick = () => {
    nagivate("/auction");
  }

  const calculateFees = (price: number) => {
    if (price >= 10 && price <= 49) {
      return { deposit: 5, creator: 2, company: 1, winner: 2 };
    } else if (price >= 50 && price <= 99) {
      return { deposit: 10, creator: 3, company: 3, winner: 4 };
    } else if (price >= 100 && price <= 499) {
      return { deposit: 20, creator: 6, company: 6, winner: 8 };
    } else if (price >= 500) {
      return { deposit: 50, creator: 15, company: 15, winner: 20 };
    } else {
      throw new Error("최소 즉시구매가는 10 klav 이상이어야 합니다.");
    }
  }

  useEffect(() => {
    if (Number(startingPrice) > auction.immediatePrice) {
      setIsStartingPriceError(true); // 오류 발생
      setStartingPriceErrorMessage(`${auction.immediatePrice} 클랩을 넘을 수 없습니다.`); // 오류 메시지 설정
    } else {
      setIsStartingPriceError(false); // 오류 발생하지 않음
      if (auction.numberOfBidders === 0) {
        setStartingPriceErrorMessage(
          `${auction.startPrice} 클랩(${auction.startPrice * 1000
          })이상으로 입찰 가능합니다.`
        ); // 오류 메시지 초기화
      } else {
        setStartingPriceErrorMessage(
          `${auction.currentPrice + 1} 클랩(${(auction.currentPrice + 1) * 1000
          })이상으로 입찰 가능합니다.`
        ); // 오류 메시지 초기화
      }
    }
  }, [startingPrice]);

  return (
    <BaseModal title={title} isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-[60px] w-[560px] mobile:w-[292px]">
        <div className="flex flex-col text-sm font-semibold pt-8 gap-8">
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              시작가
            </span>
            <span>{auction.startPrice}클랩</span>
          </div>
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              최고가
            </span>
            <span>
              {auction.currentPrice}클랩 · 입찰자 {auction.numberOfBidders}명
            </span>
          </div>
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              남은 시간
            </span>
            {(remainingTime.days === 0 && remainingTime.hours === 0 && remainingTime.minutes <= 1) ? (
              <span className="text-[#F04D1A] font-semibold text-sm">
                1분 이내 종료
              </span>
            ) : (
              <span>
                {remainingTime.days}일 {remainingTime.hours}시간 {remainingTime.minutes}분 후
                종료
              </span>
            )}
          </div>
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              보증금
            </span>
            <span>{calculateFees(auction.immediatePrice).deposit}클랩</span>
          </div>

          <div className="border-b border-achromatic-100" />

          <div className="flex flex-col gap-2">
            <span className="w-[120px] font-medium text-sm text-achromatic-400">
              사용 가능한 클랩
            </span>
            <div className="flex justify-between">
              <div className="flex gap-2 items-end">
                <div className="flex gap-1 text-achromatic-600 font-medium text-base leading-[19px]">
                  <CoinIcon />
                  <span className="text-center">{wallet.klav}클랩</span>
                </div>
                <div className="flex items-center font-medium text-xs text-achromatic-300 leading-[19px]">
                  1 클랩 = 1000 원
                </div>
              </div>
              <div>
                <button
                  className="bg-primary-500 rounded-md text-white font-semibold text-sm py-2 px-6 transition-all duration-150 ease-in-out hover:bg-primary-600"
                  onClick={handeChargeClick}
                >
                  클랩 충전
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <span className="w-[120px] font-medium text-sm text-achromatic-400">
              나의 입찰가
            </span>
            <div className="flex gap-2">
              <div
                className="flex w-full gap-2 border items-center border-achromatic-100 rounded-lg"
                onClick={handleDivClick}
              >
                <CoinIcon className="ml-3 w-[18px] h-[18px]" />
                <AdjustInput
                  ref={startingPriceInputRef}
                  inputValue={startingPrice}
                  setInputValue={setStartingPrice}
                  isError={isStartingPriceError}
                />
                {startingPrice && <div className="flex-1 flex mobile:flex-none mobile:ml-auto mobile:mr-4">클랩</div>}
              </div>
              <div className="w-full max-w-[120px]">
                <Button text="입찰 검토" onClick={handleBidReview} />
              </div>
            </div>
            <span className="font-medium text-sm text-achromatic-800">
              {/* {auction.currentPrice} 클랩({auction.currentPrice * 1000})이상으로
              입찰 가능합니다. */}
              {startingPriceErrorMessage}
            </span>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default AuctionBidModal;
