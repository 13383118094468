const AccountWithdrawn = () => {
    return (
        <div className="account-withdrawn flex flex-col bg-white border border-achromatic-100 rounded-2xl gap-16 mobile:rounded-none mobile:border-0">
            <div className="flex flex-col gap-6 items-center text-center p-16 mobile:p-6">
                <h1>탈퇴된 계정입니다.</h1>
                <p>계정 복구를 원하시면 고객센터로 문의해주세요.</p>
            </div>
        </div>
    );
}

export default AccountWithdrawn;