import { ReactComponent as BackArrow } from 'assets/images/back.arrow.svg'

interface PageHeaderProps {
    title: string;
    isArrow?: boolean;
}

const PageHeader = ({ title, isArrow }: PageHeaderProps) => {
    const handleBackClick = () => {
        window.history.back();
    }

    return (
        <div className='flex border-b min-h-[76px] border-achromatic-100 px-6 items-center gap-2'>
            {
                isArrow && (
                    <BackArrow className='w-4 h-4 cursor-pointer' onClick={handleBackClick} />
                )
            }
            <h1 className='text-lg font-semibold text-black'>{title}</h1>
        </div>
    );
}

export default PageHeader;