import { AxiosResponse } from "axios";

import { post, get } from "utils";
import { ResponseDTO, UserDTO, CreatorDTO } from "types";

const baseUrl = `${process.env.REACT_APP_API_URL}/creator`;

export const createCreator = async (
  accountNumber: string,
  isCreatorTermsAgreed: boolean,
  identificationImage: File | null
): Promise<AxiosResponse<ResponseDTO<UserDTO>>> => {
  let url = `${baseUrl}/create`;

  const formData = new FormData();

  formData.append("accountNumber", accountNumber);
  formData.append("isCreatorTermsAgreed", isCreatorTermsAgreed.toString());
  if (identificationImage) {
    formData.append("file", identificationImage);
  }

  return post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getCreatorProposeInfo = async (): Promise<
  AxiosResponse<ResponseDTO<CreatorDTO>>
> => {
  let url = `${baseUrl}/propose-info`;

  return get(url);
};

export const getRecommendCreatorList = async (): Promise<
  AxiosResponse<ResponseDTO<CreatorDTO[]>>
> => {
  let url = `${baseUrl}/recommend`;

  return get(url);
};

export const getCreatorInfo = async (): Promise<
  AxiosResponse<ResponseDTO<CreatorDTO>>
> => {
  let url = `${baseUrl}/info`;

  return get(url);
};
