import { getFAQList, putCompleteFAQ } from "apis";
import { ImageGallery } from "components";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import { SupportDTO } from "types";

const FAQManageComponent = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const itemsPerPage = 10;

    const { data, isLoading, isError } = useQuery(
        ["faqList", currentPage, itemsPerPage],
        () => getFAQList(currentPage, itemsPerPage),
        {
            keepPreviousData: true,
        }
    );

    if (isLoading) return <div>로딩 중...</div>;
    if (isError) return <div>오류가 발생했습니다.</div>;

    const faqList = data?.data.data.items || [];
    const totalItems = data?.data.data.total || 0;
    const pageCount = Math.ceil(totalItems / itemsPerPage);

    const handlePageClick = ({ selected }: { selected: number }) => {
        setCurrentPage(selected + 1);
    };

    const handleCompleteClick = async () => {
        try {
            const response = await putCompleteFAQ(expandedRow!);
            if (response.status === 200) {
                alert("처리 완료되었습니다.");
                setCurrentPage(1);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const toggleRow = (id: string) => {
        setExpandedRow(expandedRow === id ? null : id);
    };

    return (
        <div className="h-full flex flex-col gap-6 py-6 px-6">
            <div className="text-2xl font-bold">고객 문의 내역 조회</div>

            <div className="flex items-center bg-white rounded-md h-[100px] shadow">
                <div className="flex px-6 text-lg font-medium">총 {totalItems}건</div>
            </div>

            <div className="bg-white rounded-md shadow flex flex-col px-6 py-8">
                <div className="overflow-x-auto">
                    <table className="w-full text-center border-collapse">
                        <thead>
                            <tr className="border-b">
                                <th className="py-2 px-4">번호</th>
                                <th className="py-2 px-4">제목</th>
                                <th className="py-2 px-4">내용</th>
                                <th className="py-2 px-4">이메일</th>
                                <th className="py-2 px-4">처리</th>
                            </tr>
                        </thead>
                        <tbody>
                            {faqList.map((item, index) => (
                                <>
                                    <tr
                                        key={item.id}
                                        className={`border-b cursor-pointer hover:bg-gray-100 ${expandedRow === item.id ? "bg-gray-50" : ""
                                            }`}
                                        onClick={() => toggleRow(item.id)}
                                    >
                                        <td className="py-2 px-4">{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                        <td className="py-2 px-4 truncate" title={item.title}>
                                            {item.title}
                                        </td>
                                        <td className="py-2 px-4 truncate" title={item.content}>
                                            {item.content}
                                        </td>
                                        <td className="py-2 px-4">{item.user.email}</td>
                                        {item.isAnswered ? (
                                            <td className="py-2 px-4 bg-green-500 bg-opacity-10">처리완료</td>
                                        ) : (
                                            <td className="py-2 px-4 bg-red-500 bg-opacity-10">처리대기</td>
                                        )}
                                    </tr>
                                    {expandedRow === item.id && (
                                        <tr className="bg-gray-50">
                                            <td colSpan={5} className="py-6 px-6 text-left">
                                                <div className="flex flex-col gap-2">
                                                    <div className="flex flex-col">
                                                        <span className="font-bold">제목</span>
                                                        <span>{item.title}</span>
                                                    </div>
                                                    <div className="flex flex-col gap-2">
                                                        <span className="font-bold">내용</span>
                                                        <span>{item.content}</span>
                                                        <ImageGallery
                                                            images={[item.contentImage]}
                                                            isAuction={false}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-2">
                                                        <span className="font-bold">고객 정보</span>
                                                        <span>닉네임: {item.user.name} </span>
                                                        <span>이메일: {item.user.email} </span>
                                                        <span>생년월일: {item.user.birth} </span>
                                                        <span>주소: {item.user.address}</span>
                                                    </div>
                                                    <div className="flex justify-end">
                                                        <button
                                                            className="bg-primary-500 hover:bg-primary-600 text-white font-medium rounded-lg px-4 py-2 transition-all duration-150 ease-in-out"
                                                            onClick={handleCompleteClick}
                                                        >
                                                            처리완료
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="mt-6 flex justify-center">
                    <ReactPaginate
                        previousLabel={"이전"}
                        nextLabel={"다음"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"flex space-x-2"}
                        activeClassName={"active bg-primary-500 transition-all duration-150 ease-in-out"}
                        previousClassName={"px-3 py-1 border rounded"}
                        nextClassName={"px-3 py-1 border rounded"}
                        pageClassName={"px-3 py-1 border rounded"}
                        activeLinkClassName={"text-white"}
                    />
                </div>
            </div>
        </div>
    );
};

export default FAQManageComponent;
