import { NavLink, useLocation } from 'react-router-dom';

import { sideBarLinks as initialSideBarLinks } from 'data';

import { ReactComponent as FullLogoIcon } from 'assets/images/full.logo.svg';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getUnreadNotificationCount } from 'apis';

const SideBar = () => {
    const location = useLocation();
    const [sideBarLinks, setSideBarLinks] = useState(initialSideBarLinks)

    const { isLoading, isError } = useQuery('sideBarLinks', getUnreadNotificationCount, {
        // enabled: false,
        onSuccess: (data) => {
            const updatedSideBarLinks = sideBarLinks.map((link) => {
                if (link.href === '/notification') {
                    return {
                        ...link,
                        notificationCount: data.data.data || 0,
                    };
                }
                return link;
            });
            setSideBarLinks(updatedSideBarLinks);
        }
    });

    return (
        <div className='h-full bg-white mobile:w-full mobile:h-[56px]'>
            <div className="flex flex-col h-full p-6 mobile:py-4">
                <div className='flex py-3 mobile:hidden'>
                    <FullLogoIcon className='w-[125.65px] h-5' />
                </div>
                <div className='flex flex-col gap-2 pt-6 mobile:flex-row mobile:p-0 mobile:justify-between'>
                    {sideBarLinks.map((link, index) => {
                        const Icon = link.icon;
                        // 홈 경로인지 확인하여 "/"인 경우에는 정확히 일치해야만 활성화되도록 처리
                        const isHomeLink = link.href === '/';
                        const isActive = isHomeLink
                            ? location.pathname === link.href // 홈 경로는 정확히 일치하는 경우에만 활성화
                            : location.pathname.startsWith(link.href); // 그 외에는 시작 경로가 일치하면 활성화

                        return (
                            <NavLink
                                key={index}
                                to={link.href}
                                className={() =>
                                    `relative flex items-center gap-4 py-3 mobile:p-0 cursor-pointer ${isActive ? 'text-primary-500' : 'text-achromatic-500'}`
                                }
                            >
                                {({ isActive: navLinkIsActive }) => {
                                    const finalIsActive = isActive || navLinkIsActive;
                                    const iconColor = finalIsActive ? '#09de50' : '#686f74';

                                    return (
                                        <>
                                            {/* SVG 아이콘의 색상을 style로 동적으로 설정 */}
                                            <div className='relative flex'>
                                                <Icon className='w-6 h-6' style={{ stroke: iconColor }} />
                                                {(link.notificationCount ?? 0) > 0 && (
                                                    <span className='absolute top-0 right-0 w-3 h-3 bg-orange-500 rounded-full' />
                                                )}
                                            </div>

                                            <span className='text-sm font-semibold mobile:hidden' style={{ color: iconColor }}>{link.title}</span>
                                        </>
                                    );
                                }}
                            </NavLink>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default SideBar;
