import { FollowingComponent, PageHeader, PostWrapper, ProfileComponent } from "components";
import { useState } from "react";
import { useUserStore } from "store";

const MyPageComponent = () => {
    const { user } = useUserStore();
    const [showFollowComponent, setShowFollowComponent] = useState(false);

    const handleFollowClick = () => {
        setShowFollowComponent(prev => !prev);
    };


    return (
        <div className="flex flex-col">
            <PageHeader title="마이페이지" />
            <ProfileComponent onFollowClick={handleFollowClick} showFollowComponent={showFollowComponent} />
            {user.role === "creator" && !showFollowComponent ? (
                <PostWrapper />
            ) : (
                <FollowingComponent />
            )}
        </div>
    );
}

export default MyPageComponent;