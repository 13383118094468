import { AuctionNoticeModal, Loading } from "components";
import { useEffect, useState } from "react";
import { AuctionStatus, PostDTO } from "types";

interface AuctionDetailComponentProps {
    post?: PostDTO;
}

const AuctionDetailComponent = ({ post }: AuctionDetailComponentProps) => {
    const [isAuctionNoticeModalOpen, setIsAuctionNoticeModalOpen] = useState<boolean>(false);
    const [remainingTime, setRemainingTime] = useState<{ days: number; hours: number; minutes: number }>({ days: 0, hours: 0, minutes: 0 });

    useEffect(() => {
        if (!post) return;

        const calculateTimeLeft = () => {
            const createdAt = new Date(post.auction.createdAt!);
            const periodDays = post.auction.period;
            const endDate = new Date(createdAt);
            endDate.setDate(createdAt.getDate() + Math.floor(periodDays));
            endDate.setHours(createdAt.getHours() + Math.floor((periodDays % 1) * 24));

            const now = new Date();
            const timeDifference = endDate.getTime() - now.getTime();

            let remainingDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            let remainingHours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let remainingMinutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

            if (timeDifference <= 0) {
                remainingDays = 0;
                remainingHours = 0;
                remainingMinutes = 0;
            }

            setRemainingTime({
                days: remainingDays,
                hours: remainingHours,
                minutes: remainingMinutes,
            });
        };

        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 60000);

        return () => clearInterval(timer);
    }, [post]);

    if (!post) {
        return <Loading />
    }

    const openModal = () => {
        setIsAuctionNoticeModalOpen(true);
    }

    const closeModal = () => {
        setIsAuctionNoticeModalOpen(false);
    }

    return (
        <div className="auction-detail flex flex-col gap-6">
            <div className="flex flex-col gap-4">
                <div>
                    <span className='font-semibold text-xs rounded-[4px] bg-[#E2F2E8] text-primary-600 py-1 px-[6px]'>
                        {post.auction.numberOfBidders}명 입찰 참여중
                    </span>
                </div>
                <div className='flex flex-col gap-2'>
                    <span className='font-semibold text-lg'>{post.auction.title}</span>
                    {post.auction.auctionStatus === AuctionStatus.FINISHED || post.auction.auctionStatus === AuctionStatus.PASSED || post.auction.auctionStatus === AuctionStatus.WINNING ? (
                        <span className="text-[#F04D1A] font-semibold text-sm">
                            경매 종료
                        </span>
                    ) : (
                        (remainingTime.days === 0 && remainingTime.hours === 0 && remainingTime.minutes <= 1) ? (
                            <span className="text-[#F04D1A] font-semibold text-sm">
                                1분 이내 종료
                            </span>
                        ) : (
                            <span className="text-[#F04D1A] font-semibold text-sm">
                                {remainingTime.days}일 {remainingTime.hours}시간 {remainingTime.minutes}분 후 종료
                            </span>
                        )
                    )}
                </div>
            </div>
            <div className='flex flex-col justify-center gap-1'>
                <span className='text-xs font-medium text-achromatic-500'>시작가</span>
                <span className='text-[22px] font-semibold'>{post.auction.startPrice}클랩</span>
            </div>
            <div className='flex flex-col justify-center gap-1'>
                <span className='text-xs font-medium text-achromatic-500'>현재가</span>
                <span className='text-[22px] font-semibold'>{post.auction.currentPrice}클랩</span>
            </div>
            <div className='flex justify-between gap-1 mobile:flex-row mobile:justify-between'>
                <div className="flex flex-col justify-center gap-1">
                    <span className='text-xs font-medium text-achromatic-500'>즉시 구매가</span>
                    <span className='text-[22px] font-semibold'>{post.auction.immediatePrice}클랩</span>
                </div>
                <div
                    className="content-end mobile:flex mobile:flex-col mobile:justify-end cursor-pointer"
                    onClick={openModal}
                >
                    <span className='font-semibold text-xs rounded-[4px] bg-opacity-[8%] bg-[#F04D1A] text-[#F04D1A] py-1 px-[6px]'>
                        {/* {auction.auctionStatus === AuctionStatus.ONGOING ? "경매 프로세스" : auction.auctionStatus} */}
                        경매 프로세스
                    </span>
                </div>
            </div>

            <div>
                <AuctionNoticeModal
                    title="경매 참여 방법"
                    isOpen={isAuctionNoticeModalOpen}
                    onClose={closeModal}
                    onAgree={() => { closeModal() }}
                />
            </div>
        </div>
    )
}

export default AuctionDetailComponent