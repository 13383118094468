const AdminSideBarLinks = [
  { href: "/admin", title: "Dashboard", icon: "" },
  { href: "/admin/user", title: "유저관리", icon: "" },
  { href: "/admin/delivery", title: "배송관리", icon: "" },
  { href: "/admin/faq", title: "문의", icon: "" },
  { href: "/admin/report", title: "신고", icon: "" },
  { href: "/admin/creator", title: "크리에이터", icon: "" },
  { href: "/admin/withdraw", title: "출금 신청", icon: "" },
  // { href: "/admin/settings", title: "설정", icon: "" },
];

export default AdminSideBarLinks;
