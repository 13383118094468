import { WalletDTO } from "types/DTOs/wallet/wallet.dto";
import { create } from "zustand";

interface WalletStore {
  wallet: WalletDTO;
  setWallet: (wallet: WalletDTO) => void;
}

export const useWalletStore = create<WalletStore>((set) => ({
  wallet: {} as WalletDTO,
  setWallet: (wallet) => set({ wallet }),
}));
