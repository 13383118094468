import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StripeElementsOptions } from '@stripe/stripe-js';

import { depositList } from 'data';
import { Button, WithdrawModal } from 'components'

import { ReactComponent as CoinIcon } from 'assets/images/coin.svg';
import { ReactComponent as BigCoinIcon } from 'assets/images/big.coin.svg';
import { useAlertStore, useUserStore, useWalletStore } from 'store';
import { getMyWallet, postCheckoutSession } from 'apis';

interface DepositItemProps {
    klav: number;
}

// const DepositItem = ({ klav }: DepositItemProps) => {

//     const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
//     const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);

//     const handleItemClick = () => {
//         setIsPaymentModalOpen(true);
//         window.open('/charge/checkout', '_popup', 'width=400, height=600');
//     }

//     return (
//         <div
//             className='min-h-[148px] border border-achromatic-100 rounded-2xl p-6 transition duration-150 ease-in-out cursor-pointer hover:bg-achromatic-100'
//             onClick={handleItemClick}
//         >
//             <div className='flex flex-col gap-6 items-center'>
//                 <BigCoinIcon className='' />
//                 <div className='flex flex-col gap-1 font-medium text-center'>
//                     <span className='text-achromatic-300 text-sm'>{klav} 클랩</span>
//                     <span className='text-primary-500 text-base'>{(klav * 1000).toLocaleString()}원</span>
//                 </div>
//             </div>
//         </div>
//     )
// }
const DepositItem = ({ klav }: DepositItemProps) => {
    const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);

    const handleItemClick = async () => {
        try {
            setIsPaymentLoading(true);

            // klavAmount 인자를 전달합니다.
            const response = await postCheckoutSession(
                klav * 1000
            );
            if (response.data && response.data.url) {
                window.open(response.data.url, '_blank');
            } else {
                console.error('결제 세션 URL을 가져오지 못했습니다.');
            }
        } catch (error) {
            console.error('결제 오류:', error);
        } finally {
            setIsPaymentLoading(false);
        }
    };

    return (
        <div
            className='min-h-[148px] border border-achromatic-100 rounded-2xl p-6 transition duration-150 ease-in-out cursor-pointer hover:bg-achromatic-100'
            onClick={handleItemClick}
        >
            <div className='flex flex-col gap-6 items-center'>
                <BigCoinIcon />
                <div className='flex flex-col gap-1 font-medium text-center'>
                    <span className='text-achromatic-300 text-sm'>{klav} 클랩</span>
                    <span className='text-primary-500 text-base'>{(klav * 1000).toLocaleString()}원</span>
                </div>
            </div>
        </div>
    );
};

const WalletComponent = () => {
    const showAlert = useAlertStore((state) => state.showAlert);
    const { user, creator } = useUserStore();
    const { wallet, setWallet } = useWalletStore();
    const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState<boolean>(false);

    const openWithdrawModal = () => {
        if (user.role !== 'creator') {
            showAlert('크리에이터만 출금이 가능합니다.', 'error');
            return;
        }
        setIsWithdrawModalOpen(true);
    }
    const closeModal = () => setIsWithdrawModalOpen(false);

    useEffect(() => {
        const handlePaymentMessage = async (event: MessageEvent) => {
            if (event.data === 'paymentSuccess') {
                showAlert('클랩 충전이 완료되었습니다.', 'success');

                const response = await getMyWallet();
                if (response.data && response.data.data) {
                    setWallet(response.data.data);
                    closeModal();
                }
            }
        }

        window.addEventListener('message', handlePaymentMessage);

        return () => {
            window.removeEventListener('message', handlePaymentMessage);
        }
    }, [showAlert])

    return (
        <div className="h-full font-semibold text-sm border-achromatic-100 border-r">
            <div className="flex flex-col h-full p-6 gap-6">
                <div className="flex flex-col gap-4">
                    <div className="font-medium text-achromatic-400">지갑</div>
                    <div className='flex justify-between items-center'>
                        <div className='flex gap-1 text-achromatic-600 font-medium text-base'>
                            <CoinIcon />
                            <span className='text-center'>{wallet.klav} 클랩</span>
                        </div>
                        <div className='font-medium text-xs text-achromatic-300'>1 클랩 = 1000 원</div>
                    </div>
                    <Button text="출금하기" onClick={openWithdrawModal} />
                </div>

                <div className='border-b border-achromatic-100' />

                <div className='flex flex-col gap-6'>
                    <div className='text-achromatic-600'>클랩 충전하기</div>
                    <div className='grid grid-cols-2 gap-2'>
                        {depositList.map((item, index) => (
                            <DepositItem
                                key={index}
                                klav={item.klav}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <div>
                <WithdrawModal
                    isOpen={isWithdrawModalOpen}
                    onClose={closeModal}
                    title="출금할 클랩을 적어주세요."
                    account={creator.accountNumber}
                    balance={wallet.klav}
                />
            </div>

            {/* CardElement를 WalletComponent 내에 렌더링 */}
            {/* <div style={{ display: 'none' }}>
                <CardElement />
            </div> */}

        </div>

    );
}

export default WalletComponent;
