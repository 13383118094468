import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import {
  OngoingAuctionsComponent,
  WonAuctionsComponent,
} from "components";
import { checkUserBidExistence, getAuctionListByUserId } from "apis";
import { AuctionStatus, BidStatus, BidStatusDTO, PostDTO } from "types";
import { useUserStore } from "store";

const AuctionWrapper = () => {
  const [activeTab, setActiveTab] = useState<"ongoing" | "won">("ongoing");
  const [bidExistenceMap, setBidExistenceMap] = useState<{ [key: string]: BidStatusDTO }>({});
  const { data, isLoading, isError } = useQuery("auctionList", getAuctionListByUserId);

  const { user } = useUserStore();
  // 각 경매 아이템의 bidExistence 상태를 비동기로 가져오기
  const fetchBidExistence = async (postId: string, auctionId: string) => {
    const response = await checkUserBidExistence(auctionId);
    return { postId, bidExistence: response.data.data };
  };

  useEffect(() => {
    if (data && data.data.data) {
      const fetchAllBidExistence = async () => {
        const promises = data.data.data.map((post: PostDTO) =>
          fetchBidExistence(post.id, post.auction.id)
        );
        const results = await Promise.all(promises);
        const bidMap = results.reduce((map: { [key: string]: BidStatusDTO }, { postId, bidExistence }) => {
          map[postId] = bidExistence;
          return map;
        }, {} as { [key: string]: BidStatusDTO });
        setBidExistenceMap(bidMap);
      };

      fetchAllBidExistence();
    }
  }, [data]);

  const onGoingPost = useMemo(() => {
    if (Object.keys(bidExistenceMap).length === 0) return [];

    return (
      data?.data.data.filter((post: PostDTO) => {
        const bidExistence = bidExistenceMap[post.id];
        const isMine = post.postWriter.id === user.id;

        return (
          post.auction.auctionStatus !== AuctionStatus.PASSED &&
          post.auction.auctionStatus !== AuctionStatus.FINISHED &&
          (
            post.auction.auctionStatus !== AuctionStatus.WINNING ||
            bidExistence.bidStatus === BidStatus.HIGHEST_BID ||
            isMine
          )
        );
      }) || []
    );
  }, [data, bidExistenceMap]);

  const wonPost = useMemo(() => {
    if (Object.keys(bidExistenceMap).length === 0) return [];

    return (
      data?.data.data.filter((post: PostDTO) => {
        const bidExistence = bidExistenceMap[post.id];
        const isMine = post.postWriter.id === user.id;

        return (
          post.auction.auctionStatus === AuctionStatus.PASSED ||
          post.auction.auctionStatus === AuctionStatus.FINISHED ||
          (
            post.auction.auctionStatus === AuctionStatus.WINNING &&
            bidExistence.bidStatus !== BidStatus.HIGHEST_BID &&
            !isMine
          )
        );
      }) || []
    );
  }, [data, bidExistenceMap]);

  return (
    <div className="flex flex-col">
      <div className="flex gap-4 p-6 text-[22px] font-semibold">
        <div
          className={`transition-colors delay-50 cursor-pointer ${activeTab === "ongoing" ? "" : "text-achromatic-200"
            }`}
          onClick={() => setActiveTab("ongoing")}
        >
          진행 중인 경매
        </div>
        <div
          className={`transition-colors delay-50 cursor-pointer ${activeTab === "won" ? "" : "text-achromatic-200"
            }`}
          onClick={() => setActiveTab("won")}
        >
          종료된 경매
        </div>
      </div>
      <div className="">
        {activeTab === "ongoing" ? (
          <OngoingAuctionsComponent postList={onGoingPost} />
        ) : (
          <WonAuctionsComponent postList={wonPost} />
        )}
      </div>
    </div>
  );
};

export default AuctionWrapper;