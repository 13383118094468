import { AuctionDTO } from "types";
import { create } from "zustand";

interface AuctionStore {
  postingAuction: AuctionDTO;
  setPostingAuction: (auction: AuctionDTO) => void;
}

export const useAuctionStore = create<AuctionStore>((set) => ({
  postingAuction: {} as AuctionDTO,
  setPostingAuction: (auction) => set({ postingAuction: auction }),
}));
