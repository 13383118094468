import { create } from "zustand";

import { PostDTO } from "types";

interface PostStore {
  editingPost: PostDTO | null;
  setEditingPost: (post: PostDTO | null) => void;
  uploadedImages: string[];
  setUploadedImages: (images: string[]) => void;
  postList: PostDTO[];
  setPostList: (posts: PostDTO[]) => void;
  myPostList: PostDTO[];
  setMyPostList: (posts: PostDTO[]) => void;
  addPostList: (post: PostDTO) => void;
  addImage: (image: string) => void;
  deleteImage: (index: number) => void;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  isReAuction: boolean;
  setIsReAuction: (isReAuction: boolean) => void;
}

export const usePostStore = create<PostStore>((set) => ({
  editingPost: null,
  setEditingPost: (post) => set({ editingPost: post }),
  uploadedImages: [],
  setUploadedImages: (images) => set({ uploadedImages: images }),
  postList: [],
  setPostList: (newPosts) =>
    set((state) => {
      const existingIds = new Set(state.postList.map((post) => post.id));
      return {
        postList: [
          ...state.postList,
          ...newPosts.filter((post) => !existingIds.has(post.id)),
        ],
      };
    }),
  myPostList: [],
  setMyPostList: (newPosts) =>
    set((state) => {
      const existingIds = new Set(state.myPostList.map((post) => post.id));
      return {
        myPostList: [
          ...state.myPostList,
          ...newPosts.filter((post) => !existingIds.has(post.id)),
        ],
      };
    }),
  addPostList: (post) =>
    set((state) => ({ postList: [post, ...state.postList] })),
  addImage: (image) =>
    set((state) => ({ uploadedImages: [...state.uploadedImages, image] })),
  deleteImage: (index) =>
    set((state) => ({
      uploadedImages: state.uploadedImages.filter((_, i) => i !== index),
    })),
  isEditing: false,
  setIsEditing: (isEditing) => set({ isEditing }),
  isReAuction: false,
  setIsReAuction: (isReAuction) => set({ isReAuction }),
}));
