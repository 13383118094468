import { CustomAlert } from "components";
import { AdminLayout, AuthLayout, ChargeLayout, ContentLayout } from "layouts";
import { useLocation } from "react-router-dom";

type Props = {
    children: React.ReactNode;
};
export default function BaseLayout({ children }: Props) {
    const location = useLocation();

    // 로그인/회원가입 관련 경로를 확인하는 조건
    const isAuthPage = location.pathname.startsWith("/login") || location.pathname.startsWith("/signup") || location.pathname.startsWith("/account");
    const isChargePage = location.pathname.startsWith("/charge");
    const isAdminPage = location.pathname.startsWith("/admin");

    return (
        <div className="font-pretendard w-full h-full mobile:max-w-[360px] mobile:mx-auto mobile:h-screen mobile:max-h-screen">
            {isAuthPage ? (
                <AuthLayout>
                    {children}
                </AuthLayout>
            ) : isChargePage ? (
                <ChargeLayout>
                    {children}
                </ChargeLayout>
            ) : isAdminPage ? (
                <AdminLayout>
                    {children}
                </AdminLayout>
            ) : (
                <ContentLayout>
                    {children}
                </ContentLayout>
            )}
            <CustomAlert />
        </div >
    );
}