import {
  RightSideBar,
  SearchBar,
  PostEditor,
  PostWrapper,
  AutoScroll,
} from "components";
import { useState } from "react";
import { PostDTO } from "types";

export default function Page() {
  const [searchTerm, setSearchTerm] = useState<string>(""); // 검색어 상태
  const [localPost, setLocalPost] = useState<PostDTO>()

  const handleAddLocalPost = (newPost: PostDTO) => {
    setLocalPost(newPost);
  };

  const handleSearch = (searchText: string) => {
    setSearchTerm(searchText);
  };

  const handleDeleteLocalPost = () => {
    setLocalPost(undefined);
  }

  return (
    <div className="home flex w-full mobile:pb-[56px]">
      <div className="flex max-w-[680px] w-full flex-col border-achromatic-100 border-x">
        <SearchBar onSearch={handleSearch} value={searchTerm} />
        <PostEditor onAddLocalPost={handleAddLocalPost} />
        <PostWrapper searchTerm={searchTerm} onSearch={handleSearch} localPost={localPost} deleteLocalPost={handleDeleteLocalPost} />
        <AutoScroll />
      </div>
      <div className="max-w-[360px] w-full mobile:hidden">
        <RightSideBar />
      </div>
    </div>
  );
}
