import { getUserList, putBlockUser, putDeleteUser, putUnblockUser } from "apis";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";

const UserManageComponent = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const itemsPerPage = 10;

    const { data, isLoading, isError } = useQuery(
        ["userList", currentPage, itemsPerPage],
        () => getUserList(currentPage, itemsPerPage),
        {
            keepPreviousData: true,
        }
    );

    if (isLoading) return <div>로딩 중...</div>;
    if (isError) return <div>오류가 발생했습니다.</div>;

    const userList = data?.data.data.items || [];
    const totalItems = data?.data.data.total || 0;
    const pageCount = Math.ceil(totalItems / itemsPerPage);

    const handlePageClick = ({ selected }: { selected: number }) => {
        setCurrentPage(selected + 1);
    }

    const toggleRow = (id: string) => {
        setExpandedRow(expandedRow === id ? null : id);
    }

    const handleBlockClick = async () => {
        const response = await putBlockUser(expandedRow!);
        console.log(response);
        if (response.data.isSuccess === 'T') {
            alert("처리 완료되었습니다.");
            setCurrentPage(1);
        }
    }

    const handleUnblockClick = async () => {
        const response = await putUnblockUser(expandedRow!);
        console.log(response);
        if (response.data.isSuccess === 'T') {
            alert("처리 완료되었습니다.");
            setCurrentPage(1);
        }
    }

    const handleDeleteClick = async () => {
        const response = await putDeleteUser(expandedRow!);
        console.log(response);
        if (response.data.isSuccess === 'T') {
            alert("처리 완료되었습니다.");
            setCurrentPage(1);
        }
    }

    return (
        <div className="h-full flex flex-col gap-6 py-6 px-6">
            <div className="text-2xl font-bold">회원 목록 조회</div>

            <div className="flex items-center bg-white rounded-md h-[100px] shadow">
                <div className="flex px-6 text-lg font-medium">총 {totalItems}건</div>
            </div>

            <div className="bg-white rounded-md shadow flex flex-col px-6 py-8">
                <div className="overflow-x-auto">
                    <table className="w-full text-center border-collapse">
                        <thead>
                            <tr className="border-b">
                                <th className="py-2 px-4">번호</th>
                                <th className="py-2 px-4">이름</th>
                                <th className="py-2 px-4">이메일</th>
                                <th className="py-2 px-4">이용 상태</th>
                                <th className="py-2 px-4">상태</th>
                                <th className="py-2 px-4">권한</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userList.map((item, index) => (
                                <>
                                    <tr
                                        key={item.user.id}
                                        className={`border-b cursor-pointer hover:bg-gray-100 ${expandedRow === item.user.id ? "bg-gray-50" : ""
                                            }`}
                                        onClick={() => toggleRow(item.user.id)}
                                    >
                                        <td className="py-2 px-4">{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                        <td className="py-2 px-4 truncate" title={item.user.name}>
                                            {item.user.name}
                                        </td>
                                        <td className="py-2 px-4 truncate" title={item.user.email}>
                                            {item.user.email}
                                        </td>
                                        {item.user.isBlocked ? (
                                            <td className="py-2 px-4 bg-red-500 bg-opacity-10">이용 정지</td>
                                        ) : (
                                            <td className="py-2 px-4 bg-green-500 bg-opacity-10">이용 가능</td>
                                        )}
                                        {item.user.isDeleted ? (
                                            <td className="py-2 px-4 bg-red-500 bg-opacity-10">탈퇴 처리</td>
                                        ) : (
                                            <td className="py-2 px-4 bg-green-500 bg-opacity-10">이용 가능</td>
                                        )}
                                        {item.user.role === 'creator' ? (
                                            <td className="py-2 px-4 bg-green-500 bg-opacity-10">크리에이터</td>
                                        ) : (
                                            <td className="py-2 px-4 bg-red-500 bg-opacity-10">유저</td>
                                        )}
                                    </tr>
                                    {expandedRow === item.user.id && (
                                        <tr className="bg-gray-50">
                                            <td colSpan={7} className="py-6 px-6 text-left">
                                                <div className="flex flex-col gap-2">
                                                    <div className="flex flex-col gap-2">
                                                        <span className="font-bold">고객 정보</span>
                                                        <span>닉네임: {item.user.name} </span>
                                                        <span>이메일: {item.user.email} </span>
                                                        <span>생년월일: {item.user.birth} </span>
                                                        <span>주소: {item.user.address}</span>
                                                        <span>전화번호: {item.user.phone}</span>
                                                    </div>
                                                    <div className="flex flex-col gap-2">
                                                        <span className="font-bold">지갑 정보</span>
                                                        <span>보유 클랩: {item.wallet.klav} </span>
                                                    </div>
                                                    {item.user.role === 'creator' && (
                                                        <div className="flex flex-col gap-2">
                                                            <span className="font-bold">계좌 정보</span>
                                                            <span>계좌번호: {item.creator.accountNumber}</span>
                                                        </div>
                                                    )}
                                                    <div className="flex justify-end gap-2">
                                                        {item.user.isBlocked ? (
                                                            <button
                                                                className="bg-primary-500 hover:bg-primary-600 text-white font-medium rounded-lg px-4 py-2 transition-all duration-150 ease-in-out"
                                                                onClick={handleUnblockClick}
                                                            >
                                                                이용 정지 해제
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="bg-red-500 hover:bg-red-600 text-white font-medium rounded-lg px-4 py-2 transition-all duration-150 ease-in-out"
                                                                onClick={handleBlockClick}
                                                            >
                                                                이용 정지
                                                            </button>

                                                        )}
                                                        <button
                                                            className={`bg-primary-500  text-white font-medium rounded-lg px-4 py-2 transition-all duration-150 ease-in-out ${item.user.isDeleted ? "bg-opacity-50" : "hover:bg-primary-600"}`}
                                                            onClick={handleDeleteClick}
                                                            disabled={item.user.isDeleted}
                                                        >
                                                            {item.user.isDeleted ? "탈퇴 처리 완료" : "탈퇴 처리"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="mt-6 flex justify-center">
                    <ReactPaginate
                        previousLabel={"이전"}
                        nextLabel={"다음"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"flex space-x-2"}
                        activeClassName={"active bg-primary-500 transition-all duration-150 ease-in-out"}
                        previousClassName={"px-3 py-1 border rounded"}
                        nextClassName={"px-3 py-1 border rounded"}
                        pageClassName={"px-3 py-1 border rounded"}
                        activeLinkClassName={"text-white"}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserManageComponent;