import { followUser, getIsFollowing, getUserProfile, unfollowUser } from "apis";
import { PageHeader, PostWrapper, ProfileComponent, UserPostWrapper } from "components";
import { useEffect, useRef, useState } from "react";
import { UserDTO } from "types";
import FollowButtonWithParticles from "./TestButton";
import { useUserStore } from "store";
import { ReactComponent as BlueCheck } from 'assets/images/verified.icon.svg'

interface UserProfileComponentProps {
    profileId: string;
}

const UserProfileComponent = ({ profileId }: UserProfileComponentProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { user: currentUser } = useUserStore();

    const [user, setUser] = useState<UserDTO | null>(null);
    const [isFollowing, setIsFollowing] = useState<boolean>(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
        if (profileId) {
            getUserData();
        }
    }, [profileId]);

    const getUserData = async () => {
        if (profileId) {
            const response = await getUserProfile(profileId);
            setUser(response.data.data);

            const isFollowingResponse = await getIsFollowing(profileId);
            setIsFollowing(isFollowingResponse.data.data);
        }
    }

    const handleClick = async () => {
        if (isButtonDisabled) return;
        setIsButtonDisabled(true);

        playParticles();
        animateButton();

        try {
            if (!isFollowing) {
                const response = await followUser(profileId);
                if (response.data.isSuccess === "T") {
                    setIsFollowing(true);
                }
            } else {
                const response = await unfollowUser(profileId);
                if (response.data.isSuccess === "T") {
                    setIsFollowing(false);
                }
            }
        } catch (e) {
            console.error("팔로우 처리 중 오류", e);
        } finally {
            setTimeout(() => setIsButtonDisabled(false), 1000); // 1초 디바운싱
        }
    };

    const animateButton = () => {
        if (buttonRef.current) {
            buttonRef.current.classList.add('scale-110');
            setTimeout(() => {
                buttonRef.current?.classList.remove('scale-110');
            }, 200);
        }
    };

    const playParticles = () => {
        const particleCount = 15;

        for (let i = 0; i < particleCount; i++) {
            const angle = Math.random() * 2 * Math.PI;
            const baseSpeed = Math.random() * 3 + 1.4;
            const hue = Math.floor(Math.random() * 360); // 모든 색상 범위
            const color = `hsl(${hue}, ${Math.floor(Math.random() * 20 + 60)}%, ${Math.floor(Math.random() * 15 + 75)}%)`;

            const directionX = Math.cos(angle);
            const directionY = Math.sin(angle);

            let distance = 0;
            let frame = 0;
            const maxDistance = 40;
            const step = baseSpeed * 0.6;
            const curveStrength = 0.004;

            const animate = () => {
                const progress = distance / maxDistance;
                if (progress >= 1) return;

                const decelerationFactor = 1 - progress * 0.5; // ✅ 후반부 속도 감소
                distance += step * decelerationFactor;

                const x = directionX * distance;
                const y = directionY * distance + curveStrength * Math.pow(distance, 2);

                // trail dot 생성
                const trail = document.createElement('span');
                const size = 2;
                trail.className = `absolute rounded-full pointer-events-none`;
                trail.style.width = `${size}px`;
                trail.style.height = `${size}px`;
                trail.style.left = '50%';
                trail.style.top = '50%';
                trail.style.backgroundColor = color;
                trail.style.transform = `translate(calc(-50% + ${x}px), calc(-50% + ${y}px))`;
                trail.style.opacity = '1';
                trail.style.transition = 'opacity 0.6s ease-out';

                containerRef.current?.appendChild(trail);

                setTimeout(() => {
                    trail.style.opacity = '0';
                }, 10);
                setTimeout(() => {
                    trail.remove();
                }, 700);

                frame++;
                requestAnimationFrame(animate);
            };

            animate();
        }
    };

    return (
        <div className="flex flex-col">
            <PageHeader title={profileId} />
            <div className="flex flex-col border-b border-achromatic-100">
                <div className="relative p-0">
                    {
                        user?.bannerImage ? (
                            <img
                                src={`${process.env.REACT_APP_API_URL}/${user.bannerImage}`}
                                alt="banner"
                                className="w-full h-[320px] object-cover"
                            />
                        ) : (
                            <div className="flex items-center justify-center text-white font-semibold text-2xl bg-achromatic-100 max-w-[680px] h-[320px]">
                                default image
                            </div>
                        )
                    }
                </div>
                <div className="flex justify-between p-6">
                    <div className="flex gap-3">
                        <div className='relative flex'>
                            {user?.profileImage ? (
                                <img
                                    src={`${process.env.REACT_APP_API_URL}/${user.profileImage}`}
                                    alt="profile"
                                    className="w-16 h-16 object-cover rounded-full"
                                />
                            ) : (
                                <div className="w-16 h-16 bg-achromatic-100 rounded-full" >
                                </div>
                            )}

                        </div>
                        <div className="flex flex-col justify-center">
                            <div className="text-black leading-[17px] font-semibold break-words flex flex-wrap items-center gap-1">
                                <span className="break-words max-w-full">{user?.name}</span>
                                {user?.role === 'creator' && <span className="shrink-0"><BlueCheck /></span>}
                            </div>
                            <div className="text-achromatic-300 leading-[17px]">@{user?.profileId}</div>
                        </div>
                    </div>
                    <div className="flex gap-2 max-h-[34px] text-xs font-semibold">
                        <div className="relative inline-block overflow-visible" ref={containerRef}>
                            {currentUser?.profileId !== profileId && (
                                <button
                                    ref={buttonRef}
                                    disabled={isButtonDisabled}
                                    className={`transition-transform duration-200 ease-in-out
                                        ${isFollowing
                                            ? "text-white bg-primary-500 hover:bg-primary-600"
                                            : "text-primary-500 border-primary-500 border hover:bg-primary-100"
                                        }
                                        ${isButtonDisabled ? 'opacity-60 cursor-not-allowed' : ''}
                                        scale-100 rounded-[4px] px-4 py-1.5 text-xs font-semibold relative z-10`}
                                    onClick={handleClick}
                                >
                                    {isFollowing ? '팔로잉' : '팔로우'}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {user && <UserPostWrapper user={user} />}
        </div>
    );
}

export default UserProfileComponent;