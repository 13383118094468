import { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import Select from 'react-select';

import { AdjustInput, CheckBox, ImageGallery } from 'components';
import { createPost, reAuctionPost, updatePost, uploadPostImages } from 'apis';
import { usePostStore, useAuctionStore, useUserStore, useAlertStore } from 'store';
import { AuctionDTO, PostDTO, ResponseDTO } from 'types';

import { ReactComponent as BadgePicture } from 'assets/images/badge.picture.svg';
import { ReactComponent as BadgeAuction } from 'assets/images/badge.auction.svg';
import { AxiosResponse } from 'axios';

interface SmallButtonProps {
    text: string;
    isSelected?: boolean;
    onClick?: () => void;
    disabled?: boolean;
}

interface PostButtonProps {
    text: string;
    disabled?: boolean;
    onClick?: () => void;
}

const SmallButton = ({ text, isSelected, onClick, disabled }: SmallButtonProps) => {
    return (
        <button
            onClick={!disabled ? onClick : undefined}
            className="flex h-[22px] max-w-[71px] items-center justify-center"
        >
            {text === '사진' &&
                <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={onClick}
                    disabled={disabled}
                />
            }
            <div className={`flex w-full h-full px-[6px] py-1 rounded-[4px] items-center justify-center ${isSelected ? 'bg-[#0066FF] bg-opacity-[8%]' : 'bg-achromatic-50'}`}>
                <div className='flex items-center gap-1'>
                    {text === '사진' && <BadgePicture className={`w-[11px] h-[11px] ${isSelected ? 'fill-[#0066FF]' : 'fill-achromatic-400'}`} />}
                    {text === '물품경매' && <BadgeAuction className={`w-[11px] h-[11px] ${isSelected ? 'fill-[#0066FF]' : 'fill-achromatic-400'}`} />}
                    <span className={`font-semibold text-xs ${isSelected ? 'text-[#0066FF]' : 'text-achromatic-400'}`}>
                        {text}
                    </span>
                </div>
            </div>
        </button>
    );
}

const PostButton = ({ text, disabled, onClick }: PostButtonProps) => {
    return (
        <button
            className={`flex w-[89px] w min-h-[30px] h-full items-center justify-center rounded-[4px] mobile:max-w-[80px] ${disabled ? 'bg-primary-500 opacity-40 cursor-not-allowed' : 'bg-primary-500 opacity-100'
                }`}
            onClick={!disabled ? onClick : undefined} // disabled 상태에서는 onClick 무시
            disabled={disabled} // 버튼 자체를 비활성화
        >
            <span className="font-semibold text-xs text-white">{text}</span>
        </button>
    );
}

const AuctionEditor = forwardRef((_, ref) => {
    const showAlert = useAlertStore((state) => state.showAlert);
    const { postingAuction, setPostingAuction } = useAuctionStore();
    const { uploadedImages } = usePostStore();
    const [title, setTitle] = useState<string>(''); // 경매 제품명 상태
    const [startingPrice, setStartingPrice] = useState<string>('');
    const [immediatePrice, setImmediatePrice] = useState<string>('');
    const [period, setPeriod] = useState<number>(0.5); // 기본값으로 1일 설정
    const [isWarning, setIsWarning] = useState<boolean>(false); // 경고 여부 상태
    const [isStartingPriceError, setIsStartingPriceError] = useState<boolean>(false); // 오류 발생 여부 상태
    const [isImmediatePriceError, setIsImmediatePriceError] = useState<boolean>(false); // 오류 발생 여부 상태
    const [startingPriceErrorMessage, setStartingPriceErrorMessage] = useState<string>(''); // 오류 메시지 상태
    const [immediatePriceErrorMessage, setImmediatePriceErrorMessage] = useState<string>(''); // 오류 메시지 상태
    const startingPriceInputRef = useRef<HTMLInputElement>(null); // 시작가 input의 ref
    const immediatePriceInputRef = useRef<HTMLInputElement>(null); // 즉시 낙찰가 input의 ref

    useImperativeHandle(ref, () => ({
        resetState() {
            setTitle('');
            setStartingPrice('');
            setImmediatePrice('');
            setPeriod(0.5);
            setIsWarning(false);
        },
        setAuction(auction: AuctionDTO) {
            setTitle(auction.title || '');
            setStartingPrice(String(auction.startPrice || ''));
            setImmediatePrice(String(auction.immediatePrice || ''));
            setPeriod(auction.period);
            setIsWarning(auction.isWarning || false);
        },
    }));

    // const auctionPeriodOptions = Array.from({ length: 30 }, (_, i) => ({
    //     value: i + 1,
    //     label: `${i + 1}일`,
    // }));

    // const auctionPeriodOptions = [
    //     { value: 0.5, label: '12시간' }, // 12시간 옵션 추가
    //     ...Array.from({ length: 30 }, (_, i) => ({
    //         value: i + 1,
    //         label: `${i + 1}일`,
    //     })),
    // ];

    const auctionPeriodOptions = [
        { value: 0.5, label: '12시간' },
        { value: 1, label: '1일' },
        { value: 3, label: '3일' },
        { value: 7, label: '7일' },
    ];

    const handleDivClick = (inputType: 'startingPrice' | 'immediatePrice') => {
        if (inputType === 'startingPrice') {
            if (startingPriceInputRef.current) {
                startingPriceInputRef.current.focus();
            }
        } else if (inputType === 'immediatePrice') {
            if (immediatePriceInputRef.current) {
                immediatePriceInputRef.current.focus();
            }
        }
    };

    useEffect(() => {
        if (Number(startingPrice) > 10000000) {
            setIsStartingPriceError(true); // 오류 발생
            setStartingPriceErrorMessage('10000000 넘을 수 없습니다.'); // 오류 메시지 설정
        } else {
            setIsStartingPriceError(false); // 오류 발생하지 않음
            setStartingPriceErrorMessage(''); // 오류 메시지 초기화
        }
    }, [startingPrice]);

    useEffect(() => {
        if (immediatePrice && Number(immediatePrice) < 10) {
            setIsImmediatePriceError(true); // 오류 발생
            setImmediatePriceErrorMessage('최소 10 클랩 이상으로 설정해주세요.'); // 오류 메시지 설정
        } else if (Number(immediatePrice) > 10000000) {
            setIsImmediatePriceError(true); // 오류 발생
            setImmediatePriceErrorMessage('최대 10000000 클랩을 넘을 수 없습니다.'); // 오류 메시지 설정
        } else {
            setIsImmediatePriceError(false); // 오류 발생하지 않음
            setImmediatePriceErrorMessage(''); // 오류 메시지 초기화
        }
    }, [immediatePrice]);

    useEffect(() => {
        const updatedAuction: AuctionDTO = {
            ...postingAuction,
            title,
            startPrice: Number(startingPrice),
            immediatePrice: Number(immediatePrice),
            period,
            isWarning,
            // image: uploadedImages,
        };
        setPostingAuction(updatedAuction);
    }, [title, startingPrice, immediatePrice, period, isWarning]);

    return (
        <div className="flex flex-col p-4 my-8 rounded-2xl border border-achromatic-100 gap-4 text-achromatic-400 font-medium text-sm">
            <div className='flex flex-col gap-2'>
                <span>경매 제품명</span>
                <input
                    className='px-3 min-h-12 border border-achromatic-100 rounded-lg text-black font-semibold focus:outline-none'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                >
                </input>
            </div>
            <div className='flex flex-col gap-2'>
                <span>경매 기간</span>
                <Select
                    className="w-full"
                    classNamePrefix="react-select"
                    options={auctionPeriodOptions}
                    components={{
                        IndicatorSeparator: () => null,
                    }}
                    styles={{
                        control: (styles) => ({
                            ...styles,
                            width: '100%',
                            height: '3rem',
                            border: '1px solid #E5E7EB',
                            borderRadius: '0.5rem',
                            color: '#374151',
                            fontSize: '0.875rem',
                            fontWeight: 600,
                            lineHeight: 1.25,
                            transition: 'all 0.2s',
                            '&:hover': {
                                borderColor: '#9CA3AF',
                            },
                        }),
                    }}
                    onChange={(selectedOption) => setPeriod(selectedOption ? selectedOption.value : 0.5)}
                    defaultValue={auctionPeriodOptions[0]}
                />
            </div>
            <div className='flex flex-col gap-2'>
                <span>시작가</span>
                <div
                    className='flex justify-between items-center border border-achromatic-100 rounded-lg h-12 text-black'
                    onClick={() => handleDivClick('startingPrice')}
                >
                    <div className='w-full flex h-full items-center'>
                        <AdjustInput
                            ref={startingPriceInputRef}
                            inputValue={startingPrice}
                            setInputValue={setStartingPrice}
                            isError={isStartingPriceError}
                        />
                        {startingPrice && (
                            <div className='flex-1 flex'>
                                클랩
                            </div>
                        )}
                    </div>
                </div>
                {/* 오류 메시지 표시 */}
                {startingPriceErrorMessage && <span className='text-red-500 text-xs'>{startingPriceErrorMessage}</span>}
            </div>
            <div className='flex flex-col gap-2'>
                <span>즉시 낙찰가</span>
                <div
                    className='flex justify-between items-center border border-achromatic-100 rounded-lg h-12 text-black'
                    onClick={() => handleDivClick('immediatePrice')}

                >
                    <div className='w-full flex h-full items-center'>
                        <AdjustInput
                            ref={immediatePriceInputRef}
                            inputValue={immediatePrice}
                            setInputValue={setImmediatePrice}
                            isError={isImmediatePriceError}
                        />
                        {immediatePrice && (
                            <div className='flex-1 flex'>
                                클랩
                            </div>
                        )}
                    </div>
                </div>
                {/* 오류 메시지 표시 */}
                {immediatePriceErrorMessage && <span className='text-red-500 text-xs'>{immediatePriceErrorMessage}</span>}
            </div>
            <div className='flex'>
                <div className='flex gap-2'>
                    <input
                        type='checkbox'
                        className='w-[18px] h-[18px] appearance-none rounded-md border-2 border-achromatic-200
                    checked:bg-[#F14831] checked:border-transparent checked:bg-[url("./assets/images/check.checkbox.svg")] bg-no-repeat bg-center bg-contain
                    transition-all duration-200 ease-in-out transform'
                        style={{ backgroundSize: '70%' }}
                        checked={isWarning}
                        onChange={(e) => setIsWarning(e.target.checked)}
                    />
                    <div className='text-xs font-semibold text-achromatic-600 content-center'>
                        사용자의 주의가 필요한 게시물입니다.
                    </div>
                </div>
            </div>
        </div>
    );
});

const PostEditor = ({ onAddLocalPost }: { onAddLocalPost: (post: PostDTO) => void }) => {
    const showAlert = useAlertStore((state) => state.showAlert);
    const { user } = useUserStore();
    const { postList, setPostList, addPostList, isEditing, setIsEditing, isReAuction } = usePostStore();

    const [inputText, setInputText] = useState<string>('');
    const [isInputText, setIsInputText] = useState<boolean>(false);
    const [isAuction, setIsAuction] = useState<boolean>(false);
    const { postingAuction } = useAuctionStore();
    const [hashtags, setHashtags] = useState<string[]>([]);
    const { uploadedImages, addImage, setUploadedImages, editingPost, setEditingPost } = usePostStore();
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const auctionEditorRef = useRef<any>(null);

    const handleFileClick = () => {
        if (!fileInputRef.current) return;
        fileInputRef.current.click();
    }

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const formData = new FormData();
            for (const file of files) {
                formData.append('files', file);
            }

            try {
                // API 호출 부분은 다중 파일 업로드를 지원해야 함
                const response = await uploadPostImages(Array.from(files)); // 다중 파일 업로드를 지원하는 API 호출로 가정
                if (response.data.data) {
                    const imageList = response.data.data;
                    setUploadedImages([...uploadedImages, ...imageList]);
                }
            } catch (error) {
                console.error('File Upload Failed:', error);
            }
        }
    }

    const handleAuctionClick = () => {
        setIsAuction((prev) => !prev);
    }

    const handleInputText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value;
        setInputText(text);
        setIsInputText(e.target.value.trim().length > 0);
        extractHashtags(text);
        autoResizeTextarea();
    }

    const autoResizeTextarea = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }

    const extractHashtags = (text: string) => {
        const hashtagList = text.match(/#[\w가-힣]+/g) || [];
        setHashtags(hashtagList.map((tag) => tag));
    };

    const handlePostButtonClick = async () => {
        const formattedHashtags = hashtags.map(tag => tag.replace('#', '')).join(',');
        try {
            let response;
            if (isAuction) {
                response = await createPost(inputText, formattedHashtags, uploadedImages, postingAuction);
            } else {
                response = await createPost(inputText, formattedHashtags, uploadedImages);
            }
            if (response.status === 201) {
                setInputText('');
                setHashtags([]);
                autoResizeTextarea();
                setUploadedImages([]);
                setIsAuction(false);
                if (auctionEditorRef.current) {
                    auctionEditorRef.current.resetState();
                }
                onAddLocalPost(response.data.data); // 작성된 게시글을 로컬 상태에 추가
                await addPostList(response.data.data);
            }
        } catch (error) {
            console.error('Post Creation Failed:', error);
        }
    }

    const handlePostEditButtonClick = async () => {
        if (!editingPost) return;

        const formattedHashtags = hashtags.map(tag => tag.replace('#', '')).join(',');
        try {
            let response: AxiosResponse<ResponseDTO<PostDTO>>;
            if (isAuction) {
                response = await updatePost(editingPost.id, inputText, formattedHashtags, uploadedImages, postingAuction);
            } else {
                response = await updatePost(editingPost.id, inputText, formattedHashtags, uploadedImages);
            }
            if (response.status === 200) {
                setEditingPost(null);
                setIsEditing(false);
                setInputText('');
                setHashtags([]);
                autoResizeTextarea();
                setUploadedImages([]);
                setIsAuction(false);
                if (auctionEditorRef.current) {
                    auctionEditorRef.current.resetState();
                }
                const updatedPostList = postList.map((post) => {
                    if (post.id === editingPost.id) {
                        return response.data.data;
                    }
                    return post;
                });
                setPostList(updatedPostList);
                window.location.reload();
                showAlert('게시글이 수정되었습니다.');
            }
        } catch (error) {
            console.error('Post Edit Failed:', error);
        }
    }

    const handleReAuctionButtonClick = async () => {
        if (!editingPost) return;

        const formattedHashtags = hashtags.map(tag => tag.replace('#', '')).join(',');
        try {
            let response: AxiosResponse<ResponseDTO<PostDTO>>;
            if (isAuction) {
                response = await reAuctionPost(editingPost.id, inputText, formattedHashtags, uploadedImages, postingAuction);
            } else {
                response = await reAuctionPost(editingPost.id, inputText, formattedHashtags, uploadedImages);
            }
            if (response.status === 200) {
                setEditingPost(null);
                setIsEditing(false);
                setInputText('');
                setHashtags([]);
                autoResizeTextarea();
                setUploadedImages([]);
                setIsAuction(false);
                if (auctionEditorRef.current) {
                    auctionEditorRef.current.resetState();
                }
                const updatedPostList = postList.map((post) => {
                    if (post.id === editingPost.id) {
                        return response.data.data;
                    }
                    return post;
                });
                setPostList(updatedPostList);
            }
        } catch (error) {
            console.error('Post Edit Failed:', error);
        }
    }

    const handlePostEditCancleButtonClick = () => {
        setEditingPost(null);
        setIsEditing(false);
        setInputText('');
        setHashtags([]);
        autoResizeTextarea();
        setUploadedImages([]);
        setIsAuction(false);
        if (auctionEditorRef.current) {
            auctionEditorRef.current.resetState();
        }
    }

    useEffect(() => {
        autoResizeTextarea();
        setIsInputText(inputText.trim().length > 0);
    }, [inputText]);

    useEffect(() => {
        if (editingPost) {
            setInputText(editingPost.content || '');
            setUploadedImages(editingPost.images || []);
            setHashtags(Array.isArray(editingPost.hashtags) ? editingPost.hashtags : []);
            setIsAuction(!!editingPost.auction);

            // 경매 데이터 초기화
            if (editingPost.auction && auctionEditorRef.current) {
                auctionEditorRef.current.resetState();
                auctionEditorRef.current.setAuction(editingPost.auction);
            }
        }
    }, [editingPost]);

    const isCreator = user?.role === 'creator';

    return (
        <div className="w-full max-h-full min-[142px] border-b border-achromatic-100">
            <div className='flex flex-col w-full h-full justify-between py-6'>
                <div className="w-full text-achromatic-800 text-base font-medium">
                    <textarea
                        ref={textareaRef}
                        className="w-full focus:outline-none resize-none overflow-hidden px-6 bg-white"
                        placeholder={isCreator ? "새로운 글을 작성해보세요." : "크리에이터가 되어 경매를 시작해보세요."}
                        value={inputText}
                        onChange={handleInputText}
                        disabled={!isCreator}
                    />
                    <div className="whitespace-pre-wrap mt-2 font-medium text-sm text-primary-500 px-6">
                        {hashtags.map((tag) => `${tag}`).join(' ')}
                    </div>
                    <div className={`px-6 transition-all duration-300 ${isAuction ? 'max-h-[470px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}>
                        <AuctionEditor ref={auctionEditorRef} />
                    </div>
                    {<ImageGallery images={uploadedImages} isPosting={true} />}
                </div>
                <div className="flex justify-between px-6">
                    <div className="flex gap-2 items-end">
                        <input
                            className='hidden'
                            ref={fileInputRef}
                            onChange={handleFileUpload}
                            type="file"
                            disabled={!isCreator}
                            multiple
                        />
                        <SmallButton text="사진" onClick={handleFileClick} disabled={!isCreator} />
                        <SmallButton text="물품경매" onClick={handleAuctionClick} isSelected={isAuction} disabled={!isCreator} />
                    </div>
                    {isEditing ? (
                        <div className='flex gap-2'>
                            <PostButton text="취소" onClick={handlePostEditCancleButtonClick} />
                            <PostButton text="수정완료" disabled={!isInputText || !isCreator} onClick={handlePostEditButtonClick} />
                        </div>
                    ) : isReAuction ? (
                        <PostButton text="재경매" disabled={!isInputText || !isCreator} onClick={handleReAuctionButtonClick} />
                    ) : (
                        <PostButton text="작성완료" disabled={!isInputText || !isCreator} onClick={handlePostButtonClick} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default PostEditor;