import { AuctionItem } from "components";
import { PostDTO } from "types";

interface AuctionOnGoingProps {
  postList: PostDTO[];
}
const OngoingAuctionsComponent = ({ postList }: AuctionOnGoingProps) => {
  return (
    <div className="flex flex-col px-6 gap-2 mobile:px-0">
      {postList.map((post) => (
        <AuctionItem
          key={post.id}
          post={post}
          status={post.auction.auctionStatus}
        />
      ))}
    </div>
  );
};

export default OngoingAuctionsComponent;
