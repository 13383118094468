import { PageHeader, RightSideBar, NotificationComponent } from "components"

export default function Page() {
    return (
        <div className="flex w-full mobile:pb-[56px]">
            <div className="flex max-w-[680px] w-full flex-col border-achromatic-100 border-x">
                <PageHeader title="알림" />
                <NotificationComponent />
            </div>
            <div className="max-w-[360px] w-full mobile:hidden">
                <RightSideBar />
            </div>
        </div>
    )
}