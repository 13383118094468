import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLoginStore, useUserStore } from 'store';
import { LoginComponent, TermsOfServiceComponent, MemberInfoComponent } from 'components';

const LoginBaseComponent = () => {
    const { view, setView } = useLoginStore();
    const { user, setUser } = useUserStore();
    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem('user_access_token');
        const termsAgree = localStorage.getItem('terms_agree');

        if (accessToken && termsAgree === 'true') {
            navigate('/');
        } else {
            navigate('/login');
        }

        if (!accessToken) {
            setView('login');
        } else if (accessToken) {
            setView('terms');
        } else if (termsAgree !== 'true') {
            setView('memberInfo');
        }
    }, [navigate, setView]);

    const renderComponent = () => {
        switch (view) {
            case 'login':
                return <LoginComponent />;
            case 'terms':
                return <TermsOfServiceComponent />;
            case 'memberInfo':
                return <MemberInfoComponent />;
            default:
                return null;
        }
    };

    return (
        <div className="flex flex-col bg-white border border-achromatic-100 rounded-2xl gap-16 mobile:rounded-none mobile:border-0">
            {renderComponent()}
        </div>
    );
}

export default LoginBaseComponent;