import { PaymentIntent } from "@stripe/stripe-js";

import { AxiosResponse } from "axios";
import { CheckoutSessionResponse, ResponseDTO } from "types";
import { post } from "utils";

const baseUrl = `${process.env.REACT_APP_API_URL}/payment`;

export const createPaymentIntent = async (
  amount: number
): Promise<AxiosResponse<ResponseDTO<PaymentIntent>>> => {
  let url = `${baseUrl}/create-payment-intent`;
  const body = {
    amount,
  };

  return post(url, body);
};

export const postCheckoutSession = async (
  amount: number
): Promise<AxiosResponse<CheckoutSessionResponse>> => {
  let url = `${baseUrl}/create-checkout-session`;
  const body = {
    amount,
  };

  return post(url, body);
};
