import { AxiosResponse } from "axios";

import { AuctionDTO, BidStatusDTO, ResponseDTO } from "types";
import { BidStatus } from "types/types";
import { get, post } from "utils";

const baseUrl = `${process.env.REACT_APP_API_URL}/auction`;

export const createAuction = async (
  title: string,
  startPrice: number,
  immediatePrice: number,
  startDate: string,
  period: string,
  image: string
): Promise<AxiosResponse<ResponseDTO<AuctionDTO>>> => {
  let url = `${baseUrl}/create`;
  const body = {
    title,
    startPrice,
    immediatePrice,
    startDate,
    period,
    image,
  };

  return post(url, body);
};

export const getPopularAuctionList = async (): Promise<
  AxiosResponse<ResponseDTO<AuctionDTO[]>>
> => {
  let url = `${baseUrl}/popular`;

  return get(url);
};

export const bidAuction = async (
  auctionId: string,
  bidAmount: number
): Promise<AxiosResponse<ResponseDTO<AuctionDTO>>> => {
  let url = `${baseUrl}/bid?bidAmount=${bidAmount}`;

  const body = {
    id: auctionId,
  };

  return post(url, body);
};

export const purchaseNowAuction = async (
  auctionId: string
): Promise<AxiosResponse<ResponseDTO<AuctionDTO>>> => {
  let url = `${baseUrl}/purchaseNow`;

  const body = {
    id: auctionId,
  };

  return post(url, body);
};

export const cancelBidAuction = async (
  auctionId: string
): Promise<AxiosResponse<ResponseDTO<AuctionDTO>>> => {
  let url = `${baseUrl}/cancel-bid`;

  const body = {
    id: auctionId,
  };

  return post(url, body);
};

export const checkUserBidExistence = async (
  auctionId: string
): Promise<AxiosResponse<ResponseDTO<BidStatusDTO>>> => {
  let url = `${baseUrl}/exist-user-bid?auctionId=${auctionId}`;

  return get(url);
};

export const processWinningBidPayment = async (
  auctionId: string,
  bidAmount: number
): Promise<AxiosResponse<ResponseDTO<AuctionDTO>>> => {
  let url = `${baseUrl}/payment-winning-bid?bidAmount=${bidAmount}`;

  const body = {
    id: auctionId,
  };
  return post(url, body);
};
