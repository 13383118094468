import { useEffect, useState } from "react";

import { AuctionPaymentModal, Button, ConfirmPaymentModal } from "components";
import { AuctionDeliveryStatus, AuctionStatus, BidStatusDTO, PostDTO } from "types";
import { useAuctionStore, usePostStore, useUserStore } from "store";
import { useNavigate } from "react-router-dom";
import { BidStatus } from "types/types";
import { checkUserBidExistence } from "apis";

interface AuctionStatusItemProps {
  status: AuctionStatus;
  isMine: boolean;
  bidExistence?: BidStatusDTO;
}

interface AuctionItemProps {
  post: PostDTO;
  status: AuctionStatus;
}

const getStatusStyles = (status: AuctionStatus) => {
  switch (status) {
    case AuctionStatus.ONGOING:
      return {
        backgroundColor: "bg-primary-500",
        textColor: "text-primary-500",
      };
    case AuctionStatus.BIDDING:
      return {
        backgroundColor: "bg-primary-500",
        textColor: "text-primary-500",
      };
    case AuctionStatus.WINNING:
      return {
        backgroundColor: "bg-primary-500",
        textColor: "text-primary-500",
      };
    case AuctionStatus.FINISHED:
      return { backgroundColor: "bg-[#494949]", textColor: "text-[#494949]" };
    case AuctionStatus.PASSED:
      return { backgroundColor: "bg-[#F04D1A]", textColor: "text-[#F04D1A]" };
    case AuctionStatus.FAILED:
      return { backgroundColor: "bg-[#F04D1A]", textColor: "text-[#F04D1A]" };
    default:
      return {
        backgroundColor: "bg-primary-500",
        textColor: "text-primary-500",
      };
  }
};

const AuctionStatusItem = ({ status, isMine, bidExistence }: AuctionStatusItemProps) => {
  // 조건부 배경색 설정
  const { backgroundColor, textColor } = getStatusStyles(status);
  let finalState = status;
  //경매중인데 내꺼다 그면 경매중 아니면 누군가 입찰한거니까
  if (status === AuctionStatus.ONGOING && !isMine) {
    finalState = AuctionStatus.BIDDING;
  }

  if (bidExistence?.bidStatus !== BidStatus.HIGHEST_BID && !isMine) {
    finalState = AuctionStatus.FAILED;
  }

  return (
    <div
      className={`max-h-[25px] rounded-[4px] px-2 bg-opacity-[8%] mobile:hidden  ${finalState === AuctionStatus.FAILED ? "bg-[#F04D1A]" : backgroundColor}`}
    >
      <span className={`font-semibold text-sm ${finalState === AuctionStatus.FAILED ? "text-[#F04D1A]" : textColor}`}>{finalState}</span>
    </div>
  );
};

const ProgressItem = ({
  currentStep,
}: {
  currentStep: AuctionDeliveryStatus;
}) => {
  const steps = [
    AuctionDeliveryStatus.PAYMENT,
    AuctionDeliveryStatus.CREATOR_DELIVERY,
    AuctionDeliveryStatus.INSPECTION,
    AuctionDeliveryStatus.DELIVERY,
  ];

  return (
    <div className="flex flex-col w-full px-6 mobile:px-0">
      <div className="flex items-center w-full mt-2">
        {steps.map((step, index) => (
          <div
            className={`flex items-center ${index < steps.length - 1 ? "w-full" : ""
              }`}
            key={step}
          >
            <div className="relative flex flex-col items-center">
              <div
                className={`w-[18px] h-[18px] rounded-full ${index <= steps.indexOf(currentStep)
                  ? "bg-primary-500"
                  : "bg-achromatic-100"
                  }`}
              />

              {/* 중간 단계의 span 요소를 동그라미 아래에 위치시킴 */}
              {index > 0 && index < steps.length - 1 && (
                <span
                  className={`absolute top-[30px] left-1/2 transform -translate-x-1/2 text-xs font-semibold text-center inline-block w-40 ${index <= steps.indexOf(currentStep)
                    ? "text-primary-500"
                    : "text-achromatic-900"
                    }`}
                >
                  {step}
                </span>
              )}
            </div>

            {/* ${index < steps.indexOf(currentStep) + 1 */}
            {index < steps.length - 1 && (
              <div
                className={`flex-1 h-[3px] ${index < steps.indexOf(currentStep) + 1
                  ? // 다음 원의 상태에 따라 스타일 결정
                  index === steps.indexOf(currentStep)
                    ? "bg-gradient-to-r from-primary-500 to-achromatic-100" // 다음 원이 회색: 그라데이션
                    : "bg-primary-500" // 다음 원이 초록색: 단색 초록
                  : "bg-achromatic-100" // 기본 회색
                  }`}
              />
            )}
          </div>
        ))}
      </div>
      <div className="flex w-full justify-between pt-3">
        {steps.map((step, index) => (
          <div
            className={`flex w-full ${index < steps.length / 2 ? "justify-start" : "justify-end"
              }`}
            key={index}
          >
            <span
              className={`text-xs font-semibold ${index === 0 || index === steps.length - 1 ? "" : "hidden"
                } ${index <= steps.indexOf(currentStep)
                  ? "text-primary-500"
                  : "text-achromatic-900"
                }`}
            >
              {step}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const AuctionItem = ({ post, status }: AuctionItemProps) => {
  const { user } = useUserStore();
  const navigate = useNavigate();
  const { setPostingAuction } = useAuctionStore();
  const { setEditingPost, setIsReAuction } = usePostStore();
  const [isAuctionPaymentModalOpen, setIsAuctionPaymentModalOpen] =
    useState(false);
  const [isConfirmPaymentModalOpen, setIsConfirmPaymentModalOpen] =
    useState(false);
  const [currentStep, setCurrentStep] = useState(0); // 진행 단계 (0: 결제완료, 1: 배송완료 등)
  const [bidExistence, setBidExistence] = useState<BidStatusDTO>();
  const [overLimit, setOverLimit] = useState(false);

  const handleItemClick = async () => {
    navigate(`/post/${post?.id}`);
  }

  const openAuctionPaymentModal = () => {
    setIsAuctionPaymentModalOpen(true);
  };

  const openConfirmPaymentModal = () => {
    setIsConfirmPaymentModalOpen(true);
  }

  const closeModal = () => {
    setIsAuctionPaymentModalOpen(false);
    setIsConfirmPaymentModalOpen(false);
  };

  const closeConfirmModal = () => {
    setIsConfirmPaymentModalOpen(false);
  }

  const handleAddressInput = () => {
    navigate("/mypage");
  }

  const handleReAuction = () => {
    setEditingPost(post);
    setIsReAuction(true);
    navigate("/");
  }

  const getBidExistence = async () => {
    const response = await checkUserBidExistence(post.auction.id);
    setBidExistence(response.data.data);
  }

  useEffect(() => {
    getBidExistence();
  }, []);

  useEffect(() => {
    if (post.auction.overAt) {
      const overAtDate = new Date(post.auction.overAt);
      const currentDate = new Date();
      const hoursDifference = (currentDate.getTime() - overAtDate.getTime()) / (1000 * 60 * 60);

      if (hoursDifference > 24) {
        setOverLimit(true);
      }
    }
  }, [post.auction.overAt]);

  const isMine = post.postWriter.id === user.id;

  return (
    <div
      className="flex p-4 rounded-2xl border border-achromatic-100 mobile:min-h-[158px] mobile:max-h-[344px] mobile:rounded-none mobile:border-0 mobile:flex-col mobile:gap-[10px] cursor-pointer"
    >
      <div className="flex w-full justify-between gap-6 mobile:max-w-[328px]" onClick={handleItemClick}>
        {post.images.length > 0 ? (
          <img
            alt="post"
            className="min-w-24 min-h-24 max-w-24 max-h-24 object-cover object-center rounded-lg"
            src={`${process.env.REACT_APP_API_URL}/${post.images[0]}`}
          />
        ) : (
          <div className="min-w-24 min-h-24 max-w-24 max-h-24 rounded-lg bg-achromatic-100" />
        )}


        <div className="flex flex-col flex-1 gap-2">
          <div className="flex min-h-[34px] justify-between">
            <div className="flex gap-3 items-center">
              {post.postWriter.profileImage ? (
                <img
                  alt="profile"
                  className="w-8 h-8 rounded-full"
                  src={`${process.env.REACT_APP_API_URL}/${post.postWriter.profileImage}`}
                />
              ) : (
                <div className="w-8 h-8 bg-achromatic-100 rounded-full" />
              )}
              <div className="flex flex-col">
                <div className="text-black leading-[17px] font-semibold">
                  {post.postWriter.name}
                </div>
                <div className="text-achromatic-300 leading-[17px]">
                  @{post.postWriter.profileId}
                </div>
              </div>
            </div>
            <AuctionStatusItem status={status} isMine={isMine} bidExistence={bidExistence} />
          </div>

          <div className="flex min-h-[65px] justify-between gap-2 mobile:flex-col mobile:h-full">
            <div className="w-full h-full">
              <div className="flex flex-col gap-2">
                <div className="w-full font-semibold">{post.auction.title}</div>
                <div className="flex w-full font-semibold text-xs gap-1">
                  <div>{post.auction.currentPrice}클랩</div>
                  <div className="max-h-4 rounded-[4px] bg-primary-600 bg-opacity-[8%] px-1">
                    <span className="font-semibold text-[10px] text-primary-600">
                      최고가
                    </span>
                  </div>
                </div>
                <div className="w-full font-medium text-xs text-achromatic-400">
                  입찰자 {post.auction.numberOfBidders}명 ·{" "}
                  {post.auction.auctionStatus}
                </div>
                {bidExistence?.bidStatus === BidStatus.HIGHEST_BID && status === AuctionStatus.WINNING && !user.address && (
                  <div className="w-full font-medium text-xs text-[#F04D1A]">
                    배송지를 입력해 주세요
                  </div>
                )}
              </div>
            </div>

            {/* TODO isMine일 경우에만 보여주기 로직 필요 */}
            <div
              className="h-full min-w-[122px] max-h-10 mt-auto"
              onClick={(e) => e.stopPropagation()}
            >
              {bidExistence?.bidStatus === BidStatus.HIGHEST_BID &&
                post.auction.auctionDelivery?.deliveryStatus === AuctionDeliveryStatus.PAYMENT_PENDING &&
                status !== AuctionStatus.FINISHED &&
                status !== AuctionStatus.PASSED &&
                (
                  <Button
                    text={
                      overLimit
                        ? `결제 기간 종료`
                        : status === AuctionStatus.WINNING
                          ? user?.address
                            ? `낙찰가 입금하기`
                            : `배송지 입력`
                          : `null`
                    }
                    onClick={user?.address ? openAuctionPaymentModal : handleAddressInput} // 배송지 입력일 때는 handleAddressInput 함수 호출
                    disabled={overLimit}
                  />
                )}
              {status === AuctionStatus.PASSED && isMine && (
                <Button
                  text={"재경매"}
                  onClick={handleReAuction}
                />
              )}
            </div>
          </div>

          <div className="mobile:hidden">
            {status === AuctionStatus.WINNING && ((user.address && bidExistence?.bidStatus === BidStatus.HIGHEST_BID) || isMine) && (
              <ProgressItem
                currentStep={post.auction.auctionDelivery.deliveryStatus}
              />
            )}
          </div>

        </div>

      </div>

      <div className="hidden mobile:flex">
        {status === AuctionStatus.WINNING && ((user.address && bidExistence?.bidStatus === BidStatus.HIGHEST_BID) || isMine) && (
          <ProgressItem
            currentStep={post.auction.auctionDelivery.deliveryStatus}
          />
        )}
      </div>

      <div className="">
        <AuctionPaymentModal
          title="낙찰가 결제하기"
          auctionDTO={post.auction}
          isOpen={isAuctionPaymentModalOpen}
          onClose={closeModal}
          onPayment={openConfirmPaymentModal}
        />
      </div>

      <div className="">
        <ConfirmPaymentModal
          title="낙찰가 결제하기"
          isOpen={isConfirmPaymentModalOpen}
          onClose={closeModal}
          onCancle={closeConfirmModal}
          onConfirm={() => { }}
          auction={post.auction}
          type="bidding"
        />
      </div>
    </div>
  );
};

export default AuctionItem;
