import { AxiosResponse } from "axios";
import { FollowDTO, ResponseDTO, UserDTO } from "types";
import { get, post } from "utils";

const baseUrl = `${process.env.REACT_APP_API_URL}/follow`;

export const followUser = async (
  userId: string
): Promise<AxiosResponse<ResponseDTO<FollowDTO>>> => {
  let url = `${baseUrl}/?userId=${userId}`;

  return post(url);
};

export const unfollowUser = async (
  userId: string
): Promise<AxiosResponse<ResponseDTO<FollowDTO>>> => {
  let url = `${baseUrl}/unfollow/?userId=${userId}`;

  return post(url);
};

export const getFollowingList = async (
  page: number,
  limit: number
): Promise<
  AxiosResponse<
    ResponseDTO<{
      items: (UserDTO & { recentPostId?: string })[];
      total: number;
    }>
  >
> => {
  let url = `${baseUrl}/list?page=${page}&limit=${limit}`;

  return get(url);
};

export const getIsFollowing = async (
  userId: string
): Promise<AxiosResponse<ResponseDTO<boolean>>> => {
  let url = `${baseUrl}/is-following/?userId=${userId}`;

  return post(url);
};
