import { AxiosResponse } from "axios";

import { AuctionDTO, PostDTO, ResponseDTO } from "types";
import { get, patch, post, del } from "utils";

const baseUrl = `${process.env.REACT_APP_API_URL}/post`;

export const createPost = async (
  content: string,
  hashtags: string,
  images?: string[],
  auction?: AuctionDTO
): Promise<AxiosResponse<ResponseDTO<PostDTO>>> => {
  let url = `${baseUrl}/create`;
  const body = {
    content,
    hashtags,
    images,
    auction,
  };

  return post(url, body);
};

export const updatePost = async (
  postId: string,
  content: string,
  hashtags: string,
  images?: string[],
  auction?: AuctionDTO
): Promise<AxiosResponse<ResponseDTO<PostDTO>>> => {
  let url = `${baseUrl}/update`;
  const body = {
    id: postId,
    content,
    hashtags,
    images,
    auction,
  };

  return patch(url, body);
};

export const reAuctionPost = async (
  postId: string,
  content: string,
  hashtags: string,
  images?: string[],
  auction?: AuctionDTO
): Promise<AxiosResponse<ResponseDTO<PostDTO>>> => {
  let url = `${baseUrl}/re-auction`;
  const body = {
    id: postId,
    content,
    hashtags,
    images,
    auction,
  };

  return patch(url, body);
};

export const deletePost = async (
  postId: string
): Promise<AxiosResponse<ResponseDTO<PostDTO>>> => {
  const url = `${baseUrl}/delete?id=${postId}`;

  return del(url);
};

export const getPostDetail = async (
  postId: string
): Promise<AxiosResponse<ResponseDTO<PostDTO>>> => {
  const url = `${baseUrl}/detail?id=${postId}`;

  return get(url);
};

export const getPostList = async (
  page: number,
  size: number
): Promise<AxiosResponse<ResponseDTO<{ items: PostDTO[]; total: number }>>> => {
  const url = `${baseUrl}/list?page=${page}&limit=${size}`;

  return get(url);
};

export const getPostListByUser = async (
  page: number,
  size: number,
  userId: string
): Promise<AxiosResponse<ResponseDTO<{ items: PostDTO[]; total: number }>>> => {
  const url = `${baseUrl}/list/${userId}?page=${page}&limit=${size}`;

  return get(url);
};

export const getPopularPostList = async (): Promise<
  AxiosResponse<ResponseDTO<PostDTO[]>>
> => {
  const url = `${baseUrl}/popular`;

  return get(url);
};

export const uploadPostImages = async (
  files: File[]
): Promise<AxiosResponse<ResponseDTO<string[]>>> => {
  let url = `${baseUrl}/upload-image`;
  const formData = new FormData();

  files.forEach((file) => {
    formData.append("files", file);
  });

  return post(url, formData);
};

export const patchPostLike = async (
  id: string
): Promise<AxiosResponse<ResponseDTO<PostDTO>>> => {
  let url = `${baseUrl}/like`;
  const body = {
    id,
  };

  return patch(url, body);
};

export const getPostLike = async (
  id: string
): Promise<AxiosResponse<ResponseDTO<boolean>>> => {
  let url = `${baseUrl}/getLike`;
  const body = {
    id,
  };

  return post(url, body);
};

export const getPostLikeCount = async (
  id: string
): Promise<AxiosResponse<ResponseDTO<number>>> => {
  let url = `${baseUrl}/like-count?id=${id}`;

  return get(url);
};

export const getAuctionListByUserId = async (): Promise<
  AxiosResponse<ResponseDTO<PostDTO[]>>
> => {
  let url = `${baseUrl}/my-auction`;

  return get(url);
};

export const reportPost = async (
  reportedPost: PostDTO,
  illegalChecked: boolean,
  inappropriateChecked: boolean,
  fraudChecked: boolean,
  copyrightChecked: boolean,
  spamChecked: boolean
): Promise<AxiosResponse<ResponseDTO<PostDTO>>> => {
  let url = `${baseUrl}/report`;
  const body = {
    reportedPost,
    illegalChecked,
    inappropriateChecked,
    fraudChecked,
    copyrightChecked,
    spamChecked,
  };

  return post(url, body);
};
