const AccountBlock = () => {
    return (
        <div className="account-blocked flex flex-col bg-white border border-achromatic-100 rounded-2xl gap-16 mobile:rounded-none mobile:border-0">
            <div className="flex flex-col gap-6 items-center text-center p-16 mobile:p-6">
                <h1>활동이 정지된 계정입니다.</h1>
                <p>자세한 사항은 고객센터로 문의해주세요.</p>
            </div>
        </div>
    );
}

export default AccountBlock;