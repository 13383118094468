import { BaseModal, Button } from "components";

interface AuctionNoticeModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  onAgree?: () => void;
}

const AuctionNoticeModal = ({
  isOpen,
  onClose,
  title,
  onAgree,
}: AuctionNoticeModalProps) => {
  return (
    <BaseModal title={title} isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-[60px] w-[560px] mobile:w-[292px] mobile:gap-4">
        <div className="flex flex-col pt-8 gap-8 mobile:gap-4">
          <div className="flex flex-col mobile:gap-4">
            <div className="flex flex-col py-4 px-6 gap-2 mobile:p-0">
              <span className="font-semibold text-base text-achromatic-800 leading-[19px]">
                1. 경매 기간
              </span>
              <span className="font-medium text-sm text-achromatic-500 leading-[17px]">
                경매는 크리에이터가 정한 기간 동안 진행되며, 종료 1분 전 입찰 시
                종료 시간이 자동 1분 연장됩니다.
              </span>
            </div>
            <div className="flex flex-col py-4 px-6 gap-2 mobile:p-0">
              <span className="font-semibold text-base text-achromatic-800 leading-[19px]">
                2. 입찰 및 입찰 취소
              </span>
              <span className="font-medium text-sm text-achromatic-500 leading-[17px]">
                현재가 이상으로만 입찰이 가능합니다. 입찰 시 해당 경매에 책정된 보증금이
                지갑에서 차감되며 입찰을 취소하거나 낙찰되지 않으면 보증금은 환급됩니다. 입찰
                후 1시간 내에는 취소가 가능하지만 경매 종료까지 1시간 미만인 경우에는 취소가
                불가합니다.
              </span>
            </div>
            <div className="flex flex-col py-4 px-6 gap-2 mobile:p-0">
              <span className="font-semibold text-base text-achromatic-800 leading-[19px]">
                3. 낙찰대금 결제
              </span>
              <span className="font-medium text-sm text-achromatic-500 leading-[17px]">
                낙찰이 되면, 낙찰가에서 보증금을 제외한 잔여 금액과 배송비(3 클랩)를 더하여
                안내되는 낙찰대금을 24시간 이내에 결제해주세요. 클랩은 경매&지갑에서 충전
                가능합니다.
              </span>
            </div>
            <div className="flex flex-col py-4 px-6 gap-2 mobile:p-0">
              <span className="font-semibold text-base text-achromatic-800 leading-[19px]">
                4. 배송
              </span>
              <span className="font-medium text-sm text-achromatic-500 leading-[17px]">
                크리에이터의 경매 물품은 클래빗의 검수를 거쳐 낙찰자에게 배송됩니다. 검수는 외관상 확인에 한하며, 물품의 품질, 기능, 진품 여부 등에 대한 보증은 하지 않습니다.
              </span>
            </div>
          </div>
          <div className="flex flex-col bg-achromatic-50 rounded-xl py-4 px-6 gap-2">
            <span className="font-semibold text-base text-achromatic-600">
              [패넡티 규정]
            </span>
            <span className="font-medium text-sm text-achromatic-500">
              낙찰 후 24시간 내에 결제가 완료되지 않으면 일정 금액을 제외한 보증금이
              환급됩니다.
            </span>
          </div>
        </div>
        <div className="">
          <Button text="동의 후 경매 참여하기" onClick={onAgree} />
        </div>
      </div>
    </BaseModal>
  );
};

export default AuctionNoticeModal;
