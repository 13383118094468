import { useEffect, useState } from "react";

import { PageHeader, ProfileComponent } from "components";
import { useAlertStore } from "store";

interface SaveButtonProps {
    text: string;
    onClick: () => void;
}

const SaveButton = ({ text, onClick }: SaveButtonProps) => {
    return (
        <button
            className={`flex max-w-[72px] min-h-[34px] w-full h-full items-center justify-center rounded-[4px] bg-primary-500`}
            onClick={onClick}
        >
            <span className="font-semibold text-sm text-white">{text}</span>
        </button>
    );
}

const EditProfileComponent = () => {
    const showAlert = useAlertStore((state) => state.showAlert);
    const [saveState, setSaveState] = useState(false);

    const handleSaveClick = () => {
        setSaveState(true);
    }

    const handleSaveFailure = () => {
        setSaveState(false);
    };

    return (
        <div className="flex flex-col">
            <div className="flex justify-between h-[76px] border-b border-achromatic-100">
                <div className="w-full h-full">
                    <PageHeader title="프로필 수정" isArrow={true} />
                </div>
                <div className="flex flex-col justify-center px-6">
                    <div className="flex w-[72px] h-[34px]">
                        <SaveButton text="저장" onClick={handleSaveClick} />
                    </div>
                </div>
            </div>
            <ProfileComponent isEditProfile={true} onSave={saveState} onSaveFailure={handleSaveFailure} />
        </div>
    )
}

export default EditProfileComponent;