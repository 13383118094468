import { getDashboardData } from "apis";
import { useState } from "react";
import { useQuery } from "react-query";
import { DashboardDTO } from "types";

const DashboardComponent = () => {
    const { data, isLoading, isError } = useQuery(
        ["dashboard"],
        () => getDashboardData(),
    )

    return (
        <div className="h-full flex flex-col gap-6 py-6 px-6">
            <div className="text-2xl font-bold">대시보드</div>
            <div className="grid grid-cols-4 gap-6">
                <div className="flex flex-col bg-white rounded-md h-[300px] shadow">
                    <div className="flex p-4 text-lg font-medium">현재 가입자 수</div>
                    <div className="flex h-full">
                        <div className="flex w-full justify-center items-center h-full text-5xl font-bold">
                            <span>
                                {data?.data.data.userCount}
                                <span className="text-lg font-bold">명</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col bg-white rounded-md h-[300px] shadow">
                    <div className="flex p-4 text-lg font-medium">현재 크리에이터 수</div>
                    <div className="flex h-full">
                        <div className="flex w-full justify-center items-center h-full text-5xl font-bold">
                            <span>
                                {data?.data.data.creatorCount}
                                <span className="text-lg font-bold">명</span>
                            </span>

                        </div>
                    </div>
                </div>
                <div className="flex flex-col bg-white rounded-md h-[300px] shadow">
                    <div className="flex p-4 text-lg font-medium">신규 크리에이터 전황 신청 수</div>
                    <div className="flex h-full">
                        <div className="flex w-full justify-center items-center h-full text-5xl font-bold">
                            <span>
                                {data?.data.data.creatorRequestCount}
                                <span className="text-lg font-bold">건</span>
                            </span>

                        </div>
                    </div>
                </div>
                <div className="flex flex-col bg-white rounded-md h-[300px] shadow">
                    <div className="flex p-4 text-lg font-medium">진행 중인 경매 수</div>
                    <div className="flex h-full">
                        <div className="flex w-full justify-center items-center h-full text-5xl font-bold">
                            <span>
                                {data?.data.data.processingAuctionCount}
                                <span className="text-lg font-bold">건</span>
                            </span>

                        </div>
                    </div>
                </div>
                <div className="flex flex-col bg-white rounded-md h-[300px] shadow">
                    <div className="flex p-4 text-lg font-medium">응답 대기 문의 수</div>
                    <div className="flex h-full">
                        <div className="flex w-full justify-center items-center h-full text-5xl font-bold">
                            <span className="">
                                {data?.data.data.faqCount}
                                <span className="text-lg font-bold">건</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col bg-white rounded-md h-[300px] shadow">
                    <div className="flex p-4 text-lg font-medium">출금 대기 요청 수</div>
                    <div className="flex h-full">
                        <div className="flex w-full justify-center items-center h-full text-5xl font-bold">
                            <span className="">
                                {data?.data.data.withdrawRequestCount}
                                <span className="text-lg font-bold">건</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default DashboardComponent;