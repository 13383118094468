import { getReportPostList } from "apis";
import { deleteReportPost } from "apis/admin.api";
import { CheckBox, Post } from "components";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import { PostReportDTO } from "types";

const ReportManageComponent = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const itemsPerPage = 10;

    const { data, isLoading, isError } = useQuery(["reportList", currentPage, itemsPerPage], () => getReportPostList(currentPage, itemsPerPage), {
        keepPreviousData: true,
    });

    if (isLoading) return <div>로딩 중...</div>;
    if (isError) return <div>오류가 발생했습니다.</div>;

    const reportList = data?.data.data.items || [];
    const totalItems = data?.data.data.total || 0;
    const pageCount = Math.ceil(totalItems / itemsPerPage);

    const handlePageClick = ({ selected }: { selected: number }) => {
        setCurrentPage(selected + 1);
    };

    const handleDeleteClick = async () => {
        try {
            const response = await deleteReportPost(expandedRow!);
            if (response.status === 200) {
                alert("게시글이 삭제되었습니다.");
                setCurrentPage(1);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const toggleRow = (id: string) => {
        setExpandedRow(expandedRow === id ? null : id);
    };

    return (
        <div className="h-full flex flex-col gap-6 py-6 px-6">
            <div className="text-2xl font-bold">신고 내역 조회</div>

            <div className="flex items-center bg-white rounded-md h-[100px] shadow">
                <div className="flex px-6 text-lg font-medium">총 {totalItems}건</div>
            </div>

            <div className="bg-white rounded-md shadow flex flex-col px-6 py-8">
                <div className="overflow-x-auto">
                    <table className="w-full text-center border-collapse">
                        <thead>
                            <tr className="border-b">
                                <th className="py-2 px-4">번호</th>
                                <th className="py-2 px-4">신고자</th>
                                <th className="py-2 px-4">신고 게시글</th>
                                <th className="py-2 px-4">신고 날짜</th>
                                <th className="py-2 px-4">처리 여부</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportList.map((item, index) => (
                                <>
                                    <tr
                                        key={item.id}
                                        className={`border-b cursor-pointer hover:bg-gray-100 ${expandedRow === item.id ? "bg-gray-50" : ""}`}
                                        onClick={() => toggleRow(item.id)}
                                    >
                                        <td className="py-2 px-4">{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                        <td className="py-2 px-4">{item.reporter.name}({item.reporter.profileId})</td>
                                        <td className="py-2 px-4">{item.reportedPost.content}</td>
                                        <td className="py-2 px-4">
                                            {new Date(item.createdAt!).toLocaleDateString('ko-KR', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                        </td>
                                        {item.reportedPost.isDeleted === 1 ? (
                                            <td className="py-2 px-4 bg-gray-500 bg-opacity-10">삭제 처리 완료</td>
                                        ) : (
                                            <td className="py-2 px-4 bg-red-500 bg-opacity-10">대기</td>
                                        )}
                                    </tr>
                                    {expandedRow === item.id && (
                                        <tr className="bg-gray-50">
                                            <td colSpan={5} className="py-6 px-6 text-left">
                                                <div className="flex flex-col gap-2">
                                                    <div className="flex flex-col gap-2">
                                                        <span className="font-bold">신고 게시글</span>
                                                        <span>{item.reportedPost.id}</span>
                                                        <div className="flex gap-2">
                                                            <div className="w-[680px]">
                                                                <Post
                                                                    post={item.reportedPost}
                                                                />
                                                            </div>
                                                            <div className="flex flex-col gap-2">
                                                                <div className="font-bold">신고 내용</div>
                                                                {item.illegalChecked}
                                                                {item.inappropriateChecked}
                                                                {item.fraudChecked}
                                                                {item.copyrightChecked}
                                                                {item.spamChecked}
                                                                <CheckBox
                                                                    label="불법 콘텐츠"
                                                                    checked={item.illegalChecked}
                                                                    onChange={() => { }}
                                                                />
                                                                <CheckBox
                                                                    label="부적절한 콘텐츠"
                                                                    checked={item.inappropriateChecked}
                                                                    onChange={() => { }}
                                                                />
                                                                <CheckBox
                                                                    label="사기 의심"
                                                                    checked={item.fraudChecked}
                                                                    onChange={() => { }}
                                                                />
                                                                <CheckBox
                                                                    label="저작권 침해"
                                                                    checked={item.copyrightChecked}
                                                                    onChange={() => { }}
                                                                />
                                                                <CheckBox
                                                                    label="스팸"
                                                                    checked={item.spamChecked}
                                                                    onChange={() => { }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-end">
                                                        {item.reportedPost.isDeleted === 1 ? (
                                                            <span className="text-red-500">삭제된 처리 완료된 게시글입니다.</span>
                                                        ) : (

                                                            <button
                                                                className="bg-red-500 hover:bg-red-600 text-white font-medium rounded-lg px-4 py-2 transition-all duration-150 ease-in-out"
                                                                onClick={handleDeleteClick}
                                                            >
                                                                게시글 삭제
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="mt-6 flex justify-center">
                    <ReactPaginate
                        previousLabel={"이전"}
                        nextLabel={"다음"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"flex space-x-2"}
                        activeClassName={"active bg-primary-500 transition-all duration-150 ease-in-out"}
                        previousClassName={"px-3 py-1 border rounded"}
                        nextClassName={"px-3 py-1 border rounded"}
                        pageClassName={"px-3 py-1 border rounded"}
                        activeLinkClassName={"text-white"}
                    />
                </div>
            </div>
        </div>
    );
}

export default ReportManageComponent;