import { adminLogin } from 'apis';
import { ReactComponent as FullLogo } from 'assets/images/full.logo.svg';
import { useState } from 'react';

const AdminLoginComponent = () => {
    const [password, setPassword] = useState<string>('');

    const handleLogin = () => {
        const response = adminLogin(password);
    }

    return (
        <div className="w-full h-screen items-center">
            <div className="flex w-full h-full items-center justify-center">
                <div className="flex flex-col bg-white border border-achromatic-100 rounded-2xl gap-16">
                    <div className="flex flex-col gap-6 items-center text-center p-16">
                        <div className="flex flex-col gap-6 items-center text-center">
                            <FullLogo />
                        </div>
                        <div className="flex flex-col gap-4">
                            <input
                                type='password'
                                className='px-3 min-h-12 border border-achromatic-100 rounded-lg text-black font-semibold focus:outline-none'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <button
                                className="flex items-center justify-center w-full h-12 bg-primary-500 rounded-lg gap-[6px]"
                                onClick={handleLogin}
                            >
                                <span className="text-white font-medium">로그인</span>
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminLoginComponent;