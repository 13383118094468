import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || ""; // 환경변수 적용

const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (TRACKING_ID) {
      ReactGA.initialize(TRACKING_ID); // GA4 초기화
    }
  }, []);

  useEffect(() => {
    if (TRACKING_ID) {
      ReactGA.send("page_view"); // 페이지 뷰 이벤트 전송
    }
  }, [location]);
};

export default useGoogleAnalytics;
