import React, { useEffect, useState } from "react";

import { BaseModal, Button } from "components";

import { ReactComponent as CoinIcon } from "assets/images/coin.svg";
import { AuctionDTO, AuctionStatus, ERROR } from "types";
import { updateWallet, bidAuction, getMyWallet } from "apis";
import { useQuery } from "react-query";
import { useAlertStore, useWalletStore } from "store";

interface AuctionBidReviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  auction: AuctionDTO;
  bidPrice: string;
  onEdit: () => void;
  isEditMode?: boolean;
}

const AuctionBidReviewModal = ({
  isOpen,
  onClose,
  title,
  auction,
  bidPrice,
  onEdit,
  isEditMode,
}: AuctionBidReviewModalProps) => {
  const showAlert = useAlertStore((state) => state.showAlert);
  const { wallet, setWallet } = useWalletStore();
  const [remainingTime, setRemainingTime] = useState<{ days: number; hours: number; minutes: number }>({ days: 0, hours: 0, minutes: 0 });

  const { refetch } = useQuery("wallet", getMyWallet, {
    enabled: false,
    onSuccess: (data) => {
      setWallet(data.data.data);
    },
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const createdAt = new Date(auction.createdAt!);
      const periodDays = auction.period;
      const endDate = new Date(createdAt);
      endDate.setDate(createdAt.getDate() + Math.floor(periodDays));
      endDate.setHours(createdAt.getHours() + Math.floor((periodDays % 1) * 24));

      const now = new Date();
      const timeDifference = endDate.getTime() - now.getTime();

      let remainingDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      let remainingHours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let remainingMinutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

      if (timeDifference <= 0) {
        if (auction.auctionStatus === AuctionStatus.ONGOING) {
          remainingDays = 0;
          remainingHours = 0;
          remainingMinutes = 1;
        } else {
          remainingDays = 0;
          remainingHours = 0;
          remainingMinutes = 0;
        }
      }

      setRemainingTime({
        days: remainingDays,
        hours: remainingHours,
        minutes: remainingMinutes,
      });
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 60000); // update every 60 seconds

    return () => clearInterval(timer);
  }, [auction.createdAt, auction.period, auction.auctionStatus]);

  const calculateFees = (price: number) => {
    if (price >= 10 && price <= 49) {
      return { deposit: 5, creator: 2, company: 1, winner: 2 };
    } else if (price >= 50 && price <= 99) {
      return { deposit: 10, creator: 3, company: 3, winner: 4 };
    } else if (price >= 100 && price <= 499) {
      return { deposit: 20, creator: 6, company: 6, winner: 8 };
    } else if (price >= 500) {
      return { deposit: 50, creator: 15, company: 15, winner: 20 };
    } else {
      throw new Error("최소 즉시구매가는 10 klav 이상이어야 합니다.");
    }
  }

  const handleSubmitBid = () => {
    bidAuction(auction.id, Number(bidPrice))
      .then((response) => {
        if (!isEditMode) {
          updateWallet(
            calculateFees(Number(auction.immediatePrice)).deposit,
            "deposit"
          )
            .then((response) => {
              showAlert("입찰에 성공하였습니다", "success");
              refetch();
              onClose();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showAlert("입찰에 성공하였습니다", "success");
          refetch();
          onClose();
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.message === ERROR.AUCTION_CLOSED) {
          showAlert("경매가 종료되었습니다.", "error");
          onClose();
        }
      });
  };

  return (
    <BaseModal title={title} isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-[60px] w-[560px] mobile:w-[292px]">
        <div className="flex flex-col text-sm font-semibold pt-8 gap-8">
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              시작가
            </span>
            <span>{auction.startPrice}클랩</span>
          </div>
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              최고가
            </span>
            <span>
              {auction.currentPrice}클랩 · 입찰자 {auction.numberOfBidders}명
            </span>
          </div>
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              남은 시간
            </span>
            {(remainingTime.days === 0 && remainingTime.hours === 0 && remainingTime.minutes <= 1) ? (
              <span className="text-[#F04D1A] font-semibold text-sm">
                1분 이내 종료
              </span>
            ) : (
              <span>
                {remainingTime.days}일 {remainingTime.hours}시간 {remainingTime.minutes}분 후
                종료
              </span>
            )}
          </div>
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              보증금
            </span>
            <span>{calculateFees(auction.immediatePrice).deposit}클랩</span>
          </div>
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              나의 입찰가
            </span>
            <span>{bidPrice}클랩</span>
          </div>
          <div className="flex gap-4">
            <span className="font-normal text-xs text-achromatic-950">
              입찰을 확인하면, 낙찰자가 될 경우 이 상품을 구매하기로 약정하는
              것입니다. 보증금 관련 내용 약관 및 조건을 읽었으며 이에 동의한다는
              것을 의미합니다.{" "}
            </span>
          </div>

          <div className="flex justify-end gap-2 mobile:justify-between">
            <div className="w-full max-w-[120px] mobile:max-w-full">
              <Button text="입찰 편집" onClick={onEdit} />
            </div>
            <div className="w-full max-w-[120px] mobile:max-w-full">
              <Button text="입찰 확인" onClick={handleSubmitBid}></Button>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default AuctionBidReviewModal;
