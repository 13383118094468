import { AxiosRequestConfig } from "axios";

import { post } from "utils";

const baseUrl = `${process.env.REACT_APP_API_URL}/auth`;
// 로그아웃 함수 정의
// async function logout(config?: AxiosRequestConfig) {
//   try {
//     // 로그아웃 API 호출
//     await axiosService.getAxiosInstance().post("/user/logout", {}, config);

//     // 로컬 스토리지에서 토큰 제거
//     localStorage.removeItem("access_token");

//     // 로그아웃 후 로그인 페이지로 리디렉션
//     window.location.href = "/login";
//   } catch (error) {
//     console.error("Logout failed:", error);
//     // 로그아웃 실패 시 추가 오류 처리 가능
//   }
// }
// export { logout };

interface AdminLoginResponse {
  data: {
    redirectUrl?: string;
  };
}

export const adminLogin = async (email: string) => {
  try {
    const response: AdminLoginResponse = await post(baseUrl + "/admin/login", {
      email,
    });
    if (response?.data?.redirectUrl) {
      window.location.href = response.data.redirectUrl;
    }
  } catch (error) {
    console.error("Admin login failed:", error);
  }
};

export const logout = async (config?: AxiosRequestConfig) => {
  try {
    await post(baseUrl + "/user/logout", {}, config);
    localStorage.removeItem("user_access_token");
    localStorage.removeItem("terms_agree");

    window.location.href = "/login";
  } catch (error) {
    console.error("Logout failed:", error);
  }
};
