import { getNotifications, markAsReadAllNotifications } from 'apis'
import { NotificationIcons } from 'data'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { NotificationDTO, NotificationType } from 'types'

interface NotificationProps {
    notification: NotificationDTO
}

const getDate = (date: string | number | Date) => {
    const today = new Date();
    const postDateTime = new Date(date);

    const diffMilliseconds = today.getTime() - postDateTime.getTime();
    const diffMinutes = Math.floor(diffMilliseconds / (1000 * 60));
    const diffHours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
    const diffDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));

    // 1시간 미만일 경우: "N분 전"
    if (diffMinutes < 60) {
        return `${diffMinutes}분 전`;
    }

    // 24시간 미만일 경우: "N시간 전"
    if (diffHours < 24) {
        return `${diffHours}시간 전`;
    }

    // 하루 이상 지났을 경우: "N일 전"
    if (diffDays >= 1) {
        return `${diffDays}일 전`;
    }

    // 오늘 날짜일 경우: "HH:MM" 형식으로 표시
    if (
        postDateTime.getDate() === today.getDate() &&
        postDateTime.getMonth() === today.getMonth() &&
        postDateTime.getFullYear() === today.getFullYear()
    ) {
        const hour = postDateTime.getHours().toString().padStart(2, "0");
        const minute = postDateTime.getMinutes().toString().padStart(2, "0");
        return `${hour}:${minute}`;
    } else {
        // 오늘이 아니면 "MM.DD" 형식으로 표시
        const month = (postDateTime.getMonth() + 1).toString().padStart(2, "0");
        const day = postDateTime.getDate().toString().padStart(2, "0");
        return `${month}.${day}`;
    }
};


const Notification = ({ notification }: NotificationProps) => {
    const IconComponent = NotificationIcons[NotificationType[notification.type]];

    return (
        <div className="flex p-6 hover:bg-achromatic-50">
            <div className='flex gap-3 justify-between'>
                <div className=''>
                    {IconComponent && <IconComponent className="w-[38px] h-[38px] bg-achromatic-100 rounded-full" />}
                </div>
                <div className='w-full flex flex-col text-sm font-medium'>
                    <span className='font-semibold'>{notification.title}</span>
                    <span className=''>{notification.content}</span>
                    <span className='text-achromatic-500 text-xs'>{notification.createdAt ? getDate(notification.createdAt) : '날짜 없음'}</span>
                </div>
            </div>
        </div>
    )
}

const NotificationComponent = () => {
    const [notificationList, setNotificationList] = useState<NotificationDTO[]>([])

    const { data, isLoading, isError } = useQuery('notificationList', getNotifications, {
        onSuccess: (data) => {
            setNotificationList(data.data.data)
        },
    });

    const { mutate } = useMutation(markAsReadAllNotifications);

    useEffect(() => {
        mutate();
    }, []);

    if (isLoading) {
        return (
            <div className="flex p-6 font-semibold text-sm text-achromatic-500">
                알림을 불러오는 중입니다...
            </div>
        );
    }

    return (
        <div className="flex flex-col">
            {notificationList ? (
                <div className="flex flex-col gap-6">
                    {notificationList.map((notification) => (
                        <Notification key={notification.id} notification={notification} />
                    ))}
                </div>
            ) : (
                <div className="flex p-6 font-semibold text-sm text-achromatic-500">
                    알림이 없습니다.
                </div>
            )}
        </div>
    )
}

export default NotificationComponent