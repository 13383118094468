interface ButtonProps {
    text: string;
    disabled?: boolean;
    onClick?: () => void;
}

const Button = ({ text, disabled, onClick }: ButtonProps) => {
    const handleClick = () => {
        if (!disabled && onClick) {
            onClick();
        }
    };

    return (
        <button
            className="flex w-full h-full items-center justify-center"
            onClick={handleClick}
            disabled={disabled}
        >
            <div className={`flex w-full h-full rounded-lg items-center justify-center transition duration-150 ease-in-out ${!disabled ? 'bg-primary-500 hover:bg-primary-600' : 'bg-achromatic-100'}`}>
                <span className={`py-4 font-semibold text-sm ${!disabled ? 'text-white' : 'text-achromatic-300'}`}>
                    {text}
                </span>
            </div>
        </button>
    )
}

export default Button;