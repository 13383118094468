import { Outlet, useLocation } from "react-router-dom";

import { SettingSideBar } from "components";

export default function Page() {
    const location = useLocation();
    // 현재 경로가 정확히 "/setting"인 경우만 사이드바를 보여주고 그 외의 하위 경로에서는 숨김
    const showSideBar = location.pathname === "/setting";


    return (
        <div className="setting flex w-full mobile:pb-[56px]">
            <div className="flex max-w-[392px] w-full flex-col border-achromatic-100 border-x mobile:hidden">
                <SettingSideBar />
            </div>
            <div className="flex w-full mobile:hidden">
                {/* children 영역에 해당하는 Outlet을 사용하여 경로에 따른 컴포넌트를 표시 */}
                <Outlet />
            </div>

            <div className="w-full hidden mobile:flex">
                <div className="flex max-w-[392px] w-full flex-col border-achromatic-100 border-x">
                    {showSideBar && (
                        <SettingSideBar />
                    )}
                    {/* 모바일 전용 Outlet */}
                    <Outlet />
                </div>
            </div>
        </div>
    );
}