import { BaseModal, Button } from "components";

import { ReactComponent as CoinIcon } from "assets/images/coin.svg";
import { AuctionDTO } from "types";
import { useAlertStore, useWalletStore } from "store";
import { useQuery } from "react-query";
import { getMyWallet, processWinningBidPayment } from "apis";
import { useNavigate } from "react-router-dom";

interface AuctionPaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  auctionDTO: AuctionDTO;
  onPayment: () => void;
}

const AuctionPaymentModal = ({
  isOpen,
  onClose,
  title,
  auctionDTO,
  onPayment
}: AuctionPaymentModalProps) => {
  const nagivate = useNavigate();
  const { wallet, setWallet } = useWalletStore();
  const showAlert = useAlertStore((state) => state.showAlert);

  const { refetch } = useQuery("wallet", getMyWallet, {
    enabled: false,
    onSuccess: (data) => {
      setWallet(data.data.data);
    },
  });

  const isPaymentAbled =
    wallet.klav >= auctionDTO.currentPrice - auctionDTO.startPrice + 3;

  const calculateFees = (price: number) => {
    if (price >= 10 && price <= 49) {
      return { deposit: 5, creator: 2, company: 1, winner: 2 };
    } else if (price >= 50 && price <= 99) {
      return { deposit: 10, creator: 3, company: 3, winner: 4 };
    } else if (price >= 100 && price <= 499) {
      return { deposit: 20, creator: 6, company: 6, winner: 8 };
    } else if (price >= 500) {
      return { deposit: 50, creator: 15, company: 15, winner: 20 };
    } else {
      throw new Error("최소 즉시구매가는 10 klav 이상이어야 합니다.");
    }
  }

  const handlePaymentBid = () => {
    if (isPaymentAbled) {
      processWinningBidPayment(
        auctionDTO.id,
        auctionDTO.currentPrice - auctionDTO.startPrice + 3
      )
        .then((response) => {
          showAlert('결제가 완료되었습니다.', 'success')
          refetch();
          window.location.reload();
          onClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      showAlert('보유금액이 부족합니다.', 'error')
    }
  };

  const handeChargeClick = () => {
    nagivate("/auction");
  }

  return (
    <BaseModal title={title} isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-[60px] w-[560px] mobile:w-[292px]">
        <div className="flex flex-col text-sm font-semibold pt-8 gap-8">
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              낙찰금액
            </span>
            <span>{auctionDTO.currentPrice}클랩</span>
          </div>
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              보증금
            </span>
            <span>-{calculateFees(auctionDTO.immediatePrice).deposit}클랩</span>
          </div>
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              배송비
            </span>
            <span>3 클랩</span>
          </div>
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              추가결제
            </span>
            <span className="text-primary-500">
              {auctionDTO.currentPrice - calculateFees(auctionDTO.immediatePrice).deposit + 3}클랩
            </span>
          </div>

          <div className="border-b border-achromatic-100" />

          <div className="flex flex-col gap-2">
            <span className="w-[120px] font-medium text-sm text-achromatic-400">
              사용 가능한 클랩
            </span>
            <div className="flex justify-between">
              <div className="flex gap-2 items-end">
                <div className="flex gap-1 text-achromatic-600 font-medium text-base leading-[19px]">
                  <CoinIcon />
                  <span className="text-center">{wallet.klav}클랩</span>
                </div>
                <div className="flex items-center font-medium text-xs text-achromatic-300 leading-[19px]">
                  1 클랩 = 1000 원
                </div>
              </div>
              <div>
                <button
                  className="bg-primary-500 rounded-md text-white font-semibold text-sm py-2 px-6"
                  onClick={handeChargeClick}>
                  클랩 충전
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <Button
              text={`${auctionDTO.currentPrice - calculateFees(auctionDTO.immediatePrice).deposit + 3
                }클랩 결제하기`}
              onClick={onPayment}
            />
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default AuctionPaymentModal;
