import { useState } from "react";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";

import { getCreatorList, putApproveCreator } from "apis";
import { ImageGallery } from "components";
import { CreatorApplicationStatus, CreatorDTO } from "types";
import { useAlertStore } from "store";

const CreatorManageComponent = () => {
    const showAlert = useAlertStore((state) => state.showAlert);
    const [currentPage, setCurrentPage] = useState(1);
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const itemsPerPage = 10;

    const { data, isLoading, isError } = useQuery(["creatorList", currentPage, itemsPerPage], () => getCreatorList(currentPage, itemsPerPage), {
        keepPreviousData: true,
    });

    if (isLoading) return <div>로딩 중...</div>;
    if (isError) return <div>오류가 발생했습니다.</div>;

    const creatorList = data?.data.data.items || [];
    const totalItems = data?.data.data.total || 0;
    const pageCount = Math.ceil(totalItems / itemsPerPage);

    const handlePageClick = ({ selected }: { selected: number }) => {
        setCurrentPage(selected + 1);
    };

    const handleApproveClick = async (status: CreatorApplicationStatus) => {
        try {
            const response = await putApproveCreator(expandedRow!, status);
            if (response.status === 200) {
                showAlert("처리 완료되었습니다.", 'success');
                setCurrentPage(1);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const toggleRow = (id: string) => {
        setExpandedRow(expandedRow === id ? null : id);
    }

    return (
        <div className="h-full flex flex-col gap-6 py-6 px-6">
            <div className="text-2xl font-bold">크리에이터 신청 내역 조회</div>

            <div className="flex items-center bg-white rounded-md h-[100px] shadow">
                <div className="flex px-6 text-lg font-medium">총 {totalItems}건</div>
            </div>

            <div className="bg-white rounded-md shadow flex flex-col px-6 py-8">
                <div className="overflow-x-auto">
                    <table className="w-full text-center border-collapse">
                        <thead>
                            <tr className="border-b">
                                <th className="py-2 px-4">번호</th>
                                <th className="py-2 px-4">제목</th>
                                <th className="py-2 px-4">동의 현황</th>
                                <th className="py-2 px-4">상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            {creatorList.map((item, index) => (
                                <>
                                    <tr
                                        key={item.id}
                                        className={`border-b cursor-pointer hover:bg-gray-100 ${expandedRow === item.id ? "bg-gray-50" : ""}`}
                                        onClick={() => toggleRow(item.id)}
                                    >
                                        <td className="py-2 px-4">{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                        <td className="py-2 px-4 truncate" title={item.user.email}>{item.user.email}</td>
                                        <td className="py-2 px-4 truncate" title={item.isCreatorTermsAgreed.toString()}>{item.isCreatorTermsAgreed}</td>
                                        <td className={`py-2 px-4 bg-opacity-10 ${item.status === CreatorApplicationStatus.ACCEPTED_APPLICATION ? "bg-green-500" : item.status === CreatorApplicationStatus.REJECTED_APPLICATION ? "bg-red-500" : "bg-slate-100"}`}>{item.status}</td>
                                    </tr>
                                    {expandedRow === item.id && (
                                        <tr className="bg-gray-50">
                                            <td colSpan={4} className="py-6 px-6 text-left">
                                                <div className="flex flex-col gap-2">
                                                    <div className="flex flex-col gap-2">
                                                        <span className="font-bold">고객 정보</span>
                                                        <span>닉네임: {item.user.name} </span>
                                                        <span>이메일: {item.user.email} </span>
                                                        <span>생년월일: {item.user.birth} </span>
                                                        <span>주소: {item.user.address}</span>
                                                    </div>
                                                    <div className="flex flex-col  gap-2">
                                                        <div className="font-bold">크리에이터 약관 동의 현황</div>
                                                        <div>{item.isCreatorTermsAgreed}</div>
                                                    </div>
                                                    <div className="flex flex-col gap-2">
                                                        <div className="font-bold">계좌번호</div>
                                                        <div>{item.accountNumber}</div>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <span className="font-bold">신분증</span>
                                                        <ImageGallery
                                                            images={[item.identification]}
                                                        />
                                                    </div>
                                                    <div className="flex gap-2 justify-end">
                                                        <button
                                                            className="bg-primary-500 hover:bg-primary-600 text-white font-medium rounded-lg px-4 py-2 transition-all duration-150 ease-in-out"
                                                            onClick={() => handleApproveClick(CreatorApplicationStatus.ACCEPTED_APPLICATION)}
                                                        >
                                                            승인
                                                        </button>
                                                        <button
                                                            className="bg-red-500 hover:bg-red-600 text-white font-medium rounded-lg px-4 py-2 transition-all duration-150 ease-in-out"
                                                            onClick={() => handleApproveClick(CreatorApplicationStatus.REJECTED_APPLICATION)}
                                                        >
                                                            거절
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="mt-6 flex justify-center">
                    <ReactPaginate
                        previousLabel={"이전"}
                        nextLabel={"다음"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"flex space-x-2"}
                        activeClassName={"active bg-primary-500 transition-all duration-150 ease-in-out"}
                        previousClassName={"px-3 py-1 border rounded"}
                        nextClassName={"px-3 py-1 border rounded"}
                        pageClassName={"px-3 py-1 border rounded"}
                        activeLinkClassName={"text-white"}
                    />
                </div>
            </div>
        </div>
    );
}

export default CreatorManageComponent;