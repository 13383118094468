import React, { useEffect } from 'react';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg'

interface ModalProps {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const BaseModal = ({ title, isOpen, onClose, children }: ModalProps) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // 모달 컴포넌트가 언마운트될 때 스크롤 복구
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-10">
            <div className="bg-white p-10 rounded-2xl border border-achromatic-100 mobile:p-6 mobile:max-w-[340px]">
                <div className='flex justify-between'>
                    <span className='text-[22px] font-semibold mobile:w-[240px]'>{title}</span>
                    <div
                        className='bg-achromatic-100 rounded-full p-2 cursor-pointer group mobile:w-[34px] mobile:h-[34px]'
                        onClick={onClose}
                    >
                        <CloseIcon className='fill-black group-hover:fill-red-500' />
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

export default BaseModal;
