interface CheckBoxProps {
    label: React.ReactNode;
    checked: boolean;
    onChange: () => void;
}

const CheckBox = ({ label, checked, onChange }: CheckBoxProps) => {
    return (
        <div className='flex gap-2'>
            <input
                type='checkbox'
                className='min-w-6 min-h-6 max-w-6 max-h-6 appearance-none rounded-lg border-2 border-achromatic-200
                    checked:bg-primary-500 checked:border-transparent checked:bg-[url("./assets/images/check.checkbox.svg")] bg-no-repeat bg-center bg-contain
                    transition-all duration-200 ease-in-out transform cursor-pointer'
                style={{ backgroundSize: '70%' }}
                checked={checked}
                onChange={onChange}
            />
            <div className='font-medium text-achromatic-600 content-center'>
                {label}
            </div>
        </div>
    );
};

export default CheckBox;