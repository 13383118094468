import { reportPost } from "apis";
import { BaseModal, Button, CheckBox } from "components";
import { useState } from "react";
import { PostDTO } from "types";

interface ReportModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    post: PostDTO;
}

const ReportModal = ({ isOpen, onClose, title, post }: ReportModalProps) => {
    const [illegalChecked, setIllegalChecked] = useState(false);
    const [inappropriateChecked, setInappropriate] = useState(false);
    const [fraudChecked, setFraud] = useState(false);
    const [copyrightChecked, setCopyRight] = useState(false);
    const [spamChecked, setSpam] = useState(false);

    const handleReport = async () => {
        await reportPost(
            post,
            illegalChecked,
            inappropriateChecked,
            fraudChecked,
            copyrightChecked,
            spamChecked
        );
        onClose();
    }


    return (
        <BaseModal title={title} isOpen={isOpen} onClose={onClose}>
            <div className="flex flex-col gap-[60px] w-[340px] mobile:w-[292px]">
                <div className="flex flex-col text-sm font-semibold pt-8">

                    <div className="flex flex-col gap-2 py-4">
                        <div className="">
                            <CheckBox
                                label="불법 콘텐츠"
                                checked={illegalChecked}
                                onChange={() => { setIllegalChecked(!illegalChecked) }}
                            />
                        </div>
                        <div className="text-sm text-achromatic-500">
                            <span>무기, 마약, 향정신성 의약품 등 법률 위반 사항</span>
                        </div>
                    </div>

                    <div className="flex flex-col gap-2 py-4">
                        <div className="">
                            <CheckBox
                                label="부적절한 콘텐츠"
                                checked={inappropriateChecked}
                                onChange={() => { setInappropriate(!inappropriateChecked) }}
                            />
                        </div>
                        <div className="text-sm text-achromatic-500">
                            <span>음란물, 폭력적인 내용 등</span>
                        </div>
                    </div>

                    <div className="flex flex-col gap-2 py-4">
                        <div className="">
                            <CheckBox
                                label="사기 의심"
                                checked={fraudChecked}
                                onChange={() => { setFraud(!fraudChecked) }}
                            />
                        </div>
                        <div className="text-sm text-achromatic-500">
                            <span>허위 정보나 사기성 판매 행위</span>
                        </div>
                    </div>

                    <div className="flex flex-col gap-2 py-4">
                        <div className="">
                            <CheckBox
                                label="저작권 침해"
                                checked={copyrightChecked}
                                onChange={() => { setCopyRight(!copyrightChecked) }}
                            />
                        </div>
                        <div className="text-sm text-achromatic-500">
                            <span>타인의 저작권을 침해하는 콘텐츠</span>
                        </div>
                    </div>

                    <div className="flex flex-col gap-2 py-4">
                        <div className="">
                            <CheckBox
                                label="스팸 및 광고"
                                checked={spamChecked}
                                onChange={() => { setSpam(!spamChecked) }}
                            />
                        </div>
                        <div className="text-sm text-achromatic-500">
                            <span>과도한 스팸성 콘텐츠나 광고</span>
                        </div>
                    </div>

                </div>
                <div className="flex flex-col">
                    <Button
                        text="확인"
                        onClick={handleReport}
                    />
                </div>
            </div>
        </BaseModal>
    )
}

export default ReportModal;