import { PageHeader, AuctionWrapper } from "components";

const AuctionComponent = () => {
    return (
        <div>
            <AuctionWrapper />
        </div>
    )
}

export default AuctionComponent;