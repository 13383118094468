import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import { ProposeCreatorModal } from 'components';
import { useAlertStore, useUserStore } from 'store';
import { updateProfile } from 'apis';

import { ReactComponent as WalletIcon } from 'assets/images/wallet.icon.svg'
import { ReactComponent as SettingIcon } from 'assets/images/setting.icon.svg'
import { ReactComponent as CameraIcon } from 'assets/images/camera.icon.svg'
import { ReactComponent as BlueCheck } from 'assets/images/verified.icon.svg'
import { getCreatorProposeInfo } from 'apis/creator.api';
import { CreatorApplicationStatus, ERROR, ResponseDTO } from 'types';
import { AxiosError } from 'axios';


interface ProfileComponentProps {
    isEditProfile?: boolean;
    onSave?: boolean;
    onSaveFailure?: () => void;
    onFollowClick?: () => void;
    showFollowComponent?: boolean;
}

interface EditAreaProps {
    onSave?: boolean;
    selectedProfileImage?: File | null;
    selectedBannerImage?: File | null;
    onSaveFailure?: () => void;
}

const EditArea = ({ onSave, selectedProfileImage, selectedBannerImage, onSaveFailure }: EditAreaProps) => {
    const showAlert = useAlertStore((state) => state.showAlert);
    const { user, setUser } = useUserStore();
    const navigate = useNavigate();

    const now = new Date();
    const [form, setForm] = useState({
        year: user?.birth ? parseInt(user.birth.split('-')[0]) : now.getFullYear(), // user.birth에서 연도를 가져옴
        month: user?.birth ? parseInt(user.birth.split('-')[1]) : 1, // user.birth에서 월을 가져옴
        day: user?.birth ? parseInt(user.birth.split('-')[2]) : 1, // user.birth에서 일을 가져옴
    });
    const [isUnderage, setIsUnderage] = useState(false);
    const [profileId, setProfileId] = useState<string>(user?.profileId || '');
    const [nickname, setNickname] = useState<string>(user?.name || '');
    const [address, setAddress] = useState<string>(user?.address || '');
    const [phone, setPhone] = useState<string>(user?.phone || '');

    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    // const [profileImage, setProfileImage] = useState<string>('');
    // const [bannerImage, setBannerImage] = useState<string>('');

    const years = [];
    for (let y = now.getFullYear(); y >= 1930; y -= 1) {
        years.push({ value: y, label: y.toString() }); // label은 string 형식으로 설정
    }

    const months = [];
    for (let m = 1; m <= 12; m += 1) {
        months.push({ value: m, label: m < 10 ? `0${m}` : m.toString() }); // label은 string 형식으로 설정
    }

    const [days, setDays] = useState<{ value: number; label: string }[]>([]);

    useEffect(() => {
        const date = new Date(form.year, form.month, 0).getDate();
        const newDays: { value: number; label: string }[] = [];
        for (let d = 1; d <= date; d += 1) {
            newDays.push({ value: d, label: d < 10 ? `0${d}` : d.toString() }); // label은 string 형식으로 설정
        }
        setDays(newDays);

        if (form.day > date) {
            setForm((prevForm) => ({ ...prevForm, day: 1 })); // day를 1로 설정
        }
    }, [form.year, form.month, form.day]);

    // 나이 계산 함수
    const calculateAge = () => {
        const birthDate = new Date(form.year, form.month - 1, form.day); // month는 0부터 시작
        const age = now.getFullYear() - birthDate.getFullYear();
        const monthDifference = now.getMonth() - birthDate.getMonth();

        // 생일이 지났는지 확인
        if (monthDifference < 0 || (monthDifference === 0 && now.getDate() < birthDate.getDate())) {
            return age - 1;
        }
        return age;
    };

    const age = calculateAge();

    useEffect(() => {
        setIsUnderage(age < 19); // 19세 미만인 경우 true
    }, [age]);

    const saveEditedProfile = async () => {
        try {
            const response = await updateProfile(
                profileId,
                nickname,
                `${form.year}-${form.month}-${form.day}`,
                address,
                phone,
                selectedProfileImage,
                selectedBannerImage,
            );
            if (response.data.isSuccess === 'T') {
                setUser(response.data.data);
                navigate('/mypage');
                window.location.reload();
            }
        } catch (error) {
            const axiosError = error as AxiosError<ResponseDTO<any>>;
            if (axiosError.response?.data?.message === ERROR.EXIST_PROFILE_ID) {
                showAlert('이미 존재하는 프로필 아이디입니다.', 'error');
                onSaveFailure && onSaveFailure();
                setIsError(true);
                setErrorMessage('사용 중인 아이디입니다. 다른 아이디를 입력해주세요.');
            }
        }
    }

    useEffect(() => {
        if (onSave) {
            saveEditedProfile();
        }
    }, [onSave])

    useEffect(() => {
        if (isError) {
            setIsError(false);
        }
    }, [profileId])

    return (
        <div className='flex flex-col gap-6 p-6 pt-0 text-achromatic-400 font-medium text-sm'>
            <div className='flex flex-col gap-2'>
                <span>아이디</span>
                <input
                    className='px-3 min-h-12 border border-achromatic-100 rounded-lg text-black font-semibold focus:outline-none'
                    value={profileId}
                    onChange={(e) => setProfileId(e.target.value)}
                >
                </input>
                {isError && (
                    <div className='text-[#F04D1A] font-medium'>{errorMessage}</div>
                )}
                <span className='text-achromatic-300'>https://klavit.com/@{profileId}</span>
            </div>
            <div className='flex flex-col gap-2'>
                <span>닉네임</span>
                <input
                    className='px-3 min-h-12 border border-achromatic-100 rounded-lg text-black font-semibold focus:outline-none'
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                >
                </input>
            </div>

            <div className="flex flex-col gap-4">
                <span className="text-sm text-achromatic-400 font-medium">생년월일</span>
                <div className="flex gap-4">
                    {/* 년도 선택 */}
                    <Select
                        options={years}
                        value={years.find((year) => year.value === form.year)}
                        onChange={(selected) => setForm({ ...form, year: selected?.value! })} // year을 number 형식으로 설정
                        className="w-1/3"
                        classNamePrefix="react-select"
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        styles={{
                            control: (styles) => ({
                                ...styles,
                                width: '100%',
                                height: '3rem',
                                border: '1px solid #E5E7EB',
                                borderRadius: '0.5rem',
                                color: '#374151',
                                fontSize: '0.875rem',
                                fontWeight: 600,
                                lineHeight: 1.25,
                                transition: 'all 0.2s',
                                '&:hover': {
                                    borderColor: '#9CA3AF',
                                },
                            }),
                        }}
                    />

                    {/* 월 선택 */}
                    <Select
                        options={months}
                        value={months.find((month) => month.value === form.month)}
                        onChange={(selected) => setForm({ ...form, month: selected?.value! })} // month을 number 형식으로 설정
                        className="w-1/3 text-sm"
                        classNamePrefix="react-select"
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        styles={{
                            control: (styles) => ({
                                ...styles,
                                width: '100%',
                                height: '3rem',
                                border: '1px solid #E5E7EB',
                                borderRadius: '0.5rem',
                                color: '#374151',
                                fontSize: '0.875rem',
                                fontWeight: 600,
                                lineHeight: 1.25,
                                transition: 'all 0.2s',
                                '&:hover': {
                                    borderColor: '#9CA3AF',
                                },
                            }),
                        }}
                    />

                    {/* 일 선택 */}
                    <Select
                        options={days}
                        value={days.find((day) => day.value === form.day)}
                        onChange={(selected) => setForm({ ...form, day: selected?.value! })} // day을 number 형식으로 설정
                        className="w-1/3"
                        classNamePrefix="react-select"
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        styles={{
                            control: (styles) => ({
                                ...styles,
                                width: '100%',
                                height: '3rem',
                                border: '1px solid #E5E7EB',
                                borderRadius: '0.5rem',
                                color: '#374151',
                                fontSize: '0.875rem',
                                fontWeight: 600,
                                lineHeight: 1.25,
                                transition: 'all 0.2s',
                                '&:hover': {
                                    borderColor: '#9CA3AF',
                                },
                            }),
                        }}
                    />
                </div>
                {isUnderage && (
                    <div className="text-sm text-[#F04D1A] font-medium">
                        클래빗은 19세 이상만 이용할 수 있습니다.
                    </div>
                )}
            </div>

            <div className='flex flex-col gap-2'>
                <span>배송지 정보</span>
                <input
                    className='px-3 min-h-12 border border-achromatic-100 rounded-lg placeholder-achromatic-200 text-black font-semibold focus:outline-none'
                    value={address}
                    placeholder="이름과 도로명 주소를 입력해주세요."
                    onChange={(e) => setAddress(e.target.value)}
                >
                </input>
            </div>

            <div className='flex flex-col gap-2'>
                <span>전화번호</span>
                <input
                    className='px-3 min-h-12 border border-achromatic-100 rounded-lg placeholder-achromatic-200 text-black font-semibold focus:outline-none'
                    value={phone}
                    placeholder="연락 가능한 휴대폰 번호를 적어주세요."
                    onChange={(e) => setPhone(e.target.value)}
                >
                </input>
            </div>
        </div>
    )
}

const ProfileComponent = ({ isEditProfile, onSave, onSaveFailure, onFollowClick, showFollowComponent }: ProfileComponentProps) => {
    const navigate = useNavigate();

    const { user } = useUserStore();

    const [isProposeCreatorModalOpen, setIsProposeCreatorModalOpen] = useState<boolean>(false);
    const [selectedProfileImage, setSelectedProfileImage] = useState<File | null>(null);
    const [selectedBannerImage, setSelectedBannerImage] = useState<File | null>(null);
    const [isAlreadyPropose, setIsAlreadyPropose] = useState<boolean>(false);

    const profileImageInputRef = useRef<HTMLInputElement>(null);
    const bannerImageInputRef = useRef<HTMLInputElement>(null);

    const openProposeCreatorModal = () => {
        setIsProposeCreatorModalOpen(true);
    }

    const closeModal = () => {
        setIsProposeCreatorModalOpen(false);
        window.location.reload();
    };

    const handleEditProfileClick = () => {
        navigate('/mypage/edit-profile');
    }

    const handleEditProfileImageClick = () => {
        if (!profileImageInputRef.current) return
        profileImageInputRef.current.click();
    }

    const handleEditBannerImageClick = () => {
        if (!bannerImageInputRef.current) return
        bannerImageInputRef.current.click();
    }

    const handleProfileImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            setSelectedProfileImage(file);
        }
    }

    const handleBannerImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            setSelectedBannerImage(file);
        }
    }

    const getCreatorProposeState = async () => {
        if (user.role === 'creator') return;

        try {
            const response = await getCreatorProposeInfo();
            if (response.data.data.status === CreatorApplicationStatus.PENDING_APPLICATION) {
                setIsAlreadyPropose(true);
            }
            console.log('Get Creator Propose Info Success: ', response);
        } catch (error) {
            console.error('Get Creator Propose Info Error: ', error);
        }
    }

    useEffect(() => {
        getCreatorProposeState();
    }, [])

    return (
        <div className="flex flex-col border-b border-achromatic-100">
            <div className="relative p-0">
                {
                    user?.bannerImage || selectedBannerImage ? (
                        <img
                            src={selectedBannerImage ? URL.createObjectURL(selectedBannerImage) : `${process.env.REACT_APP_API_URL}/${user.bannerImage}`}
                            alt="banner"
                            className="w-full h-[320px] object-cover"
                        />
                    ) : (
                        <div className="flex items-center justify-center text-white font-semibold text-2xl bg-achromatic-100 max-w-[680px] h-[320px]">
                            default image
                        </div>
                    )
                }
                {isEditProfile && (
                    <div
                        className='absolute border-2 border-achromatic-50 flex items-center justify-center rounded-full bottom-2 right-2 bg-achromatic-100 p-[5px] cursor-pointer'
                        onClick={handleEditBannerImageClick}
                    >
                        <div className='flex items-center justify-center w-4 h-4'>
                            <CameraIcon className='' />
                            <input
                                className='hidden'
                                ref={bannerImageInputRef}
                                onChange={handleBannerImageUpload}
                                type="file"
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="flex justify-between p-6 mobile:items-center">
                <div className="flex gap-3">
                    <div className='relative flex'>
                        {user?.profileImage || selectedProfileImage ? (
                            <img
                                src={selectedProfileImage ? URL.createObjectURL(selectedProfileImage) : `${process.env.REACT_APP_API_URL}/${user.profileImage}`}
                                alt="profile"
                                className="w-16 h-16 object-cover rounded-full"
                            />
                        ) : (
                            <div className="w-16 h-16 bg-achromatic-100 rounded-full" >
                            </div>
                        )}
                        {isEditProfile && (
                            <div
                                className='absolute border-2 border-achromatic-50 flex items-center justify-center rounded-full bottom-0 right-0 bg-achromatic-100 p-[5px] cursor-pointer'
                                onClick={handleEditProfileImageClick}
                            >
                                <div className='flex items-center justify-center w-4 h-4'>
                                    <CameraIcon className='' />
                                    <input
                                        className='hidden'
                                        ref={profileImageInputRef}
                                        onChange={handleProfileImageUpload}
                                        type="file"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col justify-center">
                        <div className="text-black leading-[17px] font-semibold break-words flex flex-wrap items-center gap-1">
                            <span className="break-words max-w-full">{user?.name}</span>
                            {user.role === 'creator' && <span className="shrink-0"><BlueCheck /></span>}
                        </div>
                        <div className="text-achromatic-300 leading-[17px]">@{user?.profileId}</div>
                    </div>
                </div>
                {!isEditProfile && (
                    <div className="flex gap-2 max-h-[34px] text-xs font-semibold">
                        {user.role !== 'creator' && (
                            <>
                                {isAlreadyPropose ? (
                                    <button className='text-achromatic-500 bg-achromatic-100 rounded-[4px] px-4 mobile:hidden' disabled>
                                        Creator 신청 대기중
                                    </button>
                                ) : (
                                    <button className='text-white bg-primary-500 rounded-[4px] px-4 mobile:hidden' onClick={openProposeCreatorModal}>
                                        Creator 계정 전환
                                    </button>
                                )}
                            </>
                        )}

                        {user.role === 'creator' && (
                            <button
                                className='bg-primary-500 text-white rounded-[4px] border px-4 mobile:h-[34px] mobile:hidden hover:bg-primary-600 ease-in-out duration-150 transition-transform'
                                onClick={onFollowClick}
                            >
                                {showFollowComponent ? "게시물 목록" : "팔로잉"}
                            </button>
                        )}

                        <button
                            className='text-achromatic-500 rounded-[4px] border border-achromatic-100 px-4 text-nowrap mobile:h-[34px]'
                            onClick={handleEditProfileClick}
                        >
                            프로필 수정
                        </button>

                        {/* 11.07 삭제 */}
                        {/* <button className='flex w-[34px] h-[34px] border border-achromatic-100 rounded-full justify-center items-center'>
                            <WalletIcon className='w-5 h-5' />
                        </button>
                        <button className='flex w-[34px] h-[34px] border border-achromatic-100 rounded-full justify-center items-center'>
                            <SettingIcon className='w-5 h-5' />
                        </button> */}
                    </div>
                )}
            </div>
            {
                !isEditProfile && user.role !== 'creator' && (
                    <div className="w-full justify-center h-[34px] text-xs font-semibold hidden mobile:flex mobile:my-4">
                        {isAlreadyPropose ? (
                            <button className='min-w-[328px] text-achromatic-500 rounded-[4px] border bg-achromatic-100 px-4' disabled>
                                Creator 신청 대기중
                            </button>
                        ) : (
                            <button className='min-w-[328px] text-white bg-primary-500 rounded-[4px] px-4' onClick={openProposeCreatorModal}>
                                Creator 계정 전환
                            </button>
                        )
                        }
                    </div>
                )
            }

            {user.role === 'creator' && (
                <button
                    className='w-full justify-center text-xs items-center bg-primary-500 text-white px-4 mobile:h-[34px] hidden mobile:flex hover:bg-primary-600 ease-in-out duration-150 transition-transform'
                    onClick={onFollowClick}
                >
                    {showFollowComponent ? "게시물 목록" : "팔로잉"}
                </button>
            )}


            {
                isEditProfile &&
                <EditArea
                    onSave={onSave}
                    onSaveFailure={onSaveFailure}
                    selectedProfileImage={selectedProfileImage}
                    selectedBannerImage={selectedBannerImage} />
            }

            <div>
                <ProposeCreatorModal
                    isOpen={isProposeCreatorModalOpen}
                    onClose={closeModal}
                    title="본인 신분증과 계좌번호를 제출해주세요."
                />
            </div>
        </div >
    );
}

export default ProfileComponent;