import { getMyWallet, processWinningBidPayment, purchaseNowAuction, updateWallet } from "apis";
import { BaseModal, Button } from "components";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAlertStore, useWalletStore } from "store";
import { AuctionDTO } from "types";

const calculateFees = (price: number) => {
    if (price >= 10 && price <= 49) {
        return { deposit: 5, creator: 2, company: 1, winner: 2 };
    } else if (price >= 50 && price <= 99) {
        return { deposit: 10, creator: 3, company: 3, winner: 4 };
    } else if (price >= 100 && price <= 499) {
        return { deposit: 20, creator: 6, company: 6, winner: 8 };
    } else if (price >= 500) {
        return { deposit: 50, creator: 15, company: 15, winner: 20 };
    } else {
        throw new Error("최소 즉시구매가는 10 klav 이상이어야 합니다.");
    }
}

interface ConfirmPaymentModalProps {
    isOpen: boolean;
    onClose: () => void;
    onCancle: () => void;
    title: string;
    auction: AuctionDTO;
    type: string;
    onConfirm: () => void;
}

const ConfirmPaymentModal = ({
    title,
    isOpen,
    onClose,
    onCancle,
    auction,
    type,
    onConfirm
}: ConfirmPaymentModalProps) => {
    const showAlert = useAlertStore((state) => state.showAlert);
    const payAmount = type === "bidding" ? auction.currentPrice - calculateFees(auction.immediatePrice).deposit + 3 : auction.immediatePrice + 3;
    const { wallet, setWallet } = useWalletStore();
    const { refetch } = useQuery("wallet", getMyWallet, {
        enabled: false,
        onSuccess: (data) => {
            setWallet(data.data.data);
        },
    });

    const handleImmediateBid = () => {
        if (wallet.klav >= (payAmount)) {
            purchaseNowAuction(auction.id)
                .then((response) => {
                    updateWallet((payAmount), "payment")
                        .then((response) => {
                            showAlert("즉시 구매 완료되었습니다", "success");
                            refetch();
                            onClose();
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            showAlert("보유 클랩이 부족합니다", "error");
        }
    };

    const handlePaymentBid = () => {
        if (wallet.klav >= (payAmount)) {
            processWinningBidPayment(
                auction.id,
                payAmount
            )
                .then((response) => {
                    showAlert('결제가 완료되었습니다.', 'success')
                    refetch();
                    window.location.reload();
                    onClose();
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            showAlert('보유금액이 부족합니다.', 'error')
        }
    };

    return (
        <BaseModal title={title} isOpen={isOpen} onClose={onCancle}>
            <div className="flex flex-col gap-[60px] w-[560px] mobile:w-[292px] mobile:gap-4">
                <div className="flex flex-col pt-8 gap-8 mobile:gap-4">
                    <div className="flex flex-col mobile:gap-4">
                        <div className="flex flex-col py-4 px-6 gap-2 mobile:p-0">
                            <span className="font-semibold text-lg text-achromatic-800 leading-[19px]">
                                총
                                <span className="text-primary-500"> {payAmount.toLocaleString()}</span>
                                클랩을 결제하시겠습니까?
                            </span>
                        </div>

                        <div className="flex flex-col py-4 px-6 gap-3 mobile:p-0">


                            <div className="flex flex-col bg-achromatic-50 rounded-xl py-4 px-6 gap-2">

                                <span className="font-medium text-sm text-achromatic-500 leading-[17px]">
                                    1. 낙찰된 상품은 결제 후 취소할 수 없습니다.
                                </span>

                                <span className="font-medium text-sm text-achromatic-500 leading-[17px]">
                                    2. 결제 완료 시 즉시 거래가 진행됩니다.
                                </span>
                            </div>
                        </div>
                        {/* <div className="flex flex-col py-4 px-6 gap-2 mobile:p-0">
                            <span className="font-semibold text-base text-achromatic-800 leading-[19px]">
                                2. 입찰 및 입찰 취소
                            </span>
                            <span className="font-medium text-sm text-achromatic-500 leading-[17px]">
                                결제 완료 시 즉시 거래가 진행됩니다.
                            </span>
                        </div> */}
                    </div>
                </div>
                <div className="">
                    <Button text="결제 완료하기" onClick={type === "bidding" ? handlePaymentBid : handleImmediateBid} />
                </div>
            </div>
        </BaseModal>
    )
};

export default ConfirmPaymentModal;