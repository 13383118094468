import React, { useEffect, useRef, useState } from "react";

import { usePostStore } from "store";

import { ReactComponent as CloseIcon } from 'assets/images/close.icon.svg'
import { ReactComponent as RightArrow } from "assets/images/arrow_forward.svg";
import { ReactComponent as LeftArrow } from "assets/images/arrow_back.svg";

interface ImageGalleryProps {
    images: string[];
    isPosting?: boolean;
    isAuction?: boolean;
}

const ImageGallery = ({ images = [], isPosting, isAuction }: ImageGalleryProps) => {
    const { deleteImage } = usePostStore();
    const scrollRef = useRef<HTMLDivElement>(null);
    const [isDrag, setIsDrag] = useState<boolean>(false);
    const [hasDragged, setHasDragged] = useState<boolean>(false); // 드래그 발생 여부 체크

    const [startX, setStartX] = useState<number>(0);
    const [scrollLeft, setScrollLeft] = useState<number>(0); // 스크롤 위치
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalImageIndex, setModalImageIndex] = useState<number>(0);

    const handleDragStart = (event: React.MouseEvent | React.TouchEvent) => {
        event.preventDefault();
        setIsDrag(true);

        setHasDragged(false);

        const pageX = "touches" in event ? event.touches[0].pageX : event.pageX;

        if (scrollRef.current) {
            setStartX(pageX + scrollRef.current.scrollLeft);
        }
    };

    const handleDragMove = (event: MouseEvent | TouchEvent) => {
        if (!isDrag || !scrollRef.current) return;

        setIsDrag(true);
        const pageX = "touches" in event ? event.touches[0].pageX : event.pageX;
        scrollRef.current.scrollLeft = startX - pageX;

        if (!hasDragged) {
            setHasDragged(true); // 드래그가 실제로 발생했음을 기록
        }
    };

    const handleDragEnd = () => {
        setIsDrag(false);
    };

    useEffect(() => {
        if (isDrag) {
            window.addEventListener("mousemove", handleDragMove as EventListener);
            window.addEventListener("mouseup", handleDragEnd);
            window.addEventListener("touchmove", handleDragMove as EventListener);
            window.addEventListener("touchend", handleDragEnd);
        } else {
            window.removeEventListener("mousemove", handleDragMove as EventListener);
            window.removeEventListener("mouseup", handleDragEnd);
            window.removeEventListener("touchmove", handleDragMove as EventListener);
            window.removeEventListener("touchend", handleDragEnd);
        }

        return () => {
            window.removeEventListener("mousemove", handleDragMove as EventListener);
            window.removeEventListener("mouseup", handleDragEnd);
            window.removeEventListener("touchmove", handleDragMove as EventListener);
            window.removeEventListener("touchend", handleDragEnd);
        };
    }, [isDrag]);

    const handleImageClick = (index: number) => {
        if (isDrag || hasDragged) return;

        setModalImageIndex(index);
        setIsModalOpen(true);

    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const showNextImage = () => {
        setModalImageIndex((prev) => (prev + 1) % images.length);
    };

    const showPrevImage = () => {
        setModalImageIndex((prev) => (prev - 1 + images.length) % images.length);
    };

    return (
        <div
            ref={scrollRef}
            onMouseDown={handleDragStart}
            onTouchStart={handleDragStart}
            className={`imageGallery flex gap-2 overflow-x-hidden overflow-y-hidden px-6 ${images.length > 0 && !isAuction ? 'my-8' : ''} ${!isAuction ? 'max-h-[312px]' : 'max-h-[288px]'}`}
        >
            {images.map((image, index) => (
                <div
                    key={index}
                    // 이미지가 1개인 경우 width, height 조정
                    className={
                        `rounded-2xl overflow-hidden relative
                         ${!isAuction ?
                            `${images.length === 1 ? "w-full min-w-[312px] min-h-[312px]" : "w-full min-w-[312px] min-h-[312px]"}` :
                            `${images.length === 1 ? "min-w-[580px] min-h-[288px] mobile:min-w-[288px]" : "min-w-[288px] min-h-[288px]"}`}`
                    }
                    onClick={() => handleImageClick(index)}
                >
                    <img
                        src={`${process.env.REACT_APP_API_URL}/${image}`}
                        alt={`Uploaded ${index}`}
                        className="w-full h-full object-cover object-center"
                        onContextMenu={(e) => e.preventDefault()}
                    />
                    {isPosting && (
                        <div
                            className="flex rounded-full justify-center items-center w-8 h-8 absolute top-3 right-3 bg-black bg-opacity-60 cursor-pointer"
                            // 버블링 방지
                            onClick={(e) => {
                                e.stopPropagation();
                                deleteImage(index);
                            }}
                        >
                            <CloseIcon className="" />
                        </div>
                    )}
                </div>
            ))}

            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <div className="relative">
                        {/* 닫기 버튼 */}
                        <button
                            onClick={closeModal}
                            className="absolute top-[-50px] right-0 text-white text-xl p-2 rounded-full z-10"
                        >
                            ✕
                        </button>

                        {/* 이전 이미지 버튼 */}
                        <button
                            onClick={showPrevImage}
                            className="absolute left-[-64px] top-1/2 transform -translate-y-1/2 text-white text-3xl p-2 rounded-full"
                        >
                            <LeftArrow />
                        </button>

                        {/* 이미지 */}
                        <img
                            src={`${process.env.REACT_APP_API_URL}/${images[modalImageIndex]}`}
                            alt="Modal Display"
                            className="max-w-[90vw] max-h-[90vh] object-contain"
                            onContextMenu={(e) => e.preventDefault()}
                        />

                        {/* 다음 이미지 버튼 */}
                        <button
                            onClick={showNextImage}
                            className="absolute right-[-64px] top-1/2 transform -translate-y-1/2 text-white text-3xl p-2 rounded-full"
                        >
                            <RightArrow />
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ImageGallery;