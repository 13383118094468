import { useState } from 'react';

import { CheckBox, Button } from 'components';
import { useLoginStore } from 'store';

import { ReactComponent as Logo } from 'assets/images/logo.svg';


const TermsOfServiceComponent = () => {
    const [isTermsAgreed, setIsTermsAgreed] = useState(false);
    const [isPrivacyPolicyAgreed, setIsPrivacyPolicyAgreed] = useState(false);

    const { view, setView } = useLoginStore();

    const areAllAgreed = isTermsAgreed && isPrivacyPolicyAgreed;

    const handleTermsAgree = () => {
        setIsTermsAgreed((prev) => !prev);
    };

    const handlePrivacyPolicyAgree = () => {
        setIsPrivacyPolicyAgreed((prev) => !prev);
    };

    const handleContinueClick = () => {
        if (areAllAgreed) {
            setView('memberInfo');
        }
    }

    return (
        <div className="flex flex-col w-full min-w-[470px] min-h-[594px] gap-6 items-left p-10 justify-between mobile:min-w-[280px]">
            <div className='flex flex-col gap-8'>
                <Logo className='w-10 h-10' />
                <div className='text-[22px] font-semibold'>
                    약관을 동의해주세요.
                </div>
                <div className='flex flex-col gap-6 text-achromatic-600'>
                    <CheckBox
                        checked={isTermsAgreed}
                        onChange={handleTermsAgree}
                        // label='(필수) 일반 이용약관에 동의합니다.'
                        label={
                            <>
                                (필수){" "}
                                <a
                                    href="https://docs.google.com/document/d/e/2PACX-1vRDH7v6Lkz8gO6S-mYEoN8zSCQA-4IM4ChKhr3BftQ1FsJvF5Op_iPbominJwBxlOuaVAr0VopxZpdU/pub"
                                    className='underline cursor-pointer'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    일반 이용약관
                                </a>
                                에 동의합니다.
                            </>
                        }
                    />
                    <CheckBox
                        checked={isPrivacyPolicyAgreed}
                        onChange={handlePrivacyPolicyAgree}
                        // label='(필수) 개인정보처리방침에 동의합니다.'
                        label={
                            <>
                                (필수){" "}
                                <a
                                    href="https://docs.google.com/document/d/e/2PACX-1vRVkLz72D_-QaezBHadnMa9uwXme4sX7qWVaKAFjV3gCP1clYy6oOIYZ4IpjF8odPYGMotLjZP8m6R9/pub"
                                    className='underline cursor-pointer'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    개인정보처리방침
                                </a>
                                에 동의합니다.
                            </>
                        }
                    />
                </div>
            </div>
            <div className='text-sm font-semibold'>
                <Button text='시작하기' disabled={!areAllAgreed} onClick={handleContinueClick} />
            </div>
        </div>
    );
}

export default TermsOfServiceComponent;