import React, { useEffect, useState } from 'react';
import { useAlertStore } from 'store';

const CustomAlert = () => {
    const { message, type, visible, hideAlert } = useAlertStore();
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        if (visible) {
            setFadeOut(false);
            const timer = setTimeout(() => {
                setFadeOut(true); // fadeOut 애니메이션 시작
                setTimeout(() => hideAlert(), 500); // fadeOut 이후 숨김 처리
            }, 3000); // 3초 후 시작

            return () => clearTimeout(timer);
        }
    }, [visible, hideAlert]);

    if (!visible) return null;

    return (
        <div
            className={`z-50 fixed top-0 left-1/2 px-6 py-3 bg-black rounded-full -translate-x-1/2
                ${fadeOut ? 'animate-fadeOut' : 'animate-fadeIn'}
            `}
            style={{
                top: '60px'
            }}
        >
            <span className='text-white text-base font-medium text-center'>{message}</span>
        </div>
    );
}

export default CustomAlert;
