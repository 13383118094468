import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { SettingHeader } from "components";

const MyPageSettingComponent = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/mypage');
    })

    return (
        <div className="flex flex-col w-full">
            <div>
                <SettingHeader title="마이페이지" />
            </div>
            <div className="flex flex-col py-6">

            </div>
        </div>
    );
}

export default MyPageSettingComponent;