import { useNavigate } from "react-router-dom";

const GuestLoginButton = () => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate("/login");
    }

    return (
        <div className="flex min-h-[76px] border-b border-achromatic-100 py-[18px] justify-end px-6">
            <button
                className="w-[120px] h-[40px] bg-primary-500 rounded-lg text-white font-semibold text-xs"
                onClick={handleLogin}
            >
                로그인
            </button>
        </div>
    );
}
export default GuestLoginButton;