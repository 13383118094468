import { ReactComponent as LoadingIcon } from 'assets/images/logo.svg';

const Loading = () => {
    return (
        <div className="flex justify-center animate-pulse">
            <div className="animate-scale">
                <LoadingIcon className='w-12 h-12' />
            </div>
        </div>
    );
}

export default Loading;
