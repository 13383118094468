import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AuctionDTO, AuctionStatus, PostDTO, UserDTO } from "types";
import { Button, ImageGallery, ReportModal } from "components";
import { useUserStore } from "store";
import { getPostLike, patchPostLike, deletePost } from "apis";

import { ReactComponent as EmptyHeartIcon } from "assets/images/empty.heart.svg";
import { ReactComponent as FillHeartIcon } from "assets/images/fill.heart.svg";
import { ReactComponent as ExportIcon } from "assets/images/export.svg";
import { ReactComponent as ThreeDotsIcon } from "assets/images/three.dots.svg";
import { ReactComponent as BlueCheck } from 'assets/images/verified.icon.svg'
import { ReactComponent as GrayHeart } from 'assets/images/gray.heart.svg'

interface PostProps {
    post: PostDTO;
    onSearch?: (term: string) => void;
}

interface PostHeaderProps {
    post: PostDTO;
}

interface AuctionContentProps {
    post: PostDTO;
    auction: AuctionDTO;
}

const PostHeader = ({ post }: PostHeaderProps) => {
    const { user } = useUserStore();
    const navigate = useNavigate();
    const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [isReportModalOpen, setReportModalOpen] = useState<boolean>(false);
    const isMine = post.postWriter.id === user.id;

    const getDate = (date: string | number | Date) => {
        const today = new Date();
        const postDateTime = new Date(date);

        const diffMilliseconds = today.getTime() - postDateTime.getTime();
        const diffMinutes = Math.floor(diffMilliseconds / (1000 * 60));
        const diffHours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
        const diffDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));

        // 1시간 미만일 경우: "N분 전"
        if (diffMinutes < 60) {
            return `${diffMinutes}분 전`;
        }

        // 24시간 미만일 경우: "N시간 전"
        if (diffHours < 24) {
            return `${diffHours}시간 전`;
        }

        // 하루 이상 지났을 경우: "N일 전"
        if (diffDays >= 1) {
            return `${diffDays}일 전`;
        }

        // 오늘 날짜일 경우: "HH:MM" 형식으로 표시
        if (
            postDateTime.getDate() === today.getDate() &&
            postDateTime.getMonth() === today.getMonth() &&
            postDateTime.getFullYear() === today.getFullYear()
        ) {
            const hour = postDateTime.getHours().toString().padStart(2, "0");
            const minute = postDateTime.getMinutes().toString().padStart(2, "0");
            return `${hour}:${minute}`;
        } else {
            // 오늘이 아니면 "MM.DD" 형식으로 표시
            const month = (postDateTime.getMonth() + 1).toString().padStart(2, "0");
            const day = postDateTime.getDate().toString().padStart(2, "0");
            return `${month}.${day}`;
        }
    };

    const handleCreatorClick = () => {
        navigate(`/@${post.postWriter.profileId}`);
    };

    const handleDropdownClick = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleDeleteClick = () => {
        if (post.auction) {
            if (post.auction.auctionStatus !== AuctionStatus.FINISHED && post.auction.auctionStatus !== AuctionStatus.PASSED) {
                alert("경매가 종료되지 않은 게시물은 삭제할 수 없습니다.");
                return
            }
        }

        deletePost(post.id)
            .then((response) => {
                window.location.reload(); // humm..
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleEditClick = () => {

    }

    const openReportModal = () => {
        setReportModalOpen(true);
    }

    const closeModal = () => {
        setReportModalOpen(false);
    }


    return (
        <div className="relative flex justify-between">
            <div className="flex items-center space-x-2 ">
                {post.postWriter.profileImage ? (
                    <img
                        src={`${process.env.REACT_APP_API_URL}/${post.postWriter.profileImage}`}
                        alt="profile"
                        className="w-8 h-8 rounded-full"
                    />
                ) : (
                    <div className="w-8 h-8 bg-achromatic-100 rounded-full"></div>
                )}
                <div className="flex flex-col cursor-pointer" onClick={handleCreatorClick}>
                    <div className="flex gap-1 text-black leading-[14px] font-semibold">
                        {post.postWriter.name} {post.postWriter.role === 'creator' && <BlueCheck />}
                    </div>
                    <div className="text-achromatic-300 leading-[14px]">
                        @{post.postWriter.profileId}
                    </div>
                </div>
            </div>
            <div className="flex gap-3">
                <span className="text-achromatic-300 text-sm">{getDate(post.createdAt!)}</span>
                <ThreeDotsIcon
                    className="cursor-pointer"
                    onClick={handleDropdownClick}
                />
            </div>
            {isDropdownOpen && (
                <div className="absolute flex flex-col bg-white border border-achromatic-100 right-0 top-6 w-[98px] rounded-lg text-achromatic-500 font-semibold text-sm shadow-md">
                    {isMine ? (
                        <div className="flex flex-col items-center text-center">
                            <button
                                className="py-4 leading-[17px]"
                                onClick={handleDeleteClick}
                            >
                                삭제
                            </button>
                            <div className="border-t w-full border-achromatic-100" />
                            <button
                                className="py-4 leading-[17px]"
                                onClick={handleEditClick}
                            >수정
                            </button>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center text-center">
                            <div
                                className="py-4 leading-[17px] cursor-pointer"
                                onClick={openReportModal}
                            >신고</div>
                        </div>
                    )}
                </div>
            )}

            {isReportModalOpen &&
                <div>
                    <ReportModal
                        title="신고하기"
                        isOpen={isReportModalOpen}
                        onClose={closeModal}
                        post={post}
                    />
                </div>
            }
        </div>
    );
};

const PostFooter = ({ post }: PostProps) => {
    return (
        <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
                <div
                    className="text-achromatic-300 cursor-pointer "
                >
                    <EmptyHeartIcon className="w-6 h-6 transform transition-transform duration-300 ease-in-out" />
                </div>
            </div>
            <div className="text-achromatic-300">
                <ExportIcon className="w-6 h-6" />
            </div>
        </div>
    );
};

const AuctionContent = ({ auction, post }: AuctionContentProps) => {
    const MAX_TITLE_LENGTH = 23;

    const [remainingTime, setRemainingTime] = useState<{ days: number; hours: number; minutes: number }>({ days: 0, hours: 0, minutes: 0 });

    useEffect(() => {
        const calculateTimeLeft = () => {
            const createdAt = new Date(auction.createdAt!);
            const periodDays = auction.period;
            const endDate = new Date(createdAt);
            endDate.setDate(createdAt.getDate() + Math.floor(periodDays));
            endDate.setHours(createdAt.getHours() + Math.floor((periodDays % 1) * 24));

            const now = new Date();
            const timeDifference = endDate.getTime() - now.getTime();

            let remainingDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            let remainingHours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let remainingMinutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

            if (timeDifference <= 0) {
                if (post.auction.auctionStatus === AuctionStatus.ONGOING) {
                    remainingDays = 0;
                    remainingHours = 0;
                    remainingMinutes = 1;
                } else {
                    remainingDays = 0;
                    remainingHours = 0;
                    remainingMinutes = 0;
                }
            }

            setRemainingTime({
                days: remainingDays,
                hours: remainingHours,
                minutes: remainingMinutes,
            });
        };

        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 60000);

        return () => clearInterval(timer);
    }, [auction.createdAt, auction.period, post.auction.auctionStatus]);

    return (
        <div className="flex flex-col gap-6 w-full h-full min-h-[484px] bg-achromatic-50 border border-achromatic-100 rounded-lg py-6 mobile:rounded-none mobile:border-x-0">
            <div className="flex justify-between h-[51px] px-6 mobile:h-[134px] mobile:flex-col mobile:gap-[11px]">
                <div className="flex flex-col gap-2">
                    <div>
                        <span className="font-semibold text-xs rounded-[4px] bg-[#E2F2E8] text-primary-600 py-1 px-[6px]">
                            🔥 {auction.numberOfBidders}명 입찰 참여중
                        </span>
                    </div>
                    <div className="flex gap-2 items-center mobile:flex-col mobile:items-start">
                        <span className="font-semibold text-lg">
                            {auction.title.length > MAX_TITLE_LENGTH
                                ? `${auction.title.slice(0, MAX_TITLE_LENGTH)}...`
                                : auction.title}
                        </span>
                        {post.auction.auctionStatus === AuctionStatus.FINISHED || post.auction.auctionStatus === AuctionStatus.PASSED || post.auction.auctionStatus === AuctionStatus.WINNING ?
                            (
                                <span className="text-[#F04D1A] font-semibold text-sm">
                                    경매 종료
                                </span>
                            ) : (
                                <span className="text-[#F04D1A] font-semibold text-sm">
                                    {(remainingTime.days === 0 && remainingTime.hours === 0 && remainingTime.minutes <= 1) ? (
                                        <span className="text-[#F04D1A] font-semibold text-sm">
                                            1분 이내 종료
                                        </span>
                                    ) : (
                                        <span className="text-[#F04D1A] font-semibold text-sm">
                                            {remainingTime.days}일 {remainingTime.hours}시간 {remainingTime.minutes}분 후 종료
                                        </span>
                                    )}
                                </span>
                            )
                        }
                    </div>
                </div>
                <div className="flex flex-col justify-center text-end gap-1 mobile:text-start">
                    <span className="text-xs font-medium text-achromatic-500">
                        현재가
                    </span>
                    <span className="text-[22px] font-semibold">
                        {auction.currentPrice}클랩
                    </span>
                </div>
            </div>
            {!auction.isWarning ? (
                <div className="auction-image-gallery">
                    {post.images.length > 0 ? (
                        <div className="w-full h-[288px] px-6">
                            <div className="w-full h-full flex items-center justify-center bg-achromatic-100 rounded-lg text-achromatic-400 px-6">
                                <GrayHeart />
                            </div>
                        </div>
                    ) : (
                        <div className="w-full h-[288px]  px-6">
                            <div className="w-full h-full flex items-center justify-center bg-achromatic-100 rounded-lg text-achromatic-400 px-6">이미지가 없습니다.</div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="w-full h-[288px] px-6">
                    <div className="w-full h-full flex items-center justify-center bg-achromatic-100 rounded-lg text-achromatic-400 px-6">
                        <GrayHeart />
                    </div>
                </div>
            )}
            <div className="h-[49px] px-6">
                <Button text="회원가입 후 입찰에 참여하세요" disabled={true} />
            </div>
        </div>
    );
};

const GuestPost = ({ post, onSearch }: PostProps) => {

    // 해시태그를 제거하는 함수
    const removeHashtags = (content: string) => {
        return content.replace(/#\S+/g, "").trim(); // #으로 시작하는 단어 제거
    };

    const formatHashtags = (hashtags: string) => {
        if (!hashtags) return "";
        return hashtags
            .split(",")
            .map((tag) => `#${tag.trim()}`)
            .join(" ");
    };

    const handleHashtagClick = (hashtag: string) => {
        onSearch && onSearch(hashtag);
    }


    return (
        <div className="w-full h-full border-b border-achromatic-100">
            <div className="flex flex-col w-full h-full py-6 text-sm font-medium mobile:pb-4">
                <div className="px-6">
                    <PostHeader
                        post={post}
                    />
                </div>

                <div className="pt-4 px-6">
                    <p>{removeHashtags(post.content)}</p>
                    &nbsp;
                    <p
                        className={`text-primary-500 ${post.hashtags.length > 0 &&
                            post.images.length === 0 &&
                            !post.auction
                            ? "pb-6"
                            : "no-japan"
                            }`}
                    >
                        {/* {formatHashtags(post.hashtags)} */}
                        {post.hashtags.length > 0 && post.hashtags.split(",").map((hashtag, index) => (
                            <span
                                key={index}
                                className="cursor-pointer hover:text-primary-600 hover:underline transition-all duration-150 ease-in-out"
                                onClick={() => handleHashtagClick(`#${hashtag.trim()}`)}
                            >
                                #{hashtag.trim()}{" "}
                            </span>
                        ))}
                    </p>
                </div>

                {post.auction ? (
                    <div className="p-6 mobile:p-0">
                        <AuctionContent post={post} auction={post.auction} />
                    </div>
                ) : (
                    <div className="">
                        {
                            post.images.length > 0 ? (
                                <div className="p-6 mobile:p-0">
                                    <div className="w-full h-[288px] flex items-center justify-center bg-achromatic-100 rounded-lg text-achromatic-400 px-6">
                                        <GrayHeart />
                                    </div>
                                </div>
                            ) : (
                                <div />
                            )
                        }
                    </div>
                )}
                <div className="px-6 mobile:pt-4">
                    <PostFooter post={post} />
                </div>
            </div>
        </div >
    );
};

export default GuestPost;
