export type GetUserInfoResponse = {
  id: number;
  email: string;
  name: string;
  nickname: string;
  profileImageUrl: string;
  role: string;
  createdAt: string;
  updatedAt: string;
};

export type CheckoutSessionResponse = {
  url: string;
};

export enum BidStatus {
  BIDDING = "BIDDING",
  NOT_BIDDING = "NOT_BIDDING",
  HIGHEST_BID = "HIGHEST_BID",
}

export enum AuctionStatus {
  ONGOING = "경매중", // 경매 ONGOING
  BIDDING = "입찰", // 입찰 BIDDING
  WINNING = "낙찰", // 낙찰 WINNING
  FINISHED = "경매종료", // 경매종료 FINISHED
  PASSED = "유찰", // 유찰 PASSED
  FAILED = "실패", // 실패 FAILED
}

export enum AuctionDeliveryStatus {
  PAYMENT_PENDING = "결제대기", // 결제대기 PAYMENT_PENDING
  PAYMENT = "결제완료", // 결제완료 PAYMENT
  CREATOR_DELIVERY = "크리에이터 배송완료", // 크리에이터 배송완료 CREATOR_DELIVERY
  INSPECTION = "검수완료", // 검수완료 INSPECTION
  DELIVERY = "배송완료", // 배송완료 DELIVERY
}

export enum CreatorTermsStatus {
  AGREED_TO_TERMS = "동의",
  DISAGREED_TO_TERMS = "비동의",
}

export enum CreatorApplicationStatus {
  PENDING_APPLICATION = "신청대기",
  ACCEPTED_APPLICATION = "신청수락",
  REJECTED_APPLICATION = "신청거절",
}

export enum WalletWithdrawStatus {
  PENDING = "대기",
  ACCEPTED = "승인",
  REJECTED = "거절",
}

export enum NotificationType {
  COMMON = "COMMON", // 일반
  ALERT = "ALERT", // 경고, 중요
  NOTICE = "NOTICE", // 공지
  EVENT = "EVENT", // 이벤트
  PAYMENT = "PAYMENT", // 결제
  SETTLEMENT = "SETTLEMENT", // 정산
}

export enum ERROR {
  SWEAR_WORD = "SWEAR_WORD", // 비속어 발견
  DUPLICATION = "DUPLICATION", // 중복 작성
  AUTHENTICATION = "AUTHENTICATION", // 인증에러
  EID_AUTHENTICATION = "EID_AUTHENTICATION", // 인증에러
  EID_REFRESH_AUTHENTICATION = "EID_REFRESH_AUTHENTICATION", // 인증에러
  AUTHORIZATION = "AUTHORIZATION", // 권한에러
  NOT_EXIST_DATA = "NOT_EXIST_DATA", // 해당 데이터 없음
  UNKNOWN_APP_ID = "UNKNOWN_APP_ID", // 비정상적인 앱 접근
  WRONG_PASSWORD = "WRONG_PASSWORD", // 비밀번호 틀림

  // User
  USER_DELETED = "USER_DELETED", // 탈퇴한 회원
  USER_BLOCKED = "USER_BLOCKED", // 차단된 회원
  EXIST_PROFILE_ID = "EXIST_PROFILE_ID", // 이미 존재하는 프로필 아이디

  //Auction
  AUCTION_SAVE_FAILED = "AUCTION_SAVE_FAILED", // 경매 사용자 정보 저장 실패
  INVALID_BID_AMOUNT = "INVALID_BID_AMOUNT", // 유효하지 않은 입찰 금액
  AUCTION_NOT_EXIST = "AUCTION_NOT_EXIST", // 경매가 존재하지 않음
  AUCTION_CLOSED = "AUCTION_CLOSED", // 경매가 종료됨

  //Wallet
  INVALID_WALLET_KLAV_AMOUNT = "INVALID_WALLET_KLAV_AMOUNT", // 비정상적인 금액
}
