import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { putTermsAgree } from 'apis';

const MemberInfoComponent = () => {
    const navigate = useNavigate();

    const now = new Date();

    const [form, setForm] = useState({
        year: now.getFullYear(), // number 형식
        month: 1, // number 형식
        day: 1, // number 형식
    });
    const [isUnderage, setIsUnderage] = useState(false);

    const years = [];
    for (let y = now.getFullYear(); y >= 1930; y -= 1) {
        years.push({ value: y, label: y.toString() }); // label은 string 형식으로 설정
    }

    const months = [];
    for (let m = 1; m <= 12; m += 1) {
        months.push({ value: m, label: m < 10 ? `0${m}` : m.toString() }); // label은 string 형식으로 설정
    }

    const [days, setDays] = useState<{ value: number; label: string }[]>([]);

    useEffect(() => {
        const date = new Date(form.year, form.month, 0).getDate();
        const newDays: { value: number; label: string }[] = [];
        for (let d = 1; d <= date; d += 1) {
            newDays.push({ value: d, label: d < 10 ? `0${d}` : d.toString() }); // label은 string 형식으로 설정
        }
        setDays(newDays);

        if (form.day > date) {
            setForm((prevForm) => ({ ...prevForm, day: 1 })); // day를 1로 설정
        }
    }, [form.year, form.month, form.day]);

    // 나이 계산 함수
    const calculateAge = () => {
        const birthDate = new Date(form.year, form.month - 1, form.day); // month는 0부터 시작
        const age = now.getFullYear() - birthDate.getFullYear();
        const monthDifference = now.getMonth() - birthDate.getMonth();

        // 생일이 지났는지 확인
        if (monthDifference < 0 || (monthDifference === 0 && now.getDate() < birthDate.getDate())) {
            return age - 1;
        }
        return age;
    };

    const age = calculateAge();

    useEffect(() => {
        setIsUnderage(age < 19); // 19세 미만인 경우 true
    }, [age]);

    const handleContinueClick = async () => {
        // todo: 서버로 전송하는 로직 작성
        try {
            const response = await putTermsAgree(
                true,
                `${form.year}-${form.month < 10 ? `0${form.month}` : form.month}-${form.day < 10 ? `0${form.day}` : form.day
                }` // 형식에 맞게 설정
            );
            if (response.data.isSuccess === 'T') {
                localStorage.setItem('terms_agree', 'true');
                navigate('/');
            }
        } catch (error) {
            console.error('약관 동의 실패:', error);
        }
    };

    return (
        <div className="flex flex-col w-full min-w-[470px] min-h-[594px] gap-6 p-10 justify-between bg-white rounded-lg shadow-lg mobile:min-w-[280px]">
            <div className="flex flex-col gap-8">
                <Logo className="w-10 h-10" />
                <div className="text-[22px] font-semibold text-gray-800">
                    생년월일을 입력해주세요.
                </div>
                <div className="flex flex-col gap-4">
                    <span className="text-sm text-achromatic-400 font-medium">생년월일</span>
                    <div className="flex gap-4">
                        {/* 년도 선택 */}
                        <Select
                            options={years}
                            value={years.find((year) => year.value === form.year)}
                            onChange={(selected) => setForm({ ...form, year: selected?.value! })} // year을 number 형식으로 설정
                            className="w-1/3"
                            classNamePrefix="react-select"
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                            styles={{
                                control: (styles) => ({
                                    ...styles,
                                    width: '100%',
                                    height: '3rem',
                                    border: '1px solid #E5E7EB',
                                    borderRadius: '0.5rem',
                                    color: '#374151',
                                    fontSize: '0.875rem',
                                    fontWeight: 600,
                                    lineHeight: 1.25,
                                    transition: 'all 0.2s',
                                    '&:hover': {
                                        borderColor: '#9CA3AF',
                                    },
                                }),
                            }}
                        />

                        {/* 월 선택 */}
                        <Select
                            options={months}
                            value={months.find((month) => month.value === form.month)}
                            onChange={(selected) => setForm({ ...form, month: selected?.value! })} // month을 number 형식으로 설정
                            className="w-1/3 text-sm"
                            classNamePrefix="react-select"
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                            styles={{
                                control: (styles) => ({
                                    ...styles,
                                    width: '100%',
                                    height: '3rem',
                                    border: '1px solid #E5E7EB',
                                    borderRadius: '0.5rem',
                                    color: '#374151',
                                    fontSize: '0.875rem',
                                    fontWeight: 600,
                                    lineHeight: 1.25,
                                    transition: 'all 0.2s',
                                    '&:hover': {
                                        borderColor: '#9CA3AF',
                                    },
                                }),
                            }}
                        />

                        {/* 일 선택 */}
                        <Select
                            options={days}
                            value={days.find((day) => day.value === form.day)}
                            onChange={(selected) => setForm({ ...form, day: selected?.value! })} // day을 number 형식으로 설정
                            className="w-1/3"
                            classNamePrefix="react-select"
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                            styles={{
                                control: (styles) => ({
                                    ...styles,
                                    width: '100%',
                                    height: '3rem',
                                    border: '1px solid #E5E7EB',
                                    borderRadius: '0.5rem',
                                    color: '#374151',
                                    fontSize: '0.875rem',
                                    fontWeight: 600,
                                    lineHeight: 1.25,
                                    transition: 'all 0.2s',
                                    '&:hover': {
                                        borderColor: '#9CA3AF',
                                    },
                                }),
                            }}
                        />
                    </div>
                    {isUnderage && (
                        <div className="text-sm text-[#F04D1A] font-medium">
                            클래빗은 19세 이상만 이용할 수 있습니다.
                        </div>
                    )}
                </div>

            </div>
            <div className='text-sm font-semibold'>
                <Button text='시작하기' disabled={isUnderage} onClick={handleContinueClick} />
            </div>
        </div>
    );
};

export default MemberInfoComponent;