import { SettingHeader, Button } from "components";
import { ReactComponent as BadgePicture } from "assets/images/badge.picture.svg";
import { useRef, useState } from "react";
import { createSupport } from "apis/support.api";
import { useAlertStore } from "store";

interface SmallButtonProps {
  text: string;
  onFileSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileName: string;
}

interface ContactItemProps {
  title: string;
  value: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFileSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileName: string;
}

const SmallButton = ({ text, onFileSelect, fileName }: SmallButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };
  const buttonWidth = fileName ? `${fileName.length * 8 + 10}px` : "71px";
  return (
    <button
      className="flex h-[22px] items-center justify-center"
      onClick={handleButtonClick}
      style={{ width: buttonWidth }}
    >
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={onFileSelect}
        style={{ display: "none" }}
      />
      <div className="flex w-full h-full px-[6px] py-1 rounded-[4px] items-center justify-center bg-achromatic-50">
        <div className="flex items-center gap-1">
          <BadgePicture className="w-[11px] h-[11px]" />
          <span className="font-semibold text-xs text-achromatic-400">
            {fileName || text}
          </span>
        </div>
      </div>
    </button>
  );
};

const ContactItem = ({
  title,
  value,
  onChange,
  onFileSelect,
  fileName,
}: ContactItemProps) => {
  return (
    <div className="flex flex-col gap-2 font-medium w-full">
      <div className="text-sm text-achromatic-400">{title}</div>
      {title === "제목" ? (
        <div className="flex min-h-12 max-h-[212px] border border-achromatic-100 rounded-lg items-center">
          <input
            type="text"
            value={value}
            onChange={onChange}
            className="w-full px-3 rounded-lg font-semibold text-sm focus:outline-none"
          />
        </div>
      ) : (
        <div className="min-h-[212px] border border-achromatic-100 rounded-lg">
          <textarea
            value={value}
            onChange={onChange}
            className="w-full p-3 rounded-lg font-semibold text-sm focus:outline-none resize-none"
            style={{ minHeight: "212px" }}
          />
        </div>
      )}
      {
        title !== "제목" && (
          <SmallButton
            text="첨부파일"
            onFileSelect={onFileSelect}
            fileName={fileName}
          />
        )
      }

    </div>
  );
};

const ContactSettingComponent = () => {
  const showAlert = useAlertStore((state) => state.showAlert);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [titleFile, setTitleFile] = useState<File | null>(null);
  const [contentFile, setContentFile] = useState<File | null>(null);
  const [titleFileName, setTitleFileName] = useState("");
  const [contentFileName, setContentFileName] = useState("");

  const handleSendClick = () => {
    if (title && content) {
      createSupport(title, content, titleFile, contentFile)
        .then((response) => {
          showAlert("문의사항 전송완료", "success");
          clearInputArea();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      showAlert("제목과 내용을 입력하세요", "error");
    }
  };

  const clearInputArea = () => {
    setTitle("");
    setContent("");
    setTitleFile(null);
    setContentFile(null);
    setTitleFileName("");
    setContentFileName("");
  };

  const handleTitleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setContent(e.target.value);
  };

  const handleTitleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setTitleFile(selectedFile);
      setTitleFileName(selectedFile.name);
    }
  };

  const handleContentFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setContentFile(selectedFile);
      setContentFileName(selectedFile.name);
    }
  };

  return (
    <div className="flex flex-col w-full border-achromatic-100 border-r">
      <div>
        <SettingHeader title="고객문의" />
      </div>
      <div className="flex py-6">
        <div className="flex flex-col w-full gap-10 p-4">
          <div className="flex flex-col gap-4 justify-between">
            <ContactItem
              title="제목"
              value={title}
              onChange={handleTitleChange}
              onFileSelect={handleTitleFileSelect}
              fileName={titleFileName}
            />
            <ContactItem
              title="내용"
              value={content}
              onChange={handleContentChange}
              onFileSelect={handleContentFileSelect}
              fileName={contentFileName}
            />
          </div>
          <div className="w-full">
            <Button text="보내기" onClick={handleSendClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSettingComponent;
