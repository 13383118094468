import { useState } from "react";

import { SettingHeader, Button, CheckBox } from "components"
import { useAlertStore } from "store";
import { deleteAccount } from "apis";

const DeleteAccountComponent = () => {
    const { showAlert } = useAlertStore();
    const [checked, setChecked] = useState(false);

    const handleDeleteAccount = async () => {
        if (!checked) {
            showAlert("안내사항을 확인하고 동의해주세요.");
            return;
        }

        const response = await deleteAccount();
        if (response.data.isSuccess === 'T') {
            localStorage.removeItem("user_access_token");
            localStorage.removeItem("terms_agree");
            window.location.href = "/login";
        }
    }

    return (
        <div className="flex flex-col w-full border-achromatic-100 border-r">
            <div>
                <SettingHeader title="회원탈퇴" />
            </div>
            <div className="flex py-6">
                <div className="flex flex-col w-full gap-6 px-6 py-4 text-base font-semibold">
                    <div className="text-achromatic-800">
                        탈퇴하기 전에 확인 해주세요
                    </div>
                    <div className="flex flex-col px-6 py-4 gap-[10px] rounded-xl bg-achromatic-50 text-achromatic-600 leading-[19px]">
                        <ul className="list-none pl-0 flex flex-col gap-[10px]">
                            <li className="relative pl-4 before:content-['•'] before:absolute before:-left-1 before:top-0 before:text-achromatic-600">
                                서비스에서 탈퇴되며 복구가 불가능합니다.
                            </li>
                            <li className="relative pl-4 before:content-['•'] before:absolute before:-left-1 before:top-0 before:text-achromatic-600">
                                단, 일부 정보는 일정기간 보관 후 파기됩니다.
                            </li>
                            <li className="relative pl-4 before:content-['•'] before:absolute before:-left-1 before:top-0 before:text-achromatic-600">
                                자세한 내용은 이용약관 및 개인정보와 연결정보를 확인해주세요.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <CheckBox
                            label="안내사항을 모두 확인하였으며, 이에 동의합니다."
                            checked={checked}
                            onChange={() => { setChecked(!checked) }}
                        />
                    </div>
                    <div className="max-h-[49px]">
                        <Button text="철회 및 탈퇴하기" onClick={handleDeleteAccount} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeleteAccountComponent;