import { BaseModal, Button } from "components";

import { ReactComponent as CoinIcon } from "assets/images/coin.svg";
import { AuctionDTO } from "types";
import { useQuery } from "react-query";
import { getMyWallet } from "apis";
import { useAlertStore, useWalletStore } from "store";
import { useNavigate } from "react-router-dom";

interface PurchaseNowModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  auction: AuctionDTO;
  onPayment: () => void;
}

const PurchaseNowModal = ({
  isOpen,
  onClose,
  title,
  auction,
  onPayment,
}: PurchaseNowModalProps) => {
  const nagivate = useNavigate();
  const showAlert = useAlertStore((state) => state.showAlert);
  const { wallet, setWallet } = useWalletStore();
  const { refetch } = useQuery("wallet", getMyWallet, {
    enabled: false,
    onSuccess: (data) => {
      setWallet(data.data.data);
    },
  });

  const handeChargeClick = () => {
    nagivate("/auction");
  }

  return (
    <BaseModal title={title} isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-[60px] w-[560px] mobile:w-[292px]">
        <div className="flex flex-col text-sm font-semibold pt-8 gap-8">
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              즉시구매가
            </span>
            <span>{auction.immediatePrice} 클랩</span>
          </div>
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              배송비
            </span>
            <span>3 클랩</span>
            {/* 임시 */}
          </div>
          <div className="flex gap-4">
            <span className="w-[120px] font-medium text-achromatic-400">
              결제금액
            </span>
            <span className="text-primary-500">
              {auction.immediatePrice + 3} 클랩
            </span>
          </div>

          <div className="border-b border-achromatic-100" />

          <div className="flex flex-col gap-2">
            <span className="w-[120px] font-medium text-sm text-achromatic-400">
              사용 가능한 클랩
            </span>
            <div className="flex justify-between">
              <div className="flex gap-2 items-end">
                <div className="flex gap-1 text-achromatic-600 font-medium text-base leading-[19px]">
                  <CoinIcon />
                  <span className="text-center">{wallet.klav}클랩</span>
                </div>
                <div className="flex items-center font-medium text-xs text-achromatic-300 leading-[19px]">
                  1 클랩 = 1000 원
                </div>
              </div>
              <div>
                <button
                  className="bg-primary-500 rounded-md text-white font-semibold text-sm py-2 px-6"
                  onClick={handeChargeClick}
                >
                  클랩 충전
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <Button
              text={`${auction.immediatePrice + 3} 클랩 결제하기`}
              onClick={onPayment}
            />
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default PurchaseNowModal;
