import { useEffect } from "react";

import { getPostList, getPostListByUser } from "apis";
import { Loading, Post } from "components";
import { PostDTO } from "types";
import { useUserStore } from "store";
import { useInfiniteQuery } from "react-query";

interface PostWrapperProps {
  searchTerm?: string;
  onSearch?: (term: string) => void;
  localPost?: PostDTO;
  deleteLocalPost?: () => void;
}

const PostWrapper = ({ searchTerm, onSearch, localPost, deleteLocalPost }: PostWrapperProps) => {
  const { user } = useUserStore();
  const isMyPage = window.location.pathname === "/mypage";

  const fetchPosts = ({ pageParam = 1 }: { pageParam?: number }) =>
    isMyPage && user
      ? getPostListByUser(pageParam, 10, user.id)
      : getPostList(pageParam, 10);

  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ["posts", isMyPage ? user?.id : "all"], // Query Key
    fetchPosts, // Fetch function
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalItems = lastPage.data.data.total;
        const itemsPerPage = 10;
        const currentPage = allPages.length;
        const totalPages = Math.ceil(totalItems / itemsPerPage);

        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      enabled: isMyPage ? !!user : true, // 조건부로 실행
    }
  );

  useEffect(() => {
    if (data) {
      deleteLocalPost && deleteLocalPost();
    }
  }, [data])

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasNextPage, isFetchingNextPage]);

  if (isLoading) return <Loading />;
  if (isError) return <div>Error loading posts.</div>;

  const posts = data?.pages.flatMap((page) => page.data.data.items) ?? [];

  const filteredPosts = searchTerm
    ? posts.filter(
      (post) =>
        post.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.postWriter.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.postWriter.profileId
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        post.auction?.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : posts;

  const combinedPosts = [...(localPost ? [localPost] : []), ...filteredPosts];

  return (
    <div className="flex flex-col">
      {combinedPosts.map((post: PostDTO) => (
        <Post key={post.id} post={post} onSearch={onSearch} />
      ))}
      {isFetchingNextPage && <Loading />}
    </div>
  );
};

export default PostWrapper;
