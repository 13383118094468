import { AxiosResponse } from "axios";

import { patch, post } from "utils";
import { ResponseDTO, UserDTO } from "types";

const baseUrl = `${process.env.REACT_APP_API_URL}/user`;

export const putTermsAgree = async (
  agree: boolean,
  birth: string
): Promise<AxiosResponse<ResponseDTO<boolean>>> => {
  let url = `${baseUrl}/terms-agree`;
  const body = {
    agree,
    birth,
  };

  return post(url, body);
};

export const getProfile = async (): Promise<
  AxiosResponse<ResponseDTO<UserDTO>>
> => {
  let url = `${baseUrl}/profile`;

  return post(url);
};

export const getUserProfile = async (
  profileId: string
): Promise<AxiosResponse<ResponseDTO<UserDTO>>> => {
  let url = `${baseUrl}/user-profile`;
  const body = {
    profileId,
  };

  return post(url, body);
};

export const uploadProfileImage = async (
  file: File
): Promise<AxiosResponse<ResponseDTO<string>>> => {
  let url = `${baseUrl}/upload-profile-image`;
  const formData = new FormData();
  formData.append("file", file);

  return post(url, formData);
};

export const uploadBannerImage = async (
  file: File
): Promise<AxiosResponse<ResponseDTO<string>>> => {
  let url = `${baseUrl}/upload-banner-image`;
  const formData = new FormData();
  formData.append("file", file);

  return post(url, formData);
};

export const updateProfile = async (
  profileId?: string,
  name?: string,
  birth?: string,
  address?: string,
  phone?: string,
  profileImage?: File | null,
  bannerImage?: File | null
): Promise<AxiosResponse<ResponseDTO<UserDTO>>> => {
  let url = `${baseUrl}/update`;

  const formData = new FormData();

  if (profileId) {
    formData.append("profileId", profileId);
  }
  if (name) {
    formData.append("name", name);
  }
  if (birth) {
    formData.append("birth", birth);
  }
  if (address) {
    formData.append("address", address);
  }
  if (phone) {
    formData.append("phone", phone);
  }

  if (profileImage) {
    formData.append("profileImage", profileImage);
  }
  if (bannerImage) {
    formData.append("bannerImage", bannerImage);
  }

  return patch(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteAccount = async (): Promise<
  AxiosResponse<ResponseDTO<boolean>>
> => {
  let url = `${baseUrl}/delete`;

  return post(url);
};
