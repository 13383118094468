import { ReactComponent as FullLogo } from 'assets/images/full.logo.svg';
import { ReactComponent as GoogleLogo } from 'assets/images/google.login.svg';
import { ReactComponent as KakaoLogo } from 'assets/images/kakao.login.svg';
import { ReactComponent as NaverLogo } from 'assets/images/naver.login.svg';
import { ReactComponent as LineLogo } from 'assets/images/line.login.svg';
import { Link, useNavigate } from 'react-router-dom';

const LoginComponent = () => {
    const navigate = useNavigate();

    const privactPolicyUrl = "https://docs.google.com/document/d/e/2PACX-1vRVkLz72D_-QaezBHadnMa9uwXme4sX7qWVaKAFjV3gCP1clYy6oOIYZ4IpjF8odPYGMotLjZP8m6R9/pub";
    const termsOfServiceUrl = 'https://docs.google.com/document/d/e/2PACX-1vRDH7v6Lkz8gO6S-mYEoN8zSCQA-4IM4ChKhr3BftQ1FsJvF5Op_iPbominJwBxlOuaVAr0VopxZpdU/pub';

    const handleGoogleLogin = () => {
        const googleLoginUrl = `${process.env.REACT_APP_API_URL}/auth/user/login/google`;
        window.open(googleLoginUrl, '_self');
    }

    const handleKakaoLogin = () => {
        const kakaoLoginUrl = `${process.env.REACT_APP_API_URL}/auth/user/login/kakao`;
        window.open(kakaoLoginUrl, '_self');
    }

    const handleNaverLogin = () => {
        const naverLoginUrl = `${process.env.REACT_APP_API_URL}/auth/user/login/naver`;
        window.open(naverLoginUrl, '_self');
    }

    const handleLineLogin = () => {
        const lineLoginUrl = `${process.env.REACT_APP_API_URL}/auth/user/login/line`;
        window.open(lineLoginUrl, '_self');
    }

    return (
        <div className="flex flex-col gap-6 items-center text-center p-16 mobile:p-6">
            <div className="flex flex-col gap-6 items-center text-center">
                <FullLogo />
                <p className="font-medium text-sm text-achromatic-500">크리에이터의 소중한 것을 소유해 보세요</p>
            </div>
            <div className="flex flex-col gap-4">
                {/* 소셜 로그인 버튼 4개 */}
                <div className="flex flex-col gap-4">
                    <button
                        className="flex items-center justify-center w-full h-12 bg-[#FEE500] rounded-lg gap-[6px]"
                        onClick={handleKakaoLogin}
                    >
                        <KakaoLogo />
                        <span className="text-black font-medium">카카오톡으로 시작하기</span>
                    </button>
                    <button
                        className="flex items-center justify-center w-full h-12 border border-achromatic-100 rounded-lg gap-[6px]"
                        onClick={handleGoogleLogin}
                    >
                        <GoogleLogo />
                        <span className="text-black font-medium">구글로 시작하기</span>
                    </button>
                    <button
                        className="flex items-center justify-center w-full h-12 bg-primary-500 rounded-lg gap-[6px]"
                        onClick={handleNaverLogin}
                    >
                        <NaverLogo />
                        <span className="text-white font-medium">네이버로 시작하기</span>
                    </button>
                    <button
                        className="flex items-center justify-center w-full h-12 bg-primary-600 rounded-lg gap-[6px]"
                        onClick={handleLineLogin}
                    >
                        <LineLogo />
                        <span className="text-white font-medium">라인으로 시작하기</span>
                    </button>
                    <div className="text-xs text-center font-medium text-achromatic-400">
                        <p>
                            회원 가입을 진행하여 계정을 만드는 것은 Klavit의

                        </p>
                        <p>
                            <Link to={termsOfServiceUrl} target="_blank" className="font-bold border-b border-achromatic-500">이용 약관</Link> 및 <Link to={privactPolicyUrl} target="_blank" className='font-bold border-b border-achromatic-500'>개인정보 처리방침</Link>에 동의하는 것으로 간주됩니다.
                        </p>
                    </div>

                </div>

                <div
                    className="text-center text-sm font-medium text-achromatic-500 cursor-pointer"
                    onClick={() => navigate('/guest')}
                >
                    둘러보기
                </div>
            </div>
        </div>
    );
}

export default LoginComponent;