import { NotificationType } from "types";
import { ReactComponent as MessageIcon } from "assets/images/message.svg";
import { ReactComponent as AlertIcon } from "assets/images/alert.svg";
import { ReactComponent as CardIcon } from "assets/images/card.svg";
import { ReactComponent as TransferIcon } from "assets/images/transfer.svg";

const NotificationIcons = {
  [NotificationType.COMMON]: MessageIcon,
  [NotificationType.ALERT]: AlertIcon,
  [NotificationType.NOTICE]: null,
  [NotificationType.EVENT]: null,
  [NotificationType.PAYMENT]: CardIcon,
  [NotificationType.SETTLEMENT]: TransferIcon,
};

export default NotificationIcons;
