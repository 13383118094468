import React, { useEffect, useRef, useState, forwardRef } from 'react';

interface AdjustInputProps {
    inputValue: string;
    setInputValue: (value: string) => void;
    isError: boolean;
}

const AdjustInput = forwardRef<HTMLInputElement, AdjustInputProps>(({ inputValue, setInputValue, isError }, ref) => {
    const [width, setWidth] = useState<number>(0);
    const mirrorRef = useRef<HTMLDivElement>(null);

    // 입력 값과 변환된 값을 계산
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/\D/g, ''); // 숫자 이외의 값 제거

        if (/^0+$/.test(value)) {
            value = '0';
        }

        if (value.length > 1) {
            value = value.replace(/^0+/, '');
        }

        // 최대값 체크
        if (Number(value) > 10000000 && isError) {
            // 값을 업데이트하지 않고 그대로
            return;
        } else {
            setInputValue(value);
        }
    };

    useEffect(() => {
        if (mirrorRef.current) {
            setWidth(mirrorRef.current.clientWidth);
        }
    }, [inputValue]);

    return (
        <div className='flex items-center ml-3 mr-3'
            style={{ width: `${Math.max(width, 9) + 2}px`, }} // 값에 따라 유동적으로 커짐
        >
            <input
                type="text"
                ref={ref}
                value={inputValue}
                onChange={handleInputChange}
                className='placeholder-achromatic-200 focus:outline-none mobile:max-w-28'
                placeholder='0'
                style={{
                    width: `${Math.max(width, 9) + 2}px`,
                    overflow: 'auto',
                }} // 값에 따라 유동적으로 커짐
            />
            <div className='absolute inline-block opacity-0 h-0' ref={mirrorRef}>
                {inputValue}
            </div>
        </div>
    );
});

export default AdjustInput;