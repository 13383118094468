import "./App.css";
import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { useGoogleAnalytics } from "utils";

import {
  Home,
  Login,
  MyPage,
  Auction,
  Notification,
  Setting,
  Post,
  Profile,
  Guest,
  Admin,
  Account,
} from "pages";
import { BaseLayout } from "layouts";
import {
  MyPageSettingComponent,
  FAQComponent,
  ContactComponent,
  LogOutComponent,
  DeleteAccountComponent,
  MyPageComponent,
  EditProfileComponent,
  CompleteComponent,
  FAQManageComponent,
  AdminLoginComponent,
  ReportManageComponent,
  CreatorManageComponent,
  DeliveryManageComponent,
  WithdrawManageComponent,
  DashboardComponent,
  UserManageComponent,
  AccountWithdrawn,
  AccountBlock,
} from "components";
import { useUserStore, useWalletStore } from "store";
import { getCreatorInfo, getMyWallet, getProfile } from "apis";

function App() {
  const navigate = useNavigate();
  const { setUser, setCreator } = useUserStore();
  const { setWallet } = useWalletStore();

  useGoogleAnalytics();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const termsAgree = queryParams.get("termsAgree");

    if (window.location.pathname.startsWith("/guest") || window.location.pathname.startsWith("/account")) {
      return; // guest 페이지에서는 검사 생략
    }

    if (window.location.pathname.startsWith("/admin")) {
      if (token) {
        localStorage.setItem("admin_access_token", token);
      }

      if (!localStorage.getItem("admin_access_token")) {
        navigate("/admin/login");
      }
    } else {
      if (token) {
        localStorage.setItem("user_access_token", token);
        if (termsAgree === "true") {
          localStorage.setItem("terms_agree", termsAgree);
        }

        navigate("/");
      }

      if (!localStorage.getItem("user_access_token")) {
        navigate("/login");
      }

      if (!localStorage.getItem("terms_agree")) {
        navigate("/login");
      }

      if (
        localStorage.getItem("user_access_token") &&
        localStorage.getItem("terms_agree")
      ) {
        getProfile().then((response) => {
          setUser(response.data.data);
          if (response.data.data.role === "creator") {
            getCreatorInfo().then((response) => {
              setCreator(response.data.data);
            });
          }
          getMyWallet().then((response) => {
            setWallet(response.data.data);
          });
        });
      }
    }

  }, [navigate]);

  return (
    <BaseLayout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/guest" element={<Guest />} />
        <Route path="/:profileId" element={<Profile />} />
        <Route path="/login" element={<Login />} />
        <Route path="/account" element={<Account />}>
          <Route path="withdrawn" element={<AccountWithdrawn />} />
          <Route path="blocked" element={<AccountBlock />} />
        </Route>
        <Route path="/mypage" element={<MyPage />}>
          <Route path="" element={<MyPageComponent />} />
          <Route path="edit-profile" element={<EditProfileComponent />} />
          {/* <Route path=""/> */}
        </Route>
        <Route path="/notification" element={<Notification />} />
        <Route path="/auction" element={<Auction />} />

        {/* <Route path="" element={<AuctionComponent />} /> */}
        {/* <Route path="/charge/:status" element={<ChargeComponent />} /> */}
        <Route path="complete" element={<CompleteComponent />} />

        {/* <Route path="/wallet" element={<Wallet />} /> */}
        <Route path="/setting" element={<Setting />}>
          <Route path="mypage" element={<MyPageSettingComponent />} />
          <Route path="faq" element={<FAQComponent />} />
          <Route path="contact" element={<ContactComponent />} />
          <Route path="logout" element={<LogOutComponent />} />
          <Route path="delete-account" element={<DeleteAccountComponent />} />
        </Route>
        <Route path="/post/:id" element={<Post />} />

        <Route path="/admin" element={<Admin />} >
          <Route path="" element={<DashboardComponent />} />
          <Route path="login" element={<AdminLoginComponent />} />
          <Route path="user" element={<UserManageComponent />} />
          <Route path="faq" element={<FAQManageComponent />} />
          <Route path="report" element={<ReportManageComponent />} />
          <Route path="creator" element={<CreatorManageComponent />} />
          <Route path="delivery" element={<DeliveryManageComponent />} />
          <Route path="withdraw" element={<WithdrawManageComponent />} />
        </Route>
      </Routes>
    </BaseLayout >
  );
}

export default App;
