import { create } from "zustand";

interface AlertState {
  message: string;
  type?: "success" | "error";
  visible: boolean;
  showAlert: (message: string, type?: "success" | "error") => void;
  hideAlert: () => void;
}

export const useAlertStore = create<AlertState>((set) => ({
  message: "",
  type: "success",
  visible: false,
  showAlert: (message, type = "success") =>
    set({ message, type, visible: true }),
  hideAlert: () => set({ visible: false }),
}));
