import { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { ERROR } from "types";
import { axiosService } from "utils";

type errorResponse = {
  error: string;
  message: string;
  statusCode: number;
};

export const onRequest = (config: InternalAxiosRequestConfig) => {
  const token = window.location.pathname.startsWith("/admin")
    ? localStorage.getItem("admin_access_token")
    : localStorage.getItem("user_access_token");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
};

export const onErrorRequest = (error: Error) => {
  return Promise.reject(error);
};

export const onResponse = (response: AxiosResponse) => response;

export const onErrorResponse = async (error: AxiosError) => {
  const axiosInstance = axiosService.getAxiosInstance();

  // Refresh URL인지 확인하여 무한 루프 방지
  if (
    error.response?.status === 401 &&
    error.config &&
    error.config.url !== "/auth/user/refresh"
  ) {
    const errorData = error.response?.data as errorResponse;
    if (errorData.message === ERROR.AUTHORIZATION) {
      alert("세션이 만료되었습니다. 다시 로그인해주세요.");
      localStorage.removeItem("user_access_token");
      localStorage.removeItem("admin_access_token");
      localStorage.removeItem("terms_agree");
      if (window.location.pathname.startsWith("/admin")) {
        window.location.replace("/admin/login");
      } else {
        window.location.replace("/login");
      }
      return;
    } else if (errorData.message === ERROR.WRONG_PASSWORD) {
      alert("비밀번호가 일치하지 않습니다.");
      localStorage.removeItem("user_access_token");
      localStorage.removeItem("admin_access_token");
      localStorage.removeItem("terms_agree");
      return;
    }
    // alert("토큰이 만료되었습니다. 401에러");

    try {
      // 토큰 갱신 시도
      const refreshTokenResponse = await axiosInstance.post(
        "/auth/user/refresh",
        {},
        { withCredentials: true }
      );

      if (refreshTokenResponse.data.ok) {
        if (window.location.pathname.startsWith("/admin")) {
          localStorage.setItem(
            "admin_access_token",
            refreshTokenResponse.data.access_token
          );
        } else {
          // 토큰 갱신 성공
          localStorage.setItem(
            "user_access_token",
            refreshTokenResponse.data.access_token
          );
        }

        // 실패한 요청을 재시도
        return axiosInstance.request(error.config);
      }
    } catch (refreshError) {
      console.error("토큰 갱신 실패:", refreshError);
      alert("로그인이 만료되었습니다. 다시 로그인 해주세요.");
      localStorage.removeItem("user_access_token");
      localStorage.removeItem("admin_access_token");
      localStorage.removeItem("terms_agree");
      if (window.location.pathname.startsWith("/admin")) {
        window.location.replace("/admin/login");
      } else {
        window.location.replace("/login");
      }
      return;
    }
  } else if (error.response?.status === 403) {
    alert("문제가 발생했습니다. 다시 로그인 해주세요.");
    localStorage.removeItem("user_access_token");
    localStorage.removeItem("admin_access_token");
    localStorage.removeItem("terms_agree");

    window.location.replace("/login");

    return;
  }

  return Promise.reject(error);
};

const axiosInterceptor = {
  onRequest,
  onErrorRequest,
  onResponse,
  onErrorResponse,
};

export default axiosInterceptor;
