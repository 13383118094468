import { RightSideBar, UserProfileComponent } from "components"
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function Profile() {
    const { profileId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (profileId) {
            if (!profileId.startsWith("@")) {
                navigate("/"); // 에러 페이지로 리다이렉트하거나 다른 처리를 수행
                return;
            }
        }
    }, [profileId, navigate]);


    return (
        <div className="flex w-full">
            <div className="flex max-w-[680px] w-full flex-col border-achromatic-100 border-x">
                <UserProfileComponent profileId={profileId?.slice(1)!} />
            </div>
            <div className="max-w-[360px] w-full mobile:hidden">
                <RightSideBar />
            </div>
        </div>
    )
}