import { SettingHeader } from "components";

import { faqList } from "data"

interface FAQItemProps {
    question: string;
    answer: string;
}

const FAQItem = ({ question, answer }: FAQItemProps) => {
    return (
        <div className="flex flex-col gap-2 py-4 px-6">
            <div className="font-semibold text-base text-achromatic-800">
                {question}
            </div>
            <div className="font-medium text-sm text-achromatic-500">
                {answer}
            </div>
        </div>
    )
}

const FAQSettingComponent = () => {
    return (
        <div className="flex flex-col w-full border-achromatic-100 border-r">
            <div>
                <SettingHeader title="FAQ" />
            </div>
            <div className="flex flex-col py-6">
                {faqList.map((faq, index) => (
                    <FAQItem
                        key={index}
                        question={faq.question}
                        answer={faq.answer}
                    />
                ))
                }
            </div>
        </div>
    );
}

export default FAQSettingComponent;