import { AxiosResponse } from "axios";
import { NotificationDTO, ResponseDTO } from "types";
import { get, patch } from "utils";

const baseUrl = `${process.env.REACT_APP_API_URL}/notification`;

export const getNotifications = async (): Promise<
  AxiosResponse<ResponseDTO<NotificationDTO[]>>
> => {
  let url = `${baseUrl}`;

  return get(url);
};

export const getUnreadNotificationCount = async (): Promise<
  AxiosResponse<ResponseDTO<number>>
> => {
  let url = `${baseUrl}/unread-count`;

  return get(url);
};

export const markAsReadAllNotifications = async (): Promise<
  AxiosResponse<ResponseDTO<string>>
> => {
  let url = `${baseUrl}/make-as-read`;

  return patch(url);
};
