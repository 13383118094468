import React, { useEffect } from 'react';

type MobileTabHeaderProps = {
    activeTab: "auction" | "wallet";
    setActiveTab: (tab: "auction" | "wallet") => void;
};


const MobileTabHeader = ({ activeTab, setActiveTab }: MobileTabHeaderProps) => {
    return (
        <div className="h-[51px] border-b border-achromatic-100 gap-4 px-4 text-base font-semibold hidden mobile:flex">
            <div
                className={`flex flex-col justify-center h-full transition-colors delay-50 cursor-pointer border-b-2 px-3 ${activeTab === "auction" ? " border-green-500" : "text-achromatic-200 border-white"
                    }`}
                onClick={() => setActiveTab("auction")}
            >
                경매
            </div>
            <div
                className={`flex flex-col justify-center h-full transition-colors delay-50 cursor-pointer border-b-2 px-3 ${activeTab === "wallet" ? "border-b-2 border-green-500" : "text-achromatic-200 border-white"
                    }`}
                onClick={() => setActiveTab("wallet")}
            >
                지갑
            </div>
        </div>
    )
}

export default MobileTabHeader;