import { cancelBidAuction, getMyWallet, updateWallet } from "apis";
import { BaseModal } from "components";
import { useQuery } from "react-query";
import { useAlertStore, useWalletStore } from "store";
import { AuctionDTO } from "types";

interface CancleBidModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  auction: AuctionDTO;
}

const CancleBidModal = ({
  isOpen,
  onClose,
  title,
  auction,
}: CancleBidModalProps) => {
  const showAlert = useAlertStore((state) => state.showAlert);
  const { wallet, setWallet } = useWalletStore();
  const { refetch } = useQuery("wallet", getMyWallet, {
    enabled: false,
    onSuccess: (data) => {
      setWallet(data.data.data);
    },
  });

  const calculateFees = (price: number) => {
    if (price >= 10 && price <= 49) {
      return { deposit: 5, creator: 2, company: 1, winner: 2 };
    } else if (price >= 50 && price <= 99) {
      return { deposit: 10, creator: 3, company: 3, winner: 4 };
    } else if (price >= 100 && price <= 499) {
      return { deposit: 20, creator: 6, company: 6, winner: 8 };
    } else if (price >= 500) {
      return { deposit: 50, creator: 15, company: 15, winner: 20 };
    } else {
      throw new Error("최소 즉시구매가는 10 klav 이상이어야 합니다.");
    }
  }

  const handleCancelClick = () => {
    cancelBidAuction(auction.id)
      .then((response) => {
        updateWallet(
          calculateFees(auction.immediatePrice).deposit,
          "cancle"
        )
          .then((response) => {
            showAlert("입찰 취소 되었습니다", "success");
            refetch();
            onClose();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <BaseModal title={title} isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-[60px] w-[480px] mobile:w-[292px]">
        <div className="flex flex-col text-sm font-medium pt-8 gap-8">
          <div className="flex flex-col gap-2 px-6 py-4 bg-achromatic-50 rounded-xl">
            <span className="font-semibold text-base text-achromatic-600">
              [입찰 보증금 규정]
            </span>
            <span className="text-achromatic-500">
              입찰 보증금은 지갑으로 환급됩니다.
            </span>
          </div>
          <button className="flex w-full h-full items-center justify-center">
            <button
              className={`flex w-full h-full rounded-lg items-center justify-center transition duration-150 ease-in-out bg-[#F04D1A] bg-opacity-[8%] hover:bg-opacity-[16%]`}
              onClick={handleCancelClick}
            >
              <span className={`py-4 font-semibold text-sm text-[#F04D1A]`}>
                입찰취소하기
              </span>
            </button>
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default CancleBidModal;
