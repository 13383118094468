import {
    RightSideBar,
    SearchBar,
    AutoScroll,
    GuestPostEditor,
    GuestPostWrapper,
    GuestRightSideBar,
    GuestLoginButton,
} from "components";
import { useState } from "react";

export default function Page() {
    const [searchTerm, setSearchTerm] = useState<string>(""); // 검색어 상태

    const handleSearch = (searchText: string) => {
        setSearchTerm(searchText);
    };

    return (
        <div className="non-login flex w-full mobile:pb-[56px]">
            <div className="flex max-w-[680px] w-full flex-col border-achromatic-100 border-x">
                <SearchBar onSearch={handleSearch} value={searchTerm} />
                <GuestPostEditor />
                <GuestPostWrapper searchTerm={searchTerm} onSearch={handleSearch} />
                <AutoScroll />
            </div>
            <div className="max-w-[360px] w-full mobile:hidden">
                <GuestLoginButton />
                <GuestRightSideBar />
            </div>
        </div>
    );
}
