import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { createPaymentIntent } from "apis";
import { loadStripe } from "@stripe/stripe-js";
import { CheckoutForm, CompleteComponent } from "components";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe('pk_live_51QOxSSGfLOVBTe7sQwwwUMnTn3JjIuYTairxGVzB4pn7DvAJwZQ8iFFiBiiHKW2qZTfuqW48aknMK0EuvqDfl1w7006E7PPzEP')

const ChargeComponent = () => {
    const params = useParams();
    const [clientSecret, setClientSecret] = useState("");

    const paymentStatus = params.status;

    const getClientSecret = async () => {
        const response = await createPaymentIntent(1000);

        const { client_secret } = response.data.data;

        if (!client_secret) {
            console.error("client_secret이 없습니다.");
            return;
        }
        // setDpmCheckerLink(response.data.data.);
        setClientSecret(client_secret);
    }

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        getClientSecret();
    }, []);

    return (
        <div className="flex flex-col justify-center">
            {clientSecret && (
                <Elements options={{ clientSecret }} stripe={stripePromise}>
                    <div className="flex flex-col gap-[60px]">
                        <div className="flex flex-col text-sm font-semibold pt-8 gap-8">
                            <div className="flex gap-4">
                                {paymentStatus === 'checkout' ? (
                                    <CheckoutForm></CheckoutForm>
                                ) : (
                                    <CompleteComponent></CompleteComponent>
                                )}
                                {/* <CheckoutForm></CheckoutForm>
                                <CompleteComponent></CompleteComponent> */}
                            </div>
                        </div>
                    </div>
                </Elements>
            )}
        </div>
    )
}

export default ChargeComponent;