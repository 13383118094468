import { AxiosResponse } from "axios";
import {
  AuctionDeliveryDTO,
  AuctionDeliveryManageDTO,
  AuctionDTO,
  CreatorApplicationStatus,
  CreatorDTO,
  DashboardDTO,
  PostReportDTO,
  ResponseDTO,
  SupportDTO,
  UserDTO,
  WalletWithdrawDTO,
  WalletWithdrawStatus,
} from "types";
import { UserManageDTO } from "types/DTOs/user/user_manage.dto";
import { get, patch } from "utils";

const baseUrl = `${process.env.REACT_APP_API_URL}/admin`;

export const getDashboardData = async (): Promise<
  AxiosResponse<ResponseDTO<DashboardDTO>>
> => {
  let url = `${baseUrl}/dashboard`;

  return get(url);
};

export const getPopularAuctionList = async (): Promise<
  AxiosResponse<ResponseDTO<AuctionDTO[]>>
> => {
  let url = `${baseUrl}/popular`;

  return get(url);
};

export const getUserList = async (
  page: number,
  size: number
): Promise<
  AxiosResponse<ResponseDTO<{ items: UserManageDTO[]; total: number }>>
> => {
  let url = `${baseUrl}/user-list/?page=${page}&limit=${size}`;

  return get(url);
};

export const putBlockUser = async (
  userId: string
): Promise<AxiosResponse<ResponseDTO<UserDTO>>> => {
  let url = `${baseUrl}/block-user/?userId=${userId}`;

  return patch(url);
};

export const putUnblockUser = async (
  userId: string
): Promise<AxiosResponse<ResponseDTO<UserDTO>>> => {
  let url = `${baseUrl}/unblock-user/?userId=${userId}`;

  return patch(url);
};

export const putDeleteUser = async (
  userId: string
): Promise<AxiosResponse<ResponseDTO<UserDTO>>> => {
  let url = `${baseUrl}/delete-user/?userId=${userId}`;

  return patch(url);
};

export const getFAQList = async (
  page: number,
  size: number
): Promise<
  AxiosResponse<ResponseDTO<{ items: SupportDTO[]; total: number }>>
> => {
  let url = `${baseUrl}/faq-list/?page=${page}&limit=${size}`;

  return get(url);
};

export const getCreatorList = async (
  page: number,
  size: number
): Promise<
  AxiosResponse<ResponseDTO<{ items: CreatorDTO[]; total: number }>>
> => {
  let url = `${baseUrl}/creator-list/?page=${page}&limit=${size}`;

  return get(url);
};

export const getReportPostList = async (
  page: number,
  size: number
): Promise<
  AxiosResponse<ResponseDTO<{ items: PostReportDTO[]; total: number }>>
> => {
  let url = `${baseUrl}/report-post-list/?page=${page}&limit=${size}`;

  return get(url);
};

export const getWithrawList = async (
  page: number,
  size: number
): Promise<
  AxiosResponse<ResponseDTO<{ items: WalletWithdrawDTO[]; total: number }>>
> => {
  let url = `${baseUrl}/withdraw-list/?page=${page}&limit=${size}`;

  return get(url);
};

export const getDeliveringAuctionList = async (
  page: number,
  size: number
): Promise<
  AxiosResponse<
    ResponseDTO<{ items: AuctionDeliveryManageDTO[]; total: number }>
  >
> => {
  let url = `${baseUrl}/delivery-auction-list/?page=${page}&limit=${size}`;

  return get(url);
};

// FAQ 처리 완료 api
export const putCompleteFAQ = async (
  supportId: string
): Promise<AxiosResponse<ResponseDTO<SupportDTO>>> => {
  let url = `${baseUrl}/faq-response-complete/?supportId=${supportId}`;

  return patch(url);
};

// 크리에이터 승인 api
export const putApproveCreator = async (
  creatorId: string,
  status: CreatorApplicationStatus
): Promise<AxiosResponse<ResponseDTO<CreatorDTO>>> => {
  let url = `${baseUrl}/approve-creator/?creatorId=${creatorId}`;

  const body = {
    status,
  };

  return patch(url, body);
};

// 신고 게시물 삭제 api
export const deleteReportPost = async (
  reportId: string
): Promise<AxiosResponse<ResponseDTO<PostReportDTO>>> => {
  let url = `${baseUrl}/report-post/?reportId=${reportId}`;

  return patch(url);
};

// 출금 승인 api
export const putApproveWithdraw = async (
  withdrawId: string,
  status: WalletWithdrawStatus
): Promise<AxiosResponse<ResponseDTO<WalletWithdrawDTO>>> => {
  let url = `${baseUrl}/approve-withdraw/?withdrawId=${withdrawId}`;

  const body = {
    status,
  };

  return patch(url, body);
};

export const requestDelivery = async (
  auctionId: string
): Promise<AxiosResponse<ResponseDTO<AuctionDeliveryDTO>>> => {
  let url = `${baseUrl}/request-delivery/?auctionId=${auctionId}`;

  return patch(url);
};

export const putArriveDelivery = async (
  auctionId: string
): Promise<AxiosResponse<ResponseDTO<AuctionDeliveryDTO>>> => {
  let url = `${baseUrl}/arrive-delivery/?auctionId=${auctionId}`;

  return patch(url);
};

export const putCompleteInspection = async (
  auctionId: string
): Promise<AxiosResponse<ResponseDTO<AuctionDeliveryDTO>>> => {
  let url = `${baseUrl}/complete-inspection/?auctionId=${auctionId}`;

  return patch(url);
};

export const putCompleteDelivery = async (
  auctionId: string
): Promise<AxiosResponse<ResponseDTO<AuctionDeliveryDTO>>> => {
  let url = `${baseUrl}/complete-delivery/?auctionId=${auctionId}`;

  return patch(url);
};
