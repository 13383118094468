import { AuctionDTO, BidStatusDTO, PostDTO, UserDTO } from "types";

import { ReactComponent as EmptyHeartIcon } from "assets/images/empty.heart.svg";
import { ReactComponent as FillHeartIcon } from "assets/images/fill.heart.svg";
import { ReactComponent as ExportIcon } from "assets/images/export.svg";
import {
  AuctionBidModal,
  AuctionBidReviewModal,
  AuctionDetailComponent,
  AuctionNoticeModal,
  CancleBidModal,
  ConfirmPaymentModal,
  Loading,
  PurchaseNowModal,
} from "components";
import { getPostLike, getPostLikeCount, patchPostLike } from "apis";
import { useEffect, useState } from "react";
import { AuctionStatus, BidStatus } from "types/types";
import { useAlertStore, useUserStore, useWalletStore } from "store";

interface PostDetailProps {
  post?: PostDTO;
  like?: boolean;
  bidExistence?: BidStatusDTO;
}

interface PostHeaderProps {
  user: UserDTO;
}

interface AuctionControlProps {
  auction: AuctionDTO;
  bidExistence?: BidStatusDTO;
  isMine?: boolean;
}

const PostHeader = ({ user }: PostHeaderProps) => {
  return (
    <div className="flex items-center space-x-2">
      {user.profileImage ? (
        <img
          src={`${process.env.REACT_APP_API_URL}/${user.profileImage}`}
          alt="profile"
          className="w-8 h-8 rounded-full object-cover object-center"
        />
      ) : (
        <div className="w-8 h-8 bg-achromatic-100 rounded-full"></div>
      )}
      <div className="flex flex-col">
        <div className="text-black leading-[14px] font-semibold">
          {user.name}
        </div>
        <div className="text-achromatic-300 leading-[14px]">
          @{user.profileId}
        </div>
      </div>
    </div>
  );
};

const PostFooter = ({ post, like }: PostDetailProps) => {
  const [isLike, setLike] = useState<boolean>(like!);
  const [likeCount, setLikeCount] = useState<number>(post!.postLikes);
  const [animate, setAnimate] = useState<boolean>(false); // 애니메이션 상태 추가
  const showAlert = useAlertStore((state) => state.showAlert);
  const { user } = useUserStore();

  const isMine = post!.postWriter.id === user.id;

  const handleClickPostLike = async (postId: string) => {
    if (window.location.pathname.startsWith('/admin')) return;

    const newLikeState = !isLike; // 토글 상태
    setLike(newLikeState);
    setAnimate(true); // 애니메이션 시작

    setTimeout(() => {
      setAnimate(false); // 애니메이션 초기화
    }, 150); // 애니메이션 지속 시간 (300ms)

    try {
      const response = await patchPostLike(postId);
      if (response.data.isSuccess !== "T") {
        throw new Error("Failed to update like");
      }
      // 최신 좋아요 수 서버에서 가져오기
      const countResponse = await getPostLikeCount(postId);
      setLikeCount(countResponse.data.data);
    } catch (error) {
      console.error("좋아요 업데이트 중 오류 발생:", error);
      showAlert("좋아요를 업데이트하는 데 실패했습니다.", "error");

      // 실패 시 상태 복구
      setLike(!newLikeState);
      setAnimate(false);
    }
  };

  const handleExportClick = () => {
    if (window.location.pathname.startsWith('/admin')) return;

    navigator.clipboard.writeText(window.location.href + "post/" + post!.id);
    showAlert("클립보드에 복사되었습니다.", 'success');
  }

  // 초기 좋아요 상태 가져오기
  const fetchInitialLikeState = async () => {
    try {
      const response = await getPostLike(post!.id);
      if (response.data.isSuccess === "T") {
        setLike(response.data.data);
      }
    } catch (error) {
      console.error("초기 좋아요 상태를 가져오는 데 실패했습니다.", error);
    }
  };

  useEffect(() => {
    fetchInitialLikeState();
  }, [post]);

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center space-x-2">
        <div
          className="text-achromatic-300 cursor-pointer"
          onClick={() => handleClickPostLike(post!.id)}
        >
          {isLike ? (
            <div
              className={`flex items-center gap-1 transform transition-transform duration-300 ease-in-out ${animate ? "scale-110" : ""
                }`}
            >
              <FillHeartIcon className="w-6 h-6" />
              <span className="text-[#F04D1A] font-semibold text-sm">
                좋아요 {likeCount}
              </span>
            </div>
          ) : (
            <div
              className={`flex items-center gap-1 transform transition-transform duration-300 ease-in-out ${animate ? "scale-90" : ""
                }`}
            >
              <EmptyHeartIcon className="w-6 h-6" />
              <span className="text-achromatic-300 font-semibold text-sm">
                좋아요 {likeCount}
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        className="text-achromatic-300 cursor-pointer"
        onClick={handleExportClick}
      >
        <ExportIcon className="w-6 h-6" />
      </div>
    </div>
  );
};

const AuctionControlComponent = ({
  auction,
  bidExistence,
  isMine,
}: AuctionControlProps) => {
  const showAlert = useAlertStore((state) => state.showAlert);
  const { wallet } = useWalletStore();
  const [bidPrice, setBidPrice] = useState<string>("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAuctionNoticeModalOpen, setIsAuctionNoticeModalOpen] =
    useState(false);
  const [isAuctionBidModalOpen, setIsAuctionBidModalOpen] = useState(false);
  const [isAuctionBidReviewModalOpen, setIsAuctionBidReviewModalOpen] =
    useState(false);
  const [isPruchaseNowModalOpen, setIsPruchaseNowModalOpen] = useState(false);
  const [isCancleBidModalOpen, setIsCancleBidModalOpen] = useState(false);
  const [isConfirmPaymentModalOpen, setIsConfirmPaymentModalOpen] = useState(
    false
  );

  const [canCancel, setCanCancel] = useState(true);

  // useEffect(() => {
  //   const now = new Date().getTime();

  //   if (bidExistence?.bidTime) {
  //     const bidTime = new Date(bidExistence.bidTime).getTime(); // ← 문자열을 Date로 변환
  //     const isExpired = bidTime + 60 * 60 * 1000 < now;
  //     setCanCancel(!isExpired);
  //   } else if (auction.createdAt && auction.period + new Date(auction.createdAt).getTime() + 1 > now) {
  //     setCanCancel(false);
  //   }

  // }, [bidExistence]);

  useEffect(() => {
    const checkCanCancel = () => {
      const now = Date.now();
      let canCancelBid = true;

      if (bidExistence?.bidTime) {
        const bidTime = new Date(bidExistence.bidTime).getTime();
        const isBidExpired = now > bidTime + 60 * 60 * 1000;
        if (isBidExpired) {
          canCancelBid = false;
        }
      }

      if (auction?.createdAt && auction?.period) {
        const auctionEndTime =
          new Date(auction.createdAt).getTime() +
          auction.period * 24 * 60 * 60 * 1000;

        const isWithinOneHourToEnd = now > auctionEndTime - 60 * 60 * 1000;
        if (isWithinOneHourToEnd) {
          canCancelBid = false;
        }
      }

      setCanCancel(canCancelBid);
    };

    checkCanCancel(); // initial check
    const timer = setInterval(checkCanCancel, 60000); // check every 1 minute
    return () => clearInterval(timer);
  }, [bidExistence, auction]);

  const openAuctionNoticeModal = () => {
    setIsAuctionNoticeModalOpen(true);
  };

  const openAuctionBidModal = () => {
    setIsEditMode(false);
    setIsAuctionNoticeModalOpen(false);
    setIsAuctionBidReviewModalOpen(false);
    setIsAuctionBidModalOpen(true);
  };

  const openAuctionBidModalOnEditMode = () => {
    setIsEditMode(true);
    setIsAuctionNoticeModalOpen(false);
    setIsAuctionBidReviewModalOpen(false);
    setIsAuctionBidModalOpen(true);
  }

  const openAuctionBidReviewModal = (bidPrice: string) => {
    setBidPrice(bidPrice);
    setIsAuctionBidModalOpen(false);
    setIsAuctionBidReviewModalOpen(true);
  };

  const openPruchaseNowModal = () => {
    setIsPruchaseNowModalOpen(true);
  };

  const openCancleBidModal = () => {
    setIsCancleBidModalOpen(true);
  };

  const openConfirmPaymentModal = () => {
    if (wallet.klav >= (auction.immediatePrice + 3)) {
      setIsConfirmPaymentModalOpen(true);
    } else {
      showAlert("보유 클랩이 부족합니다", "error");
    }
  }

  const closeModal = () => {
    window.location.reload();
    setIsAuctionNoticeModalOpen(false);
    setIsAuctionBidModalOpen(false);
    setIsAuctionBidReviewModalOpen(false);
    setIsPruchaseNowModalOpen(false);
    setIsCancleBidModalOpen(false);
    setIsConfirmPaymentModalOpen(false);
  };

  const closeConfirmModal = () => {
    setIsConfirmPaymentModalOpen(false);
  }

  return (
    <div className="flex flex-col gap-2">
      {isMine ? (
        <div className="flex f lex-col gap-2">
          {/* <button className="flex w-full h-full items-center justiry-center">
            <div
              className={`flex w-full h-full rounded-lg items-center justify-center border bg-achromatic-100 border-achromatic-100 `}
            >
              <span className={`py-4 font-semibold text-sm`}>자신의 경매입니다.</span>
            </div>
          </button> */}
        </div>
      ) : auction.auctionStatus === AuctionStatus.ONGOING ? (
        bidExistence?.bidStatus === BidStatus.BIDDING ||
          bidExistence?.bidStatus === BidStatus.HIGHEST_BID ? (
          <div className="flex flex-col gap-2">
            <button
              className="flex w-full h-full items-center justify-center"
              onClick={openAuctionBidModalOnEditMode}
            >
              <div
                className={`flex w-full h-full rounded-lg items-center justify-center transition duration-150 ease-in-out border border-achromatic-100 hover:bg-achromatic-50 `}
              >
                <span className={`py-4 font-semibold text-sm`}>
                  입찰편집 (입찰중)
                </span>
              </div>
            </button>
            <button
              className="flex w-full h-full items-center justify-center"
              onClick={openCancleBidModal}
              disabled={!canCancel}
            >
              <div
                className={`flex w-full h-full rounded-lg items-center justify-center transition duration-150 ease-in-out bg-[#F04D1A] bg-opacity-[8%] hover:bg-opacity-[16%]`}
              >
                <span className={`py-4 font-semibold text-sm text-[#F04D1A]`}>
                  {canCancel ? "입찰취소" : "입찰 취소 불가"}
                </span>
              </div>
            </button>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            <button
              className="flex w-full h-full items-center justify-center"
              onClick={openAuctionNoticeModal}
            >
              <div
                className={`flex w-full h-full rounded-lg items-center justify-center transition duration-150 ease-in-out bg-primary-500 hover:bg-primary-600`}
              >
                <span className={`py-4 font-semibold text-sm text-white`}>
                  입찰
                </span>
              </div>
            </button>
            <button
              className="flex w-full h-full items-center justify-center"
              onClick={openPruchaseNowModal}
            >
              <div
                className={`flex w-full h-full rounded-lg items-center justify-center transition duration-150 ease-in-out bg-achromatic-100 hover:bg-achromatic-200`}
              >
                <span
                  className={`py-4 font-semibold text-sm text-achromatic-800`}
                >
                  즉시구매
                </span>
              </div>
            </button>
          </div>
        )
      ) : (
        <div>
          <button
            className="flex w-full h-full items-center justify-center"
            disabled={true}
          >
            <div
              className={`flex w-full h-full rounded-lg items-center justify-center transition duration-150 ease-in-out bg-achromatic-100 hover:bg-achromatic-200`}
            >
              <span
                className={`py-4 font-semibold text-sm text-achromatic-800`}
              >
                이미 종료된 경매입니다.
              </span>
            </div>
          </button>
        </div>
      )}

      <div>
        <AuctionNoticeModal
          title="경매 참여 방법"
          isOpen={isAuctionNoticeModalOpen}
          onClose={closeModal}
          onAgree={openAuctionBidModal}
        />
      </div>

      <div>
        <AuctionBidModal
          title="경매에 입찰하기"
          isOpen={isAuctionBidModalOpen}
          onClose={closeModal}
          auction={auction}
          onBid={openAuctionBidReviewModal}
          onImmediatePurchase={openPruchaseNowModal}
          bidExistence={bidExistence}
        />
      </div>

      <div>
        <AuctionBidReviewModal
          title="입찰 검토"
          isOpen={isAuctionBidReviewModalOpen}
          auction={auction}
          bidPrice={bidPrice}
          onClose={closeModal}
          onEdit={openAuctionBidModal}
          isEditMode={isEditMode}
        />
      </div>

      <div>
        <PurchaseNowModal
          title="즉시 구매"
          isOpen={isPruchaseNowModalOpen}
          onClose={closeModal}
          auction={auction}
          onPayment={openConfirmPaymentModal}
        />
      </div>

      <div>
        <CancleBidModal
          title="입찰을 취소하시겠습니까?"
          isOpen={isCancleBidModalOpen}
          onClose={closeModal}
          auction={auction}
        />
      </div>

      <div>
        <ConfirmPaymentModal
          title="결제 확인"
          isOpen={isConfirmPaymentModalOpen}
          onClose={closeModal}
          onCancle={closeConfirmModal}
          onConfirm={() => { }}
          auction={auction}
          type="immediate"
        />
      </div>
    </div>
  );
};

const AuctionGuideComponent = () => {
  return (
    <div className="flex flex-col gap-4 text-achromatic-600 text-xs font-medium">
      <div className="flex text-achromatic-400 text-sm">안내</div>
      <div className="flex flex-col gap-2">
        <span className="text-achromatic-300">입찰 전 물품 상태 확인</span>
        <span>
          입찰자 여러분께서는 경매 물품의 상태와 세부 사항을 충분히 확인하신 후
          입찰에 참여해 주시기 바랍니다. 제공된 설명과 사진은 참고용이며 실제
          상태와 다를 수 있습니다. 배송 전 검수는 외관 확인에 한하며 경매 물품의 품질, 기능, 진품 여부 등에 대한
          보증은 하지 않습니다.
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-achromatic-300">취소, 환불, 교환 불가</span>
        <span>
          경매를 통해 낙찰 받은 물품은 취소, 환불, 교환이 불가합니다. 당사는 안전한 거래
          환경을 제공하기 위해 경매 시스템을 운영하고, 낙찰된 상품이 정상적으로 전달될 수
          있도록 기본적인 검수 및 배송 절차를 지원합니다. 거래와 관련된 모든 법적∙계약적
          책임은 크리에이터와 낙찰자 본인에게 있으므로 신중하게 입찰해 주시기 바랍니다.
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-achromatic-300">입찰 금액의 구속력</span>
        <span className="">
          입찰 시 제시한 금액에는 구속력이 있음을 유의해 주시기 바랍니다. 낙찰 후 철회는
          불가능하며, 낙찰 시 해당 금액을 지불할 의무가 있습니다. 입찰 과정에서의
          부주의로 인한 손해는 입찰자가 부담하게 되니 신중히 입찰하여 주시기 바랍니다.
        </span>
      </div>
    </div>
  );
};

const PostDetailComponent = ({ post, like, bidExistence }: PostDetailProps) => {
  const { user } = useUserStore();

  const removeHashtags = (content: string) => {
    return content.replace(/#\S+/g, "").trim(); // #으로 시작하는 단어 제거
  };

  const formatHashtags = (hashtags: string) => {
    if (!hashtags) return "";
    return hashtags
      .split(",")
      .map((tag) => `#${tag.trim()}`)
      .join(" ");
  };

  if (!post) {
    // post가 로드되지 않은 경우 로딩 상태를 표시
    return <Loading />;
  }

  return (
    <div className="h-full">
      <div className="flex flex-col h-full gap-8 rounded-2xl border-achromatic-100 border p-6 mobile:border-0">
        <div className="flex flex-col w-full h-full text-sm font-medium">
          <PostHeader user={post.postWriter} />
          <div className="pt-4">
            <p>{removeHashtags(post.content)}</p>
            &nbsp;
            <p
              className={`text-primary-500 ${post!.hashtags.length > 0 &&
                post!.images.length === 0 &&
                !post!.auction
                ? "pb-6"
                : ""
                }`}
            >
              {formatHashtags(post!.hashtags)}
            </p>
          </div>
          <div>
            <PostFooter post={post} like={like} />
          </div>
        </div>

        <div className="border-b border-achromatic-100" />

        <div>
          <AuctionDetailComponent post={post} />
        </div>

        <div className="">
          <AuctionControlComponent
            auction={post.auction}
            bidExistence={bidExistence}
            isMine={post.postWriter.id === user.id}
          />
        </div>

        <div className="border-b border-achromatic-100" />

        <div>
          <AuctionGuideComponent />
        </div>
      </div>
    </div>
  );
};

export default PostDetailComponent;
