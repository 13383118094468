import React, { useEffect, useState } from "react";

import { BaseModal, Button, CheckBox } from "components";
import { createCreator } from "apis";
import { useAlertStore } from "store";

import { ReactComponent as Image } from "assets/images/image.svg";

interface ProposeCreatorModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

const ProposeCreatorModal = ({
  isOpen,
  onClose,
  title,
}: ProposeCreatorModalProps) => {
  const showAlert = useAlertStore((state) => state.showAlert);
  const [identificationFile, setIdentificationFile] = useState<File | null>(
    null
  );
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [previewImage, setPreviewImage] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      setIdentificationFile(uploadedFile);
      setPreviewImage(uploadedFile.name);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      setIdentificationFile(droppedFile);
      setPreviewImage(droppedFile.name);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleClick = () => {
    document.getElementById("fileInput")?.click();
  };

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev);
  };

  const handleAccountNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAccountNumber(event.target.value);
  };

  const handleSubmit = () => {
    if (!isChecked) {
      showAlert("약관에 동의하셔야 신청이 가능합니다.", "error");
      return;
    } else if (!identificationFile) {
      showAlert("신분증을 등록하셔야 신청이 가능합니다.", "error");
      return;
    } else if (!accountNumber) {
      showAlert("계좌번호를 입력하셔야 신청이 가능합니다", "error");
      return;
    }
    createCreator(accountNumber, isChecked, identificationFile)
      .then((response) => {
        if (response.status === 201) {
          onClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!isOpen) {
      setPreviewImage("");
      setAccountNumber("");
      setIsChecked(false);
    }
  }, [isOpen]);

  return (
    <BaseModal title={title} isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-[60px] w-[560px] mobile:w-[292px]">
        <div className="flex flex-col pt-8 gap-8">
          <div className="flex flex-col text-achromatic-400 font-medium text-sm gap-4">
            <div className="flex flex-col gap-2">
              <span>신분증</span>
              <div
                className="min-h-[100px] content-center text-center rounded-lg border-2 border-dashed border-achromatic-100 text-achromatic-800 font-semibold cursor-pointer transition-all duration-150 hover:bg-achromatic-50"
                onClick={handleClick}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                {/* 신분증 올리기(주민등록증, 운전면허증, 여권 jpg 또는 pdf) */}
                {previewImage ? (
                  <div className="flex items-center pl-5">
                    <Image className="w-[50px] h-[50px] mr-5" />

                    <span className="text-lg mobile:text-xs">{previewImage}</span>
                  </div>
                ) : (
                  "신분증 올리기(주민등록증, 운전면허증, 여권 jpg 또는 pdf)"
                )}
              </div>
              <input
                id="fileInput"
                type="file"
                accept=".jpg, .jpeg, .png, .pdf"
                className="hidden"
                onChange={handleFileUpload}
              />
            </div>
            <div className="flex flex-col gap-2">
              <span>계좌정보(은행명/계좌번호/예금주)</span>
              <input
                type="text"
                className="border border-achromatic-100 rounded-lg h-12 p-3 placeholder-achromatic-200 text-black focus:outline-none"
                placeholder="신분증과 동일한 명의 계좌정보를 입력하세요."
                value={accountNumber}
                onChange={handleAccountNumberChange}
              />
            </div>
          </div>
          <div>
            <CheckBox
              label={
                <>
                  크리에이터 계정으로 전환하면{" "}크리에이터{" "}
                  <a
                    href="https://docs.google.com/document/d/e/2PACX-1vTelTHkMcIawvHTWMsUXEbZEfR-gnfVDpKWi4C47RClfe0sKk1C-SGkNmp83-SMe7UpPavhSEDecQRi/pub" // 실제 링크로 대체
                    className="underline cursor-pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    이용 약관
                  </a>
                  에 동의하시는 것입니다.
                </>
              }
              checked={isChecked}
              onChange={() => {
                handleCheckboxChange();
              }}
            />
          </div>
        </div>
        <div className="">
          <Button text="Creator 계정 전환 신청하기" onClick={handleSubmit} />
        </div>
      </div>
    </BaseModal>
  );
};

export default ProposeCreatorModal;
