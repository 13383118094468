import { ReactComponent as HomeIcon } from "assets/images/home.svg";
import { ReactComponent as MyPageIcon } from "assets/images/stars.svg";
import { ReactComponent as NotificationIcon } from "assets/images/bell.svg";
import { ReactComponent as WalletIcon } from "assets/images/wallet.svg";
import { ReactComponent as SettingIcon } from "assets/images/settings.svg";

const sideBarLinks = [
  { href: "/", title: "홈", icon: HomeIcon },
  { href: "/mypage", title: "마이페이지", icon: MyPageIcon },
  {
    href: "/notification",
    title: "알림",
    icon: NotificationIcon,
    notificationCount: 0,
  },
  { href: "/auction", title: "경매 & 지갑", icon: WalletIcon },
  { href: "/setting", title: "설정", icon: SettingIcon },
];

export default sideBarLinks;
