import { SideBar } from "components";

type Props = {
    children: React.ReactNode;
};
export default function ChargeLayout({ children }: Props) {
    return (
        <div className="min-h-[600px] max-w-[400px] items-center justify-center flex mx-auto h-full">
            {children}
        </div>
    )
}