import { NavLink, useLocation } from 'react-router-dom';

import { AdminSideBarLinks } from 'data';

import { ReactComponent as FullLogoIcon } from 'assets/images/full.logo.svg';


const AdminSideBar = () => {
    const location = useLocation();

    return (
        <div className="h-full bg-white">
            <div className="flex flex-col h-full p-6">
                <div className="flex py-3 px-4">
                    {/* <FullLogoIcon className='w-[125.65px] h-5' /> */}
                    {/* klavit */}
                </div>
                <div className='flex flex-col gap-2 pt-6'>
                    {AdminSideBarLinks.map((link, index) => {
                        const isBasicLink = link.href === '/admin';
                        const isActive = isBasicLink
                            ? location.pathname === link.href
                            : location.pathname.startsWith(link.href);

                        return (
                            <NavLink
                                key={index}
                                to={link.href}
                                className={`flex items-center gap-4 py-3 cursor-pointer
                                    transition-colors duration-150 ease-in-out
                                    hover:bg-primary-50 rounded-lg ${isActive ? 'text-primary-500 bg-primary-500 bg-opacity-10' : 'text-achromatic-500'}`}
                            >
                                {({ isActive: navLinkIsActive }) => {
                                    return (
                                        <>
                                            <span className='text-sm font-semibold px-4'>{link.title}</span>
                                        </>
                                    )
                                }}
                            </NavLink>
                        )
                    }
                    )}
                </div>
            </div>
        </div>
    )
}

export default AdminSideBar;