import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  HighestBidderComponent,
  PageHeader,
  PostDetailComponent,
  PostImageComponent,
} from "components";
import { checkUserBidExistence, getPostDetail, getPostLike } from "apis";
import { BidStatusDTO, PostDTO } from "types";
import { BidStatus } from "types/types";
import { useUserStore } from "store";

export default function Post() {
  const params = useParams();
  const postId = params.id;
  const { user } = useUserStore();

  const [postDetail, setPostDetail] = useState<PostDTO>();
  const [postLike, setPostLike] = useState<boolean>(false);
  const [bidExistence, setBidExistence] = useState<BidStatusDTO>();

  const isMine = postDetail?.postWriter.id === user?.id;

  const getDetail = async () => {
    if (postId) {
      const response = await getPostDetail(postId);
      if (response.status === 200) {
        setPostDetail(response.data.data);
        await checkUserBidExistence(response.data.data.auction.id).then(
          (response) => {
            setBidExistence(response.data.data);
          }
        );
      }
    }
  };

  const getLike = async () => {
    if (postId) {
      const response = await getPostLike(postId);
      if (response.data.isSuccess === "T") {
        setPostLike(response.data.data);
      }
    }
  };

  // 초기 좋아요 상태 가져오기
  const fetchInitialLikeState = async () => {
    try {
      const response = await getPostLike(postId!);
      if (response.data.isSuccess === "T") {
        setPostLike(response.data.data);
      }
    } catch (error) {
      console.error("초기 좋아요 상태를 가져오는 데 실패했습니다.", error);
    }
  };

  useEffect(() => {
    if (postId) {
      getDetail();
      fetchInitialLikeState();
    }
  }, [postId]);

  return (
    <div className="flex w-full flex-col border-achromatic-100 border-x mobile:pb-[56px]">
      <PageHeader title="포스트" isArrow={true} />
      {!isMine && bidExistence?.bidStatus === BidStatus.HIGHEST_BID && <HighestBidderComponent />}
      <div className="flex p-6 gap-6 mobile:flex-col mobile:py-4 mobile:px-0">
        <div className="flex max-w-[632px] w-full flex-col mobile:px-4">
          <PostImageComponent images={postDetail?.images} />
        </div>
        <div className="max-w-[360px] w-full mobile:max-w-full">
          <PostDetailComponent
            post={postDetail!}
            like={postLike!}
            bidExistence={bidExistence!}
          />
        </div>
      </div>
    </div>
  );
}
