import { AxiosResponse } from "axios";
import { CreatorDTO, PostDTO, ResponseDTO } from "types";
import { get } from "utils";

const baseUrl = `${process.env.REACT_APP_API_URL}/guest`;

export const getGuestPostList = async (
  page: number,
  size: number
): Promise<AxiosResponse<ResponseDTO<PostDTO[]>>> => {
  const url = `${baseUrl}/post/list?page=${page}&limit=${size}`;

  return get(url);
};

export const getGuestPopularPostList = async (): Promise<
  AxiosResponse<ResponseDTO<PostDTO[]>>
> => {
  const url = `${baseUrl}/post/popular`;

  return get(url);
};

export const getGuestRecommendCreatorList = async (): Promise<
  AxiosResponse<ResponseDTO<CreatorDTO[]>>
> => {
  const url = `${baseUrl}/creator/recommend`;

  return get(url);
};
