import { useEffect } from "react";

import { getGuestPostList } from "apis";
import { GuestPost, Loading } from "components";
import { PostDTO } from "types";
import { useInfiniteQuery } from "react-query";

interface PostWrapperProps {
  searchTerm?: string;
  onSearch: (term: string) => void;
}

const GuestPostWrapper = ({ searchTerm, onSearch }: PostWrapperProps) => {

  const fetchPosts = ({ pageParam = 1 }: { pageParam?: number }) =>
    getGuestPostList(pageParam, 10);

  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ["posts"], // Query Key
    fetchPosts, // Fetch function
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalPages = lastPage.data.data.length; // 서버에서 제공하는 총 페이지 수
        const nextPage = allPages.length + 1;
        return nextPage <= totalPages ? nextPage : undefined;
      },
    }
  );

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasNextPage, isFetchingNextPage]);

  if (isLoading) return <Loading />;
  if (isError) return <div>Error loading posts.</div>;

  const posts = data?.pages.flatMap((page) => page.data.data) ?? [];

  const filteredPosts = searchTerm
    ? posts.filter(
      (post) =>
        post.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.postWriter.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.postWriter.profileId
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        post.auction?.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : posts;

  return (
    <div className="flex flex-col">
      {filteredPosts.map((post: PostDTO) => (
        <GuestPost key={post.id} post={post} onSearch={onSearch} />
      ))}
      {isFetchingNextPage && <Loading />}
    </div>
  );
};

export default GuestPostWrapper;
